import React from 'react';

const GoogleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M11.78 6.125C11.78 5.735 11.745 5.36 11.68 5H6.5V7.13H9.46C9.33 7.815 8.94 8.395 8.355 8.785V10.17H10.14C11.18 9.21 11.78 7.8 11.78 6.125Z"
        fill="#4285F4"
      />
      <path
        d="M6.50375 11.5008C7.98875 11.5008 9.23375 11.0108 10.1438 10.1708L8.35875 8.78578C7.86875 9.11578 7.24375 9.31578 6.50375 9.31578C5.07375 9.31578 3.85875 8.35078 3.42375 7.05078H1.59375V8.47078C2.49875 10.2658 4.35375 11.5008 6.50375 11.5008Z"
        fill="#34A853"
      />
      <path
        d="M3.42 7.04516C3.31 6.71516 3.245 6.36516 3.245 6.00016C3.245 5.63516 3.31 5.28516 3.42 4.95516V3.53516H1.59C1.215 4.27516 1 5.11016 1 6.00016C1 6.89016 1.215 7.72516 1.59 8.46516L3.015 7.35516L3.42 7.04516Z"
        fill="#FBBC05"
      />
      <path
        d="M6.50375 2.69C7.31375 2.69 8.03375 2.97 8.60875 3.51L10.1838 1.935C9.22875 1.045 7.98875 0.5 6.50375 0.5C4.35375 0.5 2.49875 1.735 1.59375 3.535L3.42375 4.955C3.85875 3.655 5.07375 2.69 6.50375 2.69Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
