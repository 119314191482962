import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import BodyCss from '/imports/core/ui/atoms/BodyCss';
import Content from '/imports/homepage/ui/atoms/Content';
import CTA from '/imports/landing/ui/components/CTA';
import Footer from '/imports/homepage/ui/components/Footer';
import { formatCurrency } from '/imports/checkout/api/utils';
import {
  getJobTrackRoute,
  removeExperiment,
  cookieParser,
  getCurrency,
  showResumeDonePage,
  showCookie,
  getLocaleFromPath,
  getRoute,
  isBlogDomain,
  getDefaultLanguage,
  getLocation,
  EXPLICITY_SET_ZAR_CURRENCY,
} from '/lib/helpers';
import {
  getModelesCVContent,
  getElegantCVContent,
  getBrillantCvContent,
} from '/imports/homepage/api/home-page-content';
import { getPlans } from '/imports/checkout/api/helpers';
import Header from '/imports/homepage/ui/components/Header';
import { homepageContent, homepageMeta } from '/imports/homepage/api/constants';
import { HomepageContentResumedone } from '/imports/homepage/ui/components/HomepageContentResumedone';
import { LANGUAGES_CODES } from '/imports/generator/api/constants';
import { Push } from '/components/Link';
import RightSide from '/imports/landing/ui/components/RighSide';
import SectionHeading from '/imports/homepage/ui/components/SectionHeading';
import SectionList from '/imports/landing/ui/components/SectionList';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';
import publicIp from 'public-ip';

const Homepage = ({ ctaClick, userIdentified, page }) => {
  const router = useRouter();
  const { breakpoint, host, isMobile } = useResponsive();
  const { locale = 'en', t } = useIntl();
  const backHackTrigged = localStorage.getItem('back_hack_triggered');
  const [plans, setPlans] = useState({
    yearlyPlanAmount: '',
    monthlyFullPlanAmount: '',
    monthlyLimitedPlanAmount: '',
    yearlyPlanAmountTrial: '',
    monthlyFullPlanAmountTrial: '',
    monthlyLimitedPlanAmountTrial: '',
  });

  const { trackEvent, sessionStartHandler, setPeople } = useTracking();

  const renderHack = () => {
    if (!backHackTrigged) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        if (isBlogDomain(host)) {
          window.location.href = '/builder/onboard/start';
        } else {
          window.location.href = `/${locale}/onboard/start`;
        }
        localStorage.setItem('back_hack_triggered', 'true');
        trackEvent('back_hack_triggered');
        window.history.go(0);
      };
    }
  };

  const fetchData = async (IP) => {
    const countryCode = await getLocation(IP || '127.0.0.1');
    return countryCode;
  };
  const getPlansByCurrency = async () => {
    let countryCode = '';
    if (EXPLICITY_SET_ZAR_CURRENCY.includes(host)) {
      const IP = publicIp.v4();
      countryCode = fetchData(IP);
    }
    const currency = getCurrency(host, countryCode);
    getPlans(currency)
      .then((plans) => {
        const monthlyLimitedPlanSelector = '14-DAY LIMITED ACCESS';
        const monthlyFullPlanSelector = '14-DAY FULL ACCESS';
        const yearlyPlanSelector = 'MONTHLY ACCESS';
        const yearlyPlan = plans.find((p) => p.name === yearlyPlanSelector);
        const monthlyFullPlan = plans.find((p) => p.name === monthlyFullPlanSelector);
        const monthlyLimitedPlan = plans.find((p) => p.name === monthlyLimitedPlanSelector);
        const yearlyPlanAmount = formatCurrency(yearlyPlan.amount, currency, locale);
        const yearlyPlanAmountTrial = formatCurrency(yearlyPlan.trialAmount, currency, locale);
        const monthlyFullPlanAmount = formatCurrency(monthlyFullPlan.amount, currency, locale);
        const monthlyFullPlanAmountTrial = formatCurrency(monthlyFullPlan.trialAmount, currency, locale);
        const monthlyLimitedPlanAmount = formatCurrency(monthlyLimitedPlan.amount, currency, locale);
        const monthlyLimitedPlanAmountTrial = formatCurrency(monthlyLimitedPlan.trialAmount, currency, locale);
        setPlans({
          yearlyPlanAmount,
          monthlyFullPlanAmount,
          monthlyLimitedPlanAmount,
          yearlyPlanAmountTrial,
          monthlyFullPlanAmountTrial,
          monthlyLimitedPlanAmountTrial,
        });
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    const { asPath } = router;
    getPlansByCurrency();
    sessionStartHandler();
    removeExperiment('landing_view_bg_experiment');
    removeExperiment('landing_view_experiment');
    removeExperiment('landing_view_scroll_experiment');
    removeExperiment('landing_view_right_section_experiment');
    removeExperiment('landing_view_budapest_experiment');
    setPeople();
    let obj = {};
    if (marvelEmitter.getActiveVariant('exp_old_landing_text')) {
      obj.exp_old_landing_text = marvelEmitter.getActiveVariant('exp_old_landing_text');
    }
    if (showCookie()) {
      obj = { ...obj, exp_landing_cookie: marvelEmitter.getActiveVariant('exp_landing_cookie') };
    }

    trackEvent('homepage_view', obj);
    const destination = getJobTrackRoute(host, 'resumes', isMobile).as;
    const { token } = cookieParser();
    renderHack();
    if (userIdentified || token) {
      const queryString = {
        from: asPath,
      };

      const locale = getLocaleFromPath(
        asPath.split('?')[0],
        getDefaultLanguage(host) === LANGUAGES_CODES.FRENCH,
        getDefaultLanguage(host),
      );
      const route = getRoute(`${destination}`, locale, false, host, isBlogDomain(host));
      const queryValue = `?${qs.stringify(queryString)}`;
      const redirectPage = `${route}${queryValue}`;
      Push(redirectPage, locale, destination);
      return;
    }
    try {
      document.getElementsByTagName('body')[0].classList.add('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
  }, []);

  const getContentMySmartCv = () => {
    if (!page) return homepageContent['mysmartcv']?.[locale] || [];
    return homepageContent[host]?.[page][locale] || homepageContent['mysmartcv']?.[locale] || [];
  };
  const getContent = () => {
    switch (host) {
      case 'mysmartcv':
        return getContentMySmartCv();
      case 'modeles-cv':
        return [getModelesCVContent()];
      case 'elegantcv':
        return [getElegantCVContent(t)];
      case 'brillantcv':
        return [getBrillantCvContent()];
      case 'cvdeboss':
      default:
        return homepageContent[host]?.[page] || homepageContent['cvdeboss']?.default || [];
    }
  };

  const getMetaMySmartCV = () => {
    const localeTitle = homepageMeta['mysmartcv']?.[locale]?.title;
    const localeDescription = homepageMeta['mysmartcv']?.[locale]?.description;
    const title = homepageMeta['mysmartcv']?.[page]?.title || localeTitle;
    const description = homepageMeta['mysmartcv']?.[page]?.description || localeDescription;

    return (
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>
    );
  };

  const getMetaInfo = () => {
    switch (host) {
      case 'mysmartcv':
        return getMetaMySmartCV();
      case 'modeles-cv':
      case 'cvdeboss':
        return (
          homepageMeta['cvdeboss']?.[page]?.title &&
          homepageMeta['cvdeboss']?.[page]?.description && (
            <>
              <title>{homepageMeta['cvdeboss'][page].title}</title>
              <meta name="description" content={homepageMeta['cvdeboss'][page].description} />
            </>
          )
        );
      default:
        return;
    }
  };

  const onClickLandingCTA = () => ctaClick('Create my CV');
  return (
    <>
      <Head>{getMetaInfo()}</Head>
      <StyledBodyCss>
        <Container>
          <Header userIdentified={userIdentified} breakpoint={breakpoint} />
          <Section>
            <SectionHeading page={page} />
            <CTA onClick={onClickLandingCTA} userIdentified={userIdentified} isHomepage breakpoint={breakpoint} />
            <SectionList isHomepage />
            {!showResumeDonePage(host) ? (
              getContent().map((item, index) => (
                <div key={index}>
                  <Content>{item}</Content>
                  <CTA isHomepage onClick={onClickLandingCTA} userIdentified={userIdentified} breakpoint={breakpoint} />
                </div>
              ))
            ) : (
              <HomepageContentResumedone plans={plans} />
            )}
            <RightSide onClickLandingCTA={onClickLandingCTA} />
          </Section>
        </Container>
        <Footer ctaClick={onClickLandingCTA} userIdentified={userIdentified} breakpoint={breakpoint} />
      </StyledBodyCss>
    </>
  );
};

Homepage.propsType = {
  ctaClick: PropTypes.func,
  userIdentified: PropTypes.bool,
  page: PropTypes.string,
};

const StyledBodyCss = styled(BodyCss)`
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  box-shadow: 0 10px 60px 0 rgba(10, 26, 51, 0.2);

  @media (max-height: 500px) {
    min-height: 500px;
  }
  padding-bottom: 20px;
  ${({ theme }) =>
    theme.min('sm')`
      width: 50%;
      padding-bottom: 50px;
    `}
`;

const Section = styled.section`
  display: flex !important;
  flex-direction: column;
  max-width: none;
  margin: auto;
  width: 100%;
`;

export default Homepage;
