import React from 'react';
import styled, { css } from 'styled-components';

import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import Box from '/imports/core/ui/atoms/Box';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import Flex from '/imports/core/ui/atoms/Flex';
import { START_FORM_MAP } from '/imports/generator/api/form';
import { UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import useIntl from '/imports/core/api/useIntl';

const WizardStartForm = ({ resume, isExp, centered, currentUser, updateImmue }) => {
  const { t } = useIntl();
  const { id, details, settings } = resume;
  const formFields = START_FORM_MAP.filter((field) => field.name !== 'address');

  return (
    <FlexCont grid centered={centered} id="personal-information">
      {formFields.map((field, i) => {
        const FormComponent = field.component;
        let props = field.props || {};
        const gridWidth = field.fullWidth ? 12 : 6;
        const xsGridWidth = field.xsFullWidth ? 12 : gridWidth;
        const path = `details.${field.name}`;
        if (props.errorMessages && props.errorMessages.length) {
          const errorMessages = props.errorMessages.map((errorMessage) => t(errorMessage));
          props = { ...props, errorMessages };
        }
        return (
          <FormBox md={gridWidth} sm={gridWidth} xs={xsGridWidth} padded key={`exposed-${field.name}-${i}`}>
            {!isExp && field.label && (
              <FieldLabel
                isStartForm
                label={t(field.translationSlug)}
                tip={field.tip}
                id={`field-${field.name}-${i}`}
              />
            )}
            <FormComponent
              type="text"
              mutation={UPDATE_RESUME_DETAIL}
              name={field.name}
              variables={{
                docId: id,
                path,
              }}
              value={details[field.name]}
              template={settings.template}
              optimisticResponse={blockDetailImmutableUpdate(updateImmue)(id, path)}
              label={field.label}
              view="heading"
              currentUser={currentUser}
              lang={settings.language}
              details={details}
              resume={resume}
              describedBy={`field-${field.name}-${i}`}
              {...props}
            />
          </FormBox>
        );
      })}
    </FlexCont>
  );
};

const FormBox = styled(Box)`
  position: relative;
  margin-bottom: 35px;
`;

const FlexCont = styled((p) => <Flex {...p} />)`
  ${({ centered }) =>
    centered &&
    css`
      min-width: 450px;
      max-width: 60%;
      margin: 0 auto;
    `}
  ${({ theme }) =>
    theme.max('xs')`
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
  `}
`;

WizardStartForm.displayName = 'WizardStartForm';

export default WizardStartForm;
