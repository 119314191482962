import styled, { css } from 'styled-components';
import Container from '/imports/job-tracking/ui/atoms/Container';
import React, { useState } from 'react';
import nookies from 'nookies';
import SearchHeader from '/imports/job-tracking/ui/components/SearchHeader';
import { getSearchJob8 } from 'imports/job-tracking/api/api';
import MoneyIcon from '/imports/job-tracking/ui/assets/MoneyIcon';
import JobCompanyIcon from '/imports/job-tracking/ui/assets/JobCompanyIcon';
import RemoteIcon from '/imports/job-tracking/ui/assets/RemoteIcon';
import OnsiteIcon from '/imports/job-tracking/ui/assets/OnsiteIcon';
import Flex from 'imports/core/ui/atoms/Flex';
import { useSearchV2Store } from '/zustand/SearchV2Store';
import RoundedButton from '/imports/job-tracking/ui/atoms/RoundedButton';
import NoJobIcon from '/imports/job-tracking/ui/assets/NoJobIcon';
import intlHook from '/imports/core/api/useIntl';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchV2DetailSection from '/imports/job-tracking/ui/components/SearchV2DetailSection';
const formatCreatedAt = (dateString) => {
  if (!dateString) return '';
  const createdAt = new Date(dateString);
  const now = new Date();
  const diffInDays = Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));
  return `${diffInDays}d ago`;
};

const SearchV2 = () => {
  const { token } = nookies.get({});
  const { t } = intlHook();
  const [loading, setLoading] = useState(false);
  const { jobs, activeJob, setJobs, setActiveJob, setPagination, pagination, setValues, searchValue, cityValue } =
    useSearchV2Store();

  const searchJobData = async (searchValue, cityValue, page = 1, isFirstTime = false) => {
    setLoading(true);
    const resp = await getSearchJob8(searchValue, cityValue, page, token);
    setValues({ searchValue, cityValue });
    setPagination(resp.pagination);
    setJobs(isFirstTime ? [...resp.data] : [...jobs, ...resp.data]);
    isFirstTime && setActiveJob(resp.data[0]);
    setLoading(false);
  };

  const handleSelect = (job) => () => setActiveJob(job);

  return (
    <SearchWrap $fullWidth direction="column">
      <Wrapper>
        <SearchHeader searchJobData={searchJobData} searchLoading={loading} />
        <JobContainer>
          {jobs && jobs.length === 0 && (
            <StyledFlex fullWidth justifyContent="center" alignItems="center" direction="column">
              <NoJobIcon />
              <Text>{t('jobtrack_no_job_available')}</Text>
            </StyledFlex>
          )}
          {jobs.length > 0 && (
            <InfiniteScroll
              dataLength={jobs.length}
              next={() => {
                searchJobData(searchValue, cityValue, +pagination?.currentPage + 1, false);
              }}
              hasMore={+pagination?.currentPage < pagination?.totalPages}
              loader={<LoadingText>Loading...</LoadingText>}
            >
              <CardContainer direction="column">
                {jobs.map((job) => (
                  <Card
                    key={job.id}
                    onClick={handleSelect(job)}
                    isSelected={activeJob?.id === job.id}
                    $fullWidth
                    direction="column"
                  >
                    <Header>
                      <CardTitle>{job.title}</CardTitle>
                    </Header>
                    <Subtitle>{job.company}</Subtitle>
                    <Subtitle>
                      {job.city} {job.zipCode}
                    </Subtitle>
                    <ChipContainer $fullWidth $withMarginBottom>
                      {job.salaray && (
                        <Chip>
                          <MoneyIcon /> {job.salaray}
                        </Chip>
                      )}
                      {job.contractCategory && (
                        <Chip>
                          <JobCompanyIcon /> {job.contractCategory}
                        </Chip>
                      )}
                      <Chip>
                        {job.remote ? <RemoteIcon /> : <OnsiteIcon />} {job.remote ? 'Remote' : 'On Site'}
                      </Chip>
                    </ChipContainer>
                    <Footer>
                      <span>{formatCreatedAt(job.createdAt)}</span>
                      <span>
                        {job.city}, {job.country}
                      </span>
                    </Footer>
                  </Card>
                ))}
              </CardContainer>
            </InfiniteScroll>
          )}
          {activeJob && <SearchV2DetailSection />}
        </JobContainer>
      </Wrapper>
    </SearchWrap>
  );
};

export default SearchV2;

const ChipContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 8px;
  ${({ $withMarginBottom }) =>
    $withMarginBottom &&
    css`
      margin-bottom: 4px;
    `}
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  background: #ececed;
  border-radius: 4px;
`;

const LoadingText = styled.div`
  text-align: center;
  padding: 3px;
`;

const Text = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '20px', 'var(--grayscale-n400)', '44px')};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  min-height: 80vh;
`;

export const StyledRoundedButton = styled(RoundedButton)`
  width: 100%;
  margin-top: 12px;
  @media (min-width: 768px) {
    width: fit-content;
  }
`;

export const CompanyDetails = styled(Flex)`
  padding: 12px 24px;
  border-bottom: 1px solid var(--grayscale-n300);
`;

export const RightPageLayout = styled(Flex)`
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 31, 0.12);
    border-radius: 2px;
  }
`;

const SearchWrap = styled(Flex)`
  @media (max-width: 1110px) {
    display: block;
  }
`;

const Wrapper = styled(Container)`
  width: 100%;
  padding: 0;
  flex-direction: column;
  @media (min-width: 768px) {
    max-width: 90%;
  }
`;

const JobContainer = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  gap: 8px;
  margin-bottom: 20px;
  @media (min-width: 992px) {
    gap: 16px;
  }
  @media (min-width: 1200px) {
    gap: 24px;
  }
`;

const CardContainer = styled(Flex)`
  background-color: var(--light-values-white);
  overflow: visible;
  width: 100%;
  gap: 8px;
  @media (min-width: 768px) {
    max-width: 230px;
    min-width: 230px;
  }
  @media (min-width: 992px) {
    min-width: 320px;
    max-width: 320px;
  }
  @media (min-width: 1200px) {
    min-width: 350px;
    max-width: 350px;
  }
  @media (min-width: 1440px) {
    min-width: 370px;
    max-width: 370px;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 31, 0.12);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
`;

const Card = styled(Flex)`
  padding: 12px;
  gap: 4px;
  background-color: var(--light-values-white);
  border: 1px solid ${({ isSelected }) => (isSelected ? '#1688FE' : '#ECECED')};
  border-radius: 4px;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CardTitle = styled.div`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 22px;
`;

const Subtitle = styled.div`
  color: var(--grayscale-n500);
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  line-height: 20px;
`;

const Footer = styled.div`
  display: flex;
  gap: 10px;
  color: #595964;
`;
