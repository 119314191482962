import { useContext, useEffect } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import dynamic from 'next/dynamic';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import { ThemeProvider } from 'styled-components';
import AccountContext from '/imports/core/api/accounts/accountContext';
import { removeExperiment, getActiveRepositingVar } from '/lib/helpers';
import env from '/env';
import ResumeGeneratorSkeleton from '/imports/generator/ui/skeleton/ResumeGeneratorSkeleton';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';
import { useRouter } from 'next/router';
import { getCountry } from 'imports/checkout/api/utils';
import { isWithCareerABTest, getVariantWeightByLocale } from 'imports/carrer/api/helper';
import { RenderExpPowerSkillsTagsMobile } from 'imports/experiment/PowerSkillsTagsMobile';
import ResumeCareerLead from '/imports/experiment/ResumeCareerLead';
import { EnhancedTranslationOtherLocale } from 'imports/experiment/RenderTranslateCV';

const isProd = env.NODE_ENV === 'production';

const GeneratorDynamic = dynamic(() => import('/imports/generator/ui/pages/GeneratorPage'), {
  loading: () => <ResumeGeneratorSkeleton />,
});

function GeneratorWrapper(props) {
  const { currentUser, refetch } = useContext(AccountContext);
  const { host, isMobile, isGeneratorMobileView } = useResponsive();
  const { trackUserIdentity } = useTracking();
  const { locale } = useIntl();
  const country = getCountry();
  const {
    pageProps: { BlockCountry, BlockGoogleMapCountry },
  } = props;
  const router = useRouter();
  useEffect(() => {
    // Clean up localStorage for removed experiments
    const experimentsToRemove = [
      'address_autocomplete_experiment',
      'welcome_gift_popup_experiment',
      'column_switch_tooltip_experiment',
      'heading_wizard_automatic_experiment',
      'side_inverse_experiment',
      'scale_preview_experiment',
      'video_preview_experiment',
      'hide_header_finalise_experiment',
      'drag_and_drop_experiment',
      'resume_preview_button_experiment',
      'resume_preview_button_new_experiment',
      'drag_drop_v2_experiment',
      'pre_written_text_v2_experiment',
      'exp_ai_cta',
      'exp_ai_modal',
      'exp_ai_text',
      'exp_ai_design',
      'exp_ai_title',
      'exp_ai_modal_v2',
      'ai_suggestion_cta_badge',
      'exp_block_expandable',
      'exp_template_cv',
      'openai_models',
      'exp_expand_preview_button',
      'exp_heading_start_title',
      'exp_column_switcher',
      'new_flow_last_step_exp',
      'exp_career_status',
      'exp_career_video',
      'exp_drag_and_drop',
      'exp_auto_fetch_related_skills',
      'ai_popup_experience_prompt_exp',
      'skill_autocomplete_exp_en',
      'job_title_autocomplete_exp_en',
      'exp_places_autocomplete',
      'exp_places_autocomplete_v2',
      'exp_summary_suggestion',
      'exp_auto_expand_description_mobile',
      'exp_exit_popup',
      'exp_scoring_plugin',
      'block_reposition_toggle_exp',
      'exp_drag_and_drop_mobile',
      'exp_new_drag_and_drop',
      'exp_other_section_DD_V2',
      'exp_address_field',
      'exp_new_other_section_DD_V2',
      'exp_skills_DD_V2',
      'exp_template_budapest_v2',
      'exp_educ_exper_desk_DD',
      'exp_placekit_autocomplete',
      'exp_bullet_point_setup_in_text_field',
      'exp_extra_degree_for_arabic',
      'exp_placekit_autocomplete_v2',
      'exp_education_experience_dnd',
      'exp_career_finalize_pop_whatsapp',
      'job_title_autocomplete_exp_en_new',
      'skill_autocomplete_exp_en_new',
      'exp_career_status_v2_mobile',
      'exp_skills_tags_with_more_space_exp',
      'exp_auto_fetch_related_skills_new',
      'exp_rename_section_title',
      'exp_skills_tags_v1',
      'exp_notification_limit',
      'exp_experience_hide_month',
      'exp_undoredo_textarea_editor',
      'exp_career_status_v3',
      'exp_lead_generation',
      'exp_lead_generation_v2',
      'exp_translate_cv',
      'exp_builder_size_ratio_reverse',
      'exp_builder_size_ratio',
      'block_reposition_toggle_exp_v2',
      'ai_suggestion_openai_model',
      'ai_suggestion',
      'ai_suggestion_mobile',
      'exp_translation_en_new_design',
    ];

    if (locale !== 'ar') experimentsToRemove.push('exp_add_block_step');
    if (!isWithCareerABTest(locale, country)) {
      experimentsToRemove.push('exp_career_status_v2');
      if (!country === 'FR' && isMobile) {
        experimentsToRemove.push('exp_career_status_v2_mobile');
      }
    }

    experimentsToRemove.forEach((exp) => removeExperiment(exp));

    if (currentUser) {
      trackUserIdentity(currentUser, true, {}, false);
    }
  }, [currentUser, host, locale, trackUserIdentity]);

  const invertTheme = (theme) => ({ ...theme, designV2: true });

  const renderGeneratorSide = () => (
    <ThemeProvider theme={invertTheme}>
      <GeneratorDynamic
        currentUser={currentUser}
        userRefetch={refetch}
        BlockCountry={BlockCountry}
        BlockGoogleMapCountry={BlockGoogleMapCountry}
      />
    </ThemeProvider>
  );

  /**AB test exp add block */

  return (
    <EnhancedTranslationOtherLocale>
      <RenderExpScoringPlugin renderGeneratorSide={renderGeneratorSide} />
    </EnhancedTranslationOtherLocale>
  );
}

GeneratorWrapper.displayName = 'GeneratorWrapper';

GeneratorWrapper.propTypes = {
  pageProps: PropTypes.object,
};

export default GeneratorWrapper;

const RenderToggleABtest = (props) => {
  const { renderGeneratorSide } = props;
  const { isMobile } = useResponsive();
  const activeVar = getActiveRepositingVar();
  const withComplete = activeVar === 'with_complete_repositioning';
  if (isMobile || !withComplete) return renderGeneratorSide();
  return (
    <MarvelExperiment name="block_reposition_toggle_exp_v2">
      <MarvelVariant name="control">{renderGeneratorSide()}</MarvelVariant>
      <MarvelVariant name="with_modal">{renderGeneratorSide()}</MarvelVariant>
      <MarvelVariant name="with_tooltip">{renderGeneratorSide()}</MarvelVariant>
    </MarvelExperiment>
  );
};

const AddNewPromptAbtestWithV2Suggestion = (props) => {
  return (
    <MarvelExperiment name="exp_ideas_suggestion_prompt">
      <MarvelVariant name="control">
        <RephrasingPromptABTest {...props} />
      </MarvelVariant>
      <MarvelVariant name="new_prompt">
        <RephrasingPromptABTest {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

const RephrasingPromptABTest = (props) => {
  return (
    <MarvelExperiment name="exp_rephrasing_prompt">
      <MarvelVariant name="control">
        <RenderCTATextExp {...props} />
      </MarvelVariant>
      <MarvelVariant name="new_rephrasing_prompt">
        <RenderCTATextExp {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

const RenderCTATextExp = (props) => {
  const { locale } = useIntl();
  const stopAiSuggestion = ['en', 'fr', 'es'].includes(locale);
  if (stopAiSuggestion) return <RenderToggleABtest {...props} />;
  return (
    <MarvelExperiment name="ai_suggestion_cta">
      <MarvelVariant name="ai_suggestion_cta_v1">
        <RenderToggleABtest {...props} />
      </MarvelVariant>
      <MarvelVariant name="ai_suggestion_cta_v2">
        <RenderToggleABtest {...props} />
      </MarvelVariant>
      <MarvelVariant name="ai_suggestion_cta_v3">
        <RenderToggleABtest {...props} />
      </MarvelVariant>
      <MarvelVariant name="ai_suggestion_cta_v4">
        <RenderToggleABtest {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

const RenderExpAddBlockStep = (props) => {
  const { locale } = useIntl();
  if (locale !== 'ar') return <AddNewPromptAbtestWithV2Suggestion {...props} />;
  return (
    <MarvelExperiment name="exp_add_block_step">
      <MarvelVariant name="control">
        <AddNewPromptAbtestWithV2Suggestion {...props} />
      </MarvelVariant>
      <MarvelVariant name="with_additional_add_block_step">
        <AddNewPromptAbtestWithV2Suggestion {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

const RenderExpScoringPlugin = (props) => {
  useEffect(() => {
    const expScoring = ['with_popup_with_plugin', 'without_popup_with_plugin'];
    if (!expScoring.includes(marvelEmitter.getActiveVariant('exp_scoring_plugin_v2'))) {
      marvelEmitter.setActiveVariant('exp_scoring_plugin_v2', _.sample(expScoring));
    }
  }, []);

  return (
    <MarvelExperiment name="exp_scoring_plugin_v2">
      <MarvelVariant name="with_popup_with_plugin">
        <RenderExpAddBlockStep {...props} />
      </MarvelVariant>
      <MarvelVariant name="without_popup_with_plugin">
        <RenderExpAddBlockStep {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};
