import { create } from 'zustand';

export const useSearchStore = create((set) => ({
  SearchData: null,
  podioJobsData: [],
  searchfeedId: null,
  activeCardData: null,
  jobsData: null,
  searchTextData: null,
  cityTextData: null,
  hasMoreData: '',
  savedFeedNameData: '',
  isLoading: false,
  position: 0,
  filter: {},
  regionCodeData: '',
  indexData: 0,
  candidateSearchs: [],
  searchDataList: null,
  IndeedJobData: null,
  cityDefaultOption: [],
  nextPage: '',
  setNextPage: (nextPage) => set({ nextPage: nextPage }),
  setsearchData: (search) =>
    set(() => ({
      SearchData: search,
    })),

  setPodioJobsData: (podio) => set({ podioJobsData: podio }),

  setSearchId: (id) => set({ searchfeedId: id }),

  setactiveCard: (active) =>
    set({
      activeCardData: active,
    }),

  setJobsData: (jobs) =>
    set({
      jobsData: jobs,
    }),

  setSearchText: (search) =>
    set({
      searchTextData: search,
    }),

  setCityTextData: (city) =>
    set({
      cityTextData: city,
    }),

  setHasMore: (ishasMore) =>
    set({
      hasMoreData: ishasMore,
    }),

  setsavedFeedNameData: (savedFeed) =>
    set({
      savedFeedNameData: savedFeed,
    }),

  setLoading: (loading) =>
    set({
      isLoading: loading,
    }),

  setPosition: (data) =>
    set({
      position: data,
    }),

  setFilter: (data) =>
    set({
      filter: data,
    }),

  setRegionCode: (country) => set({ regionCodeData: country }),

  setIndex: (index) => set({ indexData: index }),

  setCandidateSearchs: (search) =>
    set({
      candidateSearchs: search,
    }),

  setSearchDataList: (list) => set({ searchDataList: list }),

  setIndeedJobData: (data) => set({ IndeedJobData: data }),

  setCityDefaultOptions: (cityData) => set({ cityDefaultOption: cityData }),
}));
