import { useEffect } from 'react';
import Flex from 'imports/core/ui/atoms/Flex';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled, { css } from 'styled-components';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import Checkbox from '/imports/core/ui/atoms/Checkbox';
import Label from '/imports/core/ui/atoms/Label';
import { getInputName } from '/imports/generator/api/helpers';

const SocialLinkInputCheckBox = (props) => {
  const {
    checked,
    label,
    language,
    name,
    variables,
    onChange,
    resume: { blocks },
  } = props;
  const { trackEvent } = useTracking();
  const { t, locale } = useIntl();

  const socialLinkBlock = blocks.find((block) => block.type === 'SOCIAL_LINKS');
  const blockWithShowOnHeader = socialLinkBlock?.items?.find((item) => item.fields?.showOnHeader === true);
  const isDisabled = blockWithShowOnHeader && blockWithShowOnHeader.id !== variables?.itemId;

  const handleChange = () => {
    trackEvent(`social_link_${!checked ? 'add' : 'remove'}_on_header`);
    onChange({ target: { value: !checked } });
  };
  const checkboxId = `toggle-checkbox-sociallink-${variables?.itemId}`;

  useEffect(() => {
    if (socialLinkBlock?.items?.length === 1) {
      const item = socialLinkBlock?.items[0];
      if ([null, undefined].includes(item?.fields?.showOnHeader)) {
        onChange({ target: { value: true } });
      }
    }
  }, []);

  return (
    <Wrapper
      alignItems="center"
      isDisabled={isDisabled}
      data-tooltip-id={isDisabled ? 'show-sociallink-tooltip' : undefined}
      data-tooltip-content={isDisabled ? t('generator.show_on_header_tooltip') : undefined}
    >
      <Checkbox
        id={checkboxId}
        checked={checked}
        onClick={handleChange}
        name={`interactive-element-${getInputName(name, variables)}`}
        color="#1688fe"
        customCss={checkBoxStyle}
        isDisabled={isDisabled}
      />
      <StyledLabel isDisabled={isDisabled} htmlFor={checkboxId} language={language}>
        {label}
      </StyledLabel>
      {isDisabled && (
        <Tooltip
          id="show-sociallink-tooltip"
          opacity={1}
          style={{
            fontSize: '14px',
            fontFamily: 'Gilroy Bold',
          }}
        />
      )}
    </Wrapper>
  );
};

export default SocialLinkInputCheckBox;

const checkBoxStyle = css`
  width: 16px !important;
  height: 16px !important;
  border: 2px solid #e6e6fd;
  background: #fff;
  border-radius: 3px;
`;

const Wrapper = styled(Flex)`
  margin-top: 10px;
  width: fit-content;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

const Tooltip = styled(ReactTooltip)`
  max-width: 270px;
  text-align: center;
  z-index: ${(p) => p.theme.zIndex.popover};
`;

const StyledLabel = styled(Label)`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  color: #7171a6;
  cursor: pointer;
  margin: 0;
  margin-left: 5px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
