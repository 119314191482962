import { create } from 'zustand';

export const useCheckoutResumeStore = create((set) => ({
  resume: null,
  bruneiTitleLines: 0,
  bruneiNameLines: 0,
  setResume: (resume) => set({ resume }),
  setBruneiTitleLines: (bruneiTitleLines) => set({ bruneiTitleLines }),
  setBruneiNameLines: (bruneiNameLines) => set({ bruneiNameLines }),
}));
