import React from 'react';
import styled, { css } from 'styled-components';

const RightArrow = ({ stroke = '#FFF' }) => {
  return (
    <IconCont>
      <SvgIcon width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" stroke={stroke} strokeWidth="2">
          <path strokeLinecap="round" d="M15.804 12.062L21.834 6.031 15.804 0" transform="translate(0 1)" />
          <path d="M0 6.031L22 6.031" transform="translate(0 1)" />
        </g>
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled.div`
  margin: auto 0;
`;

const SvgIcon = styled.svg`
  stroke: none;
`;

export default RightArrow;
