import React from 'react';

const MoneyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M13.125 2.625H0.875V11.6083H13.125V2.625Z"
        stroke="#14141F"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.99844 8.34166C7.67499 8.34166 8.22344 7.79321 8.22344 7.11666C8.22344 6.44011 7.67499 5.89166 6.99844 5.89166C6.32189 5.89166 5.77344 6.44011 5.77344 7.11666C5.77344 7.79321 6.32189 8.34166 6.99844 8.34166Z"
        stroke="#14141F"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.85781 4.25833H4.14115C4.14115 5.18076 3.43024 5.89166 2.50781 5.89166V8.34166C3.43024 8.34166 4.14115 9.05257 4.14115 9.975H9.85781C9.85781 9.05257 10.5687 8.34166 11.4911 8.34166V5.89166C10.5687 5.89166 9.85781 5.18076 9.85781 4.25833Z"
        stroke="#14141F"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default MoneyIcon;
