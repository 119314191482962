import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const Container = styled(Flex)`
  width: 90%;
  max-width: 90%;
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 100%;
    max-width: 652px;
  }
  @media (min-width: 992px) {
    max-width: 876px;
  }
  @media (min-width: 1200px) {
    max-width: 912px;
  }
  @media (min-width: 1440px) {
    max-width: 1000px;
  }
  @media (min-width: 1920px) {
    max-width: 1392px;
  }
`;

export default Container;
