import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import useIntl from '/imports/core/api/useIntl';

const SearchToolTip = ({ show }) => {
  const { t } = useIntl();
  return (
    <CSSTransition appear in={show} unmountOnExit classNames="toast" timeout={500}>
      <ToastWrapper>
        <Toast>
          <PointArrow />
          <ToastText>{t('jobtrack_filter_tooltip_text')}</ToastText>
        </Toast>
      </ToastWrapper>
    </CSSTransition>
  );
};

const PointArrow = styled.div`
  position: absolute;
  top: -5px;
  left: 10px;
  right: 0;
  margin-right: auto;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--grayscale-n800);
  @media (min-width: 768px) {
    left: 100px;
    margin-left: auto;
  }
`;

const ToastWrapper = styled.div`
  &.toast-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &.toast-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 500ms,
      transform 500ms;
  }
  &.toast-exit {
    opacity: 1;
  }
  &.toast-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition:
      opacity 500ms,
      transform 500ms;
  }
  position: absolute;
  top: 133px;
  z-index: 1;
  width: 100%;
  @media (min-width: 768px) {
    top: 65px;
  }
  @media (min-width: 1000px) {
    left: 0%;
  }
`;

const Toast = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 12px;
  min-height: auto;
  min-width: auto;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #ccc;
  user-select: none;
  z-index: 9999;
  background-color: var(--grayscale-n800);
  @media (min-width: 768px) {
    min-width: 270px;
  }
  @media (min-width: 1200px) {
    min-height: 38px;
    min-width: 543px;
  }
`;

const ToastText = styled.div`
  color: var(--light-values-white);
  font-size: 14px;
  line-height: 14px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
`;

export default SearchToolTip;
