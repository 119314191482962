import React from 'react';
import useDomain from 'imports/core/hooks/useDomain';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { getDomainName, isBlogDomain } from 'lib/helpers';
import { Push } from 'components/Link';
import { replaceString } from 'imports/core/ui/helpers';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import ImageWrapper from '/imports/help/ui/atoms/ImageWrapper';
import {
  StyledText,
  SubsectionTitle,
  StyledListItem,
  StyledListContainer,
  TextWithLinkWrapper,
} from '/imports/help/ui/atoms/Typography';
import useIntl from '/imports/core/api/useIntl';

const LoginToResumeDone = () => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  const { getBrandName } = useDomain();
  const blog = isBlogDomain(host);
  const getLoginUrl = () => {
    return blog ? `builder/${locale}/login` : `${locale}/login`;
  };

  const loginUrl = `https://${getDomainName(host)}/${getLoginUrl()}`;
  const step1 = replaceString(t('help.login_to_resumedone.step1'), { LOGIN_URL: loginUrl });
  React.useEffect(() => {
    const goToLoginPage = () => {
      window.open(loginUrl, '_blank');
    };
    const loginLink = document.getElementById('login_link');
    loginLink?.addEventListener('click', goToLoginPage);
    const signinLink = document.getElementById('signin_link');
    signinLink?.addEventListener('click', goToLoginPage);
    return () => {
      loginLink?.removeEventListener('click', goToLoginPage);
      signinLink?.removeEventListener('click', goToLoginPage);
    };
  }, []);

  return (
    <ArticlePageWrapper
      title={replaceString(t('help.login_to_resumedone'), { BRAND_NAME: getBrandName(true, host) })}
      translated
    >
      <StyledText>
        {replaceString(t('help.login_to_resumedone.resume_editing_access'), { BRAND_NAME: getBrandName(false, host) })}
      </StyledText>
      <SubsectionTitle>{t('help.login_to_resumedone.logging_and_troubleshooting')}</SubsectionTitle>
      <TextWithLinkWrapper dangerouslySetInnerHTML={{ __html: step1 }} />
      <ImageWrapper src={images.login_1} />
      <StyledText>{t('help.login_to_resumedone.step2')}</StyledText>
      <ImageWrapper src={images.login_2} />
      <StyledText>{t('help.login_to_resumedone.step3')}</StyledText>
      <ImageWrapper src={images.login_3} />
      <StyledText>{t('help.login_to_resumedone.boom_youre_in')}</StyledText>
      <SubsectionTitle>{t('help.login_to_resumedone.pro_tips')}</SubsectionTitle>
      <StyledText>
        {replaceString(t('help.login_to_resumedone.login_troubleshoot_tips'), { BRAND_NAME: getDomainName(host) })}
      </StyledText>
      <StyledListContainer>
        <StyledListItem>
          {replaceString(t('help.login_to_resumedone.check_spam'), { Resumedone: getBrandName(host) })}
        </StyledListItem>
      </StyledListContainer>
      <TextWithLinkWrapper dangerouslySetInnerHTML={{ __html: t('help.login_to_resumedone.link_expires') }} />
    </ArticlePageWrapper>
  );
};

export default LoginToResumeDone;
