import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import intlHook from 'imports/core/api/useIntl';

export const EnhancedTranslationOtherLocale = (props) => {
  const { locale } = intlHook();
  if (locale === 'en') return props.children;
  return (
    <MarvelExperiment name="exp_translation_non_en_locale_new_design">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="new_design">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
};
