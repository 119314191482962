export const UploadIconAutoApply = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45Z"
        fill="#E9F5F1"
      />
      <path
        d="M11.5098 28.0369H60.6191V42.4632C60.6191 48.9991 60.6191 54.9425 63.7976 57.6401H78.2803C77.5242 65.8868 72.9914 70.7608 69.4386 71.8718H25.3434C14.2763 71.8718 11.5098 57.0145 11.5098 46.7498V28.0369Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 27.5278H61.1273V42.4631C61.1273 45.7423 61.1284 48.8205 61.5189 51.4152C61.8977 53.9326 62.6302 55.8942 63.9886 57.1311H78.8373L78.7863 57.6865C78.0168 66.0791 73.3894 71.1692 69.5897 72.3574L69.5155 72.3806H25.3426C19.5151 72.3806 15.919 68.445 13.8056 63.3551C11.6942 58.2701 11 51.914 11 46.7498V27.5278ZM29.1763 71.3627H69.3584C72.5796 70.3091 76.8323 65.8495 77.7154 58.149H37.0024C36.4403 60.9251 35.2614 64.3439 33.4398 67.1313C32.2994 68.8764 30.8809 70.4107 29.1763 71.3627ZM62.5986 57.1311H36.1589L36.082 57.5477C35.5751 60.295 34.4104 63.7853 32.5877 66.5745C30.7593 69.3725 28.3418 71.3627 25.3426 71.3627C20.103 71.3627 16.7823 67.8697 14.7457 62.9648C12.707 58.055 12.0179 51.8502 12.0179 46.7498V28.5457H60.1093V42.4911C60.1093 45.7379 60.1093 48.8889 60.5123 51.5667C60.8411 53.7512 61.4472 55.6921 62.5986 57.1311Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M51.2534 33.0154C51.2534 41.3082 44.5308 48.0308 36.238 48.0308C27.9453 48.0308 21.2227 41.3082 21.2227 33.0154C21.2227 24.7226 27.9453 18 36.238 18C44.5308 18 51.2534 24.7226 51.2534 33.0154Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2375 24.4463C36.5186 24.4463 36.7464 24.6742 36.7464 24.9552L36.7464 41.6036C36.7464 41.8847 36.5186 42.1126 36.2375 42.1126C35.9564 42.1126 35.7285 41.8847 35.7285 41.6036L35.7285 24.9552C35.7285 24.6742 35.9564 24.4463 36.2375 24.4463Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2384 23.707L43.5699 31.0385C43.7686 31.2372 43.7686 31.5595 43.5699 31.7582C43.3711 31.957 43.0488 31.957 42.8501 31.7582L36.2384 25.1466L29.6266 31.7582C29.4279 31.957 29.1056 31.957 28.9069 31.7582C28.7081 31.5595 28.7081 31.2372 28.9069 31.0385L36.2384 23.707Z"
        fill="white"
      />
    </svg>
  );
};

export const NormalAutoApply = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45Z"
        fill="#E9F5F1"
      />
      <path
        d="M11.5 41.2311C11.5 28.1248 22.1248 17.5 35.2311 17.5H55.323C68.4293 17.5 79.0541 28.1248 79.0541 41.2311V42.3802C79.0541 54.8519 68.9437 64.9622 56.472 64.9622H35.2311C22.1248 64.9622 11.5 54.3374 11.5 41.2311Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 41.2311C11 27.8486 21.8486 17 35.2311 17H55.323C68.7054 17 79.5541 27.8486 79.5541 41.2311V42.3802C79.5541 55.128 69.2199 65.4622 56.472 65.4622H35.2311C21.8486 65.4622 11 54.6136 11 41.2311ZM35.2311 18C22.4009 18 12 28.4009 12 41.2311C12 54.0613 22.4009 64.4622 35.2311 64.4622H56.472C68.6676 64.4622 78.5541 54.5757 78.5541 42.3802V41.2311C78.5541 28.4009 68.1532 18 55.323 18H35.2311Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M34.6582 30.974C34.6582 29.4951 35.8571 28.2961 37.3361 28.2961H57.6772C59.1561 28.2961 60.3551 29.4951 60.3551 30.974C60.3551 32.453 59.1561 33.6519 57.6772 33.6519H37.3361C35.8571 33.6519 34.6582 32.453 34.6582 30.974Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1582 30.974C34.1582 29.2189 35.581 27.7961 37.3361 27.7961H57.6772C59.4323 27.7961 60.8551 29.2189 60.8551 30.974C60.8551 32.7291 59.4323 34.1519 57.6772 34.1519H37.3361C35.581 34.1519 34.1582 32.7291 34.1582 30.974ZM37.3361 28.7961C36.1333 28.7961 35.1582 29.7712 35.1582 30.974C35.1582 32.1768 36.1333 33.1519 37.3361 33.1519H57.6772C58.88 33.1519 59.8551 32.1768 59.8551 30.974C59.8551 29.7712 58.88 28.7961 57.6772 28.7961H37.3361Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M21.6621 31.0575C26.2667 31.0575 27.1013 28.0959 27.1013 25.6182C27.1013 28.8894 28.5631 31.0575 31.9866 31.0575C27.6983 31.0575 27.0976 34.7598 27.1013 36.3296C27.0932 32.9293 24.7909 31.0575 21.6621 31.0575Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8681 29.0875C26.5949 28.1143 26.8013 26.8329 26.8013 25.6182H27.4013C27.4013 27.2151 27.7589 28.4955 28.4905 29.3724C29.2141 30.2397 30.3418 30.7575 31.9866 30.7575V31.3575C29.9479 31.3575 28.8222 32.2278 28.1904 33.2613C27.5452 34.3168 27.3995 35.5718 27.4013 36.3289L26.8013 36.3303C26.7974 34.7012 26.2463 33.4674 25.3485 32.6389C24.4474 31.8074 23.1679 31.3575 21.6621 31.3575V30.7575C23.895 30.7575 25.1541 30.0436 25.8681 29.0875ZM23.8406 31.0896C24.5678 31.3301 25.2162 31.7004 25.7554 32.198C26.3567 32.7529 26.8124 33.457 27.0901 34.2958C27.2229 33.8458 27.4121 33.3841 27.6785 32.9484C28.1665 32.15 28.9067 31.4513 30.0001 31.0684C29.1885 30.803 28.5325 30.3593 28.0298 29.7567C27.6028 29.245 27.296 28.63 27.0957 27.934C26.9338 28.4682 26.6945 28.9836 26.3488 29.4465C25.795 30.188 24.9866 30.7711 23.8406 31.0896Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M34.6582 51.8522C34.6582 50.3732 35.8571 49.1743 37.3361 49.1743H57.6772C59.1561 49.1743 60.3551 50.3732 60.3551 51.8522C60.3551 53.3311 59.1561 54.53 57.6772 54.53H37.3361C35.8571 54.53 34.6582 53.3311 34.6582 51.8522Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1582 51.8522C34.1582 50.0971 35.581 48.6743 37.3361 48.6743H57.6772C59.4323 48.6743 60.8551 50.0971 60.8551 51.8522C60.8551 53.6073 59.4323 55.03 57.6772 55.03H37.3361C35.581 55.03 34.1582 53.6073 34.1582 51.8522ZM37.3361 49.6743C36.1333 49.6743 35.1582 50.6494 35.1582 51.8522C35.1582 53.055 36.1333 54.03 37.3361 54.03H57.6772C58.88 54.03 59.8551 53.055 59.8551 51.8522C59.8551 50.6494 58.88 49.6743 57.6772 49.6743H37.3361Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M21.6621 51.5717C26.2667 51.5717 27.1013 48.6101 27.1013 46.1323C27.1013 49.4036 28.5631 51.5717 31.9866 51.5717C27.6983 51.5717 27.0976 55.2739 27.1013 56.8438C27.0932 53.4435 24.7909 51.5717 21.6621 51.5717Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8681 49.6016C26.5949 48.6284 26.8013 47.347 26.8013 46.1323H27.4013C27.4013 47.7293 27.7589 49.0096 28.4905 49.8865C29.2141 50.7538 30.3418 51.2717 31.9866 51.2717V51.8717C29.9479 51.8717 28.8222 52.742 28.1904 53.7755C27.5452 54.8309 27.3995 56.0859 27.4013 56.8431L26.8013 56.8445C26.7974 55.2154 26.2463 53.9815 25.3485 53.1531C24.4474 52.3216 23.1679 51.8717 21.6621 51.8717V51.2717C23.895 51.2717 25.1541 50.5578 25.8681 49.6016ZM23.8406 51.6038C24.5678 51.8443 25.2162 52.2145 25.7554 52.7121C26.3567 53.267 26.8124 53.9712 27.0901 54.81C27.2229 54.3599 27.4121 53.8983 27.6785 53.4626C28.1665 52.6641 28.9067 51.9655 30.0001 51.5826C29.1885 51.3171 28.5325 50.8734 28.0298 50.2709C27.6028 49.7592 27.296 49.1441 27.0957 48.4481C26.9338 48.9824 26.6945 49.4978 26.3488 49.9607C25.795 50.7022 24.9866 51.2853 23.8406 51.6038Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M34.4707 41.2311C34.4707 39.7521 35.6696 38.5532 37.1486 38.5532H57.679C59.1579 38.5532 60.3568 39.7521 60.3568 41.2311C60.3568 42.71 59.1579 43.909 57.679 43.909H37.1486C35.6696 43.909 34.4707 42.71 34.4707 41.2311Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9707 41.2311C33.9707 39.476 35.3935 38.0532 37.1486 38.0532H57.679C59.4341 38.0532 60.8568 39.476 60.8568 41.2311C60.8568 42.9862 59.4341 44.409 57.679 44.409H37.1486C35.3935 44.409 33.9707 42.9862 33.9707 41.2311ZM37.1486 39.0532C35.9458 39.0532 34.9707 40.0283 34.9707 41.2311C34.9707 42.4339 35.9458 43.409 37.1486 43.409H57.679C58.8818 43.409 59.8568 42.4339 59.8568 41.2311C59.8568 40.0283 58.8818 39.0532 57.679 39.0532H37.1486Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M67.3086 37.0168L71.5228 41.2311L67.3086 45.4453L63.0944 41.2311L67.3086 37.0168Z"
        fill="rgb(22, 136, 254)"
      />
      <path d="M71.7654 71.2542L53.7598 63.9151V60.3278L68.294 58.415V61.8713L71.7654 71.2542Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0379 60.8484L72.2768 72.3489L54.8262 65.3934L55.1964 64.4645L70.765 70.6698L68.0754 61.1195L69.0379 60.8484Z"
        fill="rgb(22, 136, 254)"
      />
    </svg>
  );
};

export const FileUploadDoneIcon = () => {
  return (
    <svg width="91" height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 45C0.5 20.1472 20.6472 0 45.5 0C70.3528 0 90.5 20.1472 90.5 45C90.5 69.8528 70.3528 90 45.5 90C20.6472 90 0.5 69.8528 0.5 45Z"
        fill="#E9F5F1"
      />
      <path
        d="M21.0352 20.1453C21.0352 18.1477 22.6545 16.5283 24.6521 16.5283H63.8154C65.813 16.5283 67.4324 18.1477 67.4324 20.1453V70.83C67.4324 72.8275 65.813 74.4469 63.8154 74.4469H24.6521C22.6545 74.4469 21.0352 72.8275 21.0352 70.83V20.1453Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5352 20.1453C20.5352 17.8715 22.3784 16.0283 24.6521 16.0283H63.8154C66.0891 16.0283 67.9324 17.8715 67.9324 20.1453V70.83C67.9324 73.1037 66.0891 74.9469 63.8154 74.9469H24.6521C22.3784 74.9469 20.5352 73.1037 20.5352 70.83V20.1453ZM24.6521 17.0283C22.9307 17.0283 21.5352 18.4238 21.5352 20.1453V70.83C21.5352 72.5514 22.9307 73.9469 24.6521 73.9469H63.8154C65.5369 73.9469 66.9324 72.5514 66.9324 70.83V20.1453C66.9324 18.4238 65.5369 17.0283 63.8154 17.0283H24.6521Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M26.2285 49.2185C26.2285 48.4195 26.8763 47.7717 27.6753 47.7717H60.7911C61.5902 47.7717 62.2379 48.4195 62.2379 49.2185V67.5735C62.2379 68.3725 61.5902 69.0203 60.7911 69.0203H27.6753C26.8763 69.0203 26.2285 68.3725 26.2285 67.5735V49.2185Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7285 49.2185C25.7285 48.1433 26.6001 47.2717 27.6753 47.2717H60.7911C61.8663 47.2717 62.7379 48.1433 62.7379 49.2185V67.5735C62.7379 68.6487 61.8663 69.5203 60.7911 69.5203H27.6753C26.6001 69.5203 25.7285 68.6487 25.7285 67.5735V49.2185ZM27.6753 48.2717C27.1524 48.2717 26.7285 48.6956 26.7285 49.2185V67.5735C26.7285 68.0964 27.1524 68.5203 27.6753 68.5203H60.7911C61.314 68.5203 61.7379 68.0964 61.7379 67.5735V49.2185C61.7379 48.6956 61.314 48.2717 60.7911 48.2717H27.6753Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M26.2285 35.5569C26.2285 34.7579 26.8763 34.1101 27.6753 34.1101H60.7911C61.5902 34.1101 62.2379 34.7579 62.2379 35.5569V42.7453C62.2379 43.5443 61.5902 44.1921 60.7911 44.1921H27.6753C26.8763 44.1921 26.2285 43.5443 26.2285 42.7453V35.5569Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7285 35.5569C25.7285 34.4817 26.6001 33.6101 27.6753 33.6101H60.7911C61.8663 33.6101 62.7379 34.4817 62.7379 35.5569V42.7453C62.7379 43.8205 61.8663 44.6921 60.7911 44.6921H27.6753C26.6001 44.6921 25.7285 43.8205 25.7285 42.7453V35.5569ZM27.6753 34.6101C27.1524 34.6101 26.7285 35.034 26.7285 35.5569V42.7453C26.7285 43.2682 27.1524 43.6921 27.6753 43.6921H60.7911C61.314 43.6921 61.7379 43.2682 61.7379 42.7453V35.5569C61.7379 35.034 61.314 34.6101 60.7911 34.6101H27.6753Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M38.1621 23.4148C38.1621 22.6158 38.8099 21.968 39.6089 21.968H60.7905C61.5896 21.968 62.2373 22.6158 62.2373 23.4148V29.0839C62.2373 29.8829 61.5896 30.5307 60.7905 30.5307H39.6089C38.8099 30.5307 38.1621 29.8829 38.1621 29.0839V23.4148Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6621 23.4148C37.6621 22.3396 38.5337 21.468 39.6089 21.468H60.7905C61.8657 21.468 62.7373 22.3396 62.7373 23.4148V29.0839C62.7373 30.1591 61.8657 31.0307 60.7905 31.0307H39.6089C38.5337 31.0307 37.6621 30.1591 37.6621 29.0839V23.4148ZM39.6089 22.468C39.086 22.468 38.6621 22.8919 38.6621 23.4148V29.0839C38.6621 29.6068 39.086 30.0307 39.6089 30.0307H60.7905C61.3134 30.0307 61.7373 29.6068 61.7373 29.0839V23.4148C61.7373 22.8919 61.3134 22.468 60.7905 22.468H39.6089Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M35.2256 26.032C35.2256 28.5165 33.2115 30.5305 30.7271 30.5305C28.2426 30.5305 26.2285 28.5165 26.2285 26.032C26.2285 23.5475 28.2426 21.5334 30.7271 21.5334C33.2115 21.5334 35.2256 23.5475 35.2256 26.032Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7271 22.0337C28.5187 22.0337 26.7285 23.8239 26.7285 26.0322C26.7285 28.2406 28.5187 30.0308 30.7271 30.0308C32.9354 30.0308 34.7256 28.2406 34.7256 26.0322C34.7256 23.8239 32.9354 22.0337 30.7271 22.0337ZM25.7285 26.0322C25.7285 23.2716 27.9664 21.0337 30.7271 21.0337C33.4877 21.0337 35.7256 23.2716 35.7256 26.0322C35.7256 28.7928 33.4877 31.0308 30.7271 31.0308C27.9664 31.0308 25.7285 28.7928 25.7285 26.0322Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        d="M79.9767 46.69C79.9767 55.0202 73.2238 61.7731 64.8936 61.7731C56.5635 61.7731 49.8105 55.0202 49.8105 46.69C49.8105 38.3599 56.5635 31.6069 64.8936 31.6069C73.2238 31.6069 79.9767 38.3599 79.9767 46.69Z"
        fill="rgb(22, 136, 254)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.2553 42.0161C74.4506 42.2113 74.4506 42.5279 74.2553 42.7232L64.266 52.7125L56.9238 45.3704C56.7285 45.1751 56.7285 44.8585 56.9238 44.6633C57.119 44.468 57.4356 44.468 57.6309 44.6633L64.266 51.2983L73.5482 42.0161C73.7435 41.8208 74.0601 41.8208 74.2553 42.0161Z"
        fill="white"
      />
    </svg>
  );
};
