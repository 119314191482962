import React from 'react';

const NoJobIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
      <path
        d="M43.6364 0H4.36364C1.95367 0 0 1.95367 0 4.36364V43.6364C0 46.0463 1.95367 48 4.36364 48H43.6364C46.0463 48 48 46.0463 48 43.6364V4.36364C48 1.95367 46.0463 0 43.6364 0Z"
        fill="#EFF5FF"
      />
      <g mask="url(#mask0_1431_6026)">
        <g filter="url(#filter0_d_1431_6026)">
          <path
            d="M7.09094 4.36365H37.6364C38.8364 4.36365 39.8182 5.34547 39.8182 6.54547V50.6182H4.90912V6.54547C4.90912 5.34547 5.89094 4.36365 7.09094 4.36365Z"
            fill="white"
          />
          <path
            d="M32.6291 8.4436H19.5382C18.9357 8.4436 18.4473 8.93202 18.4473 9.53451C18.4473 10.137 18.9357 10.6254 19.5382 10.6254H32.6291C33.2316 10.6254 33.72 10.137 33.72 9.53451C33.72 8.93202 33.2316 8.4436 32.6291 8.4436Z"
            fill="#0050C7"
          />
          <path
            d="M26.8364 12.8073H19.5382C18.9357 12.8073 18.4473 13.2957 18.4473 13.8982C18.4473 14.5007 18.9357 14.9891 19.5382 14.9891H26.8364C27.4388 14.9891 27.9273 14.5007 27.9273 13.8982C27.9273 13.2957 27.4388 12.8073 26.8364 12.8073Z"
            fill="#0050C7"
          />
          <path
            d="M11.9018 16.0799C14.3118 16.0799 16.2654 14.1263 16.2654 11.7163C16.2654 9.30633 14.3118 7.35266 11.9018 7.35266C9.49181 7.35266 7.53815 9.30633 7.53815 11.7163C7.53815 14.1263 9.49181 16.0799 11.9018 16.0799Z"
            fill="#0050C7"
          />
          <path
            d="M14.5091 18.1637H9.58908C8.98659 18.1637 8.49817 18.6521 8.49817 19.2546C8.49817 19.8571 8.98659 20.3455 9.58908 20.3455H14.5091C15.1116 20.3455 15.6 19.8571 15.6 19.2546C15.6 18.6521 15.1116 18.1637 14.5091 18.1637Z"
            fill="#E3E3E4"
          />
          <path
            d="M32.6182 21.4801H9.58908C8.98659 21.4801 8.49817 21.9685 8.49817 22.571C8.49817 23.1735 8.98659 23.6619 9.58908 23.6619H32.6182C33.2207 23.6619 33.7091 23.1735 33.7091 22.571C33.7091 21.9685 33.2207 21.4801 32.6182 21.4801Z"
            fill="#E3E3E4"
          />
          <path
            d="M14.5091 28.5601H9.58908C8.98659 28.5601 8.49817 29.0485 8.49817 29.651C8.49817 30.2535 8.98659 30.7419 9.58908 30.7419H14.5091C15.1116 30.7419 15.6 30.2535 15.6 29.651C15.6 29.0485 15.1116 28.5601 14.5091 28.5601Z"
            fill="#E3E3E4"
          />
          <path
            d="M32.6182 31.8765H9.58908C8.98659 31.8765 8.49817 32.3649 8.49817 32.9674C8.49817 33.5699 8.98659 34.0583 9.58908 34.0583H32.6182C33.2207 34.0583 33.7091 33.5699 33.7091 32.9674C33.7091 32.3649 33.2207 31.8765 32.6182 31.8765Z"
            fill="#E3E3E4"
          />
          <path
            d="M14.5091 38.9563H9.58908C8.98659 38.9563 8.49817 39.4447 8.49817 40.0472C8.49817 40.6497 8.98659 41.1381 9.58908 41.1381H14.5091C15.1116 41.1381 15.6 40.6497 15.6 40.0472C15.6 39.4447 15.1116 38.9563 14.5091 38.9563Z"
            fill="#E3E3E4"
          />
          <path
            d="M32.6182 42.2727H9.58908C8.98659 42.2727 8.49817 42.7611 8.49817 43.3636C8.49817 43.9661 8.98659 44.4545 9.58908 44.4545H32.6182C33.2207 44.4545 33.7091 43.9661 33.7091 43.3636C33.7091 42.7611 33.2207 42.2727 32.6182 42.2727Z"
            fill="#E3E3E4"
          />
        </g>
      </g>
      <g filter="url(#filter1_d_1431_6026)">
        <path
          d="M28.5273 41.7819C35.2451 41.7819 40.6909 36.336 40.6909 29.6182C40.6909 22.9004 35.2451 17.4546 28.5273 17.4546C21.8095 17.4546 16.3636 22.9004 16.3636 29.6182C16.3636 36.336 21.8095 41.7819 28.5273 41.7819Z"
          fill="white"
          stroke="#0043A6"
          strokeMiterlimit="10"
        />
        <path
          d="M37.495 37.9882L37.657 37.8262C38.0113 37.4719 38.5858 37.4719 38.9401 37.8262L45.5509 44.437C45.9052 44.7913 45.9052 45.3658 45.5509 45.7201L45.3889 45.8821C45.0346 46.2364 44.4601 46.2364 44.1058 45.8821L37.495 39.2713C37.1407 38.917 37.1407 38.3425 37.495 37.9882Z"
          fill="#0043A6"
          stroke="#0043A6"
        />
      </g>
      <path
        d="M24 29.4545C24 28.2495 23.0232 27.2727 21.8182 27.2727C20.6132 27.2727 19.6364 28.2495 19.6364 29.4545C19.6364 30.6595 20.6132 31.6363 21.8182 31.6363C23.0232 31.6363 24 30.6595 24 29.4545Z"
        fill="#0866F5"
      />
      <path
        d="M36.5782 29.4545C36.5782 28.2495 35.6014 27.2727 34.3964 27.2727C33.1914 27.2727 32.2146 28.2495 32.2146 29.4545C32.2146 30.6595 33.1914 31.6363 34.3964 31.6363C35.6014 31.6363 36.5782 30.6595 36.5782 29.4545Z"
        fill="#C8E0FF"
      />
      <path
        d="M30.2945 29.4545C30.2945 28.2495 29.3177 27.2727 28.1127 27.2727C26.9077 27.2727 25.9309 28.2495 25.9309 29.4545C25.9309 30.6595 26.9077 31.6363 28.1127 31.6363C29.3177 31.6363 30.2945 30.6595 30.2945 29.4545Z"
        fill="#C8E0FF"
      />
      <defs>
        <filter
          id="filter0_d_1431_6026"
          x="2.90912"
          y="2.36365"
          width="42.9091"
          height="54.2545"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.121569 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1431_6026" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1431_6026" result="shape" />
        </filter>
        <filter
          id="filter1_d_1431_6026"
          x="13.8636"
          y="14.9546"
          width="38.4529"
          height="37.6932"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1431_6026" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1431_6026" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default NoJobIcon;
