import React from 'react';

const MicroSoftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <g clipPath="url(#clip0_21952_27963)">
        <path d="M0.5 0H12.5V12H0.5V0Z" fill="#F3F3F3" />
        <path d="M1.02344 0.523438H6.24083V5.74083H1.02344V0.523438Z" fill="#F35325" />
        <path d="M6.75781 0.523438H11.9752V5.74083H6.75781V0.523438Z" fill="#81BC06" />
        <path d="M1.02344 6.26172H6.24083V11.4791H1.02344V6.26172Z" fill="#05A6F0" />
        <path d="M6.75781 6.26172H11.9752V11.4791H6.75781V6.26172Z" fill="#FFBA08" />
      </g>
      <defs>
        <clipPath id="clip0_21952_27963">
          <rect width="12" height="12" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicroSoftIcon;
