import React from 'react';
import useIntl from '/imports/core/api/useIntl';

export const useTranslationData = () => {
  const { t } = useIntl();

  const salutation_data = [
    'salutation.greeting_1',
    'salutation.greeting_2',
    'salutation.greeting_3',
    'salutation.greeting_4',
  ];

  const complimentaryclosing_data = [
    'complimentaryclosing.regards_1',
    'complimentaryclosing.regards_2',
    'complimentaryclosing.regards_3',
    'complimentaryclosing.regards_4',
    'complimentaryclosing.regards_5',
    'complimentaryclosing.regards_6',
    'complimentaryclosing.regards_7',
    'complimentaryclosing.regards_8',
    'complimentaryclosing.regards_9',
  ];

  return { salutation_data, complimentaryclosing_data };
};
