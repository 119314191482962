import { DropdownContent, ChevronContainer, DropdownMenu, DropdownButton } from '/imports/core/ui/atoms/Dropdown';
import { useRef, useState, useEffect, useDeferredValue } from 'react';
import { Chevron } from '/imports/generator/ui/assets';
import CheckIcon from '/imports/core/ui/assets/new/CheckIcon';
import useIntl from 'imports/core/api/useIntl';
import PropTypes from 'prop-types';
import { InputWrapper } from '/imports/core/ui/atoms/DropdownCustom';
import { InputStyle } from '/imports/core/ui/atoms/Input';
import { removeAccents } from 'lib/helpers';
import styled from 'styled-components';

const DropdownAutocomplete = (props) => {
  const { defaultValue, onChange, dark, preview, name, placeholder, lang, noSearch = false } = props;
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [selected, setSelected] = useState(defaultValue);
  const [search, setSearch] = useState(null);
  const optionText = useDeferredValue(search);
  const [filterOptions, setFilterOptions] = useState([]);
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const { t, locale } = useIntl();
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (props.selected) {
      const s = props?.options.find((t) => t.value == props.selected);
      if (s) {
        handleSelect(s);
      } else {
        if (props.selected != selected?.title) {
          handleSelect({
            title: props.selected,
            value: props.selected,
            description: '',
          });
        }
      }
    }
  }, [props.selected]);
  const handleSelect = (option) => {
    onChange(option);
    setSelected(option);
    const title = option?.titleSlug && t(option.titleSlug) ? t(option.titleSlug) : option?.title;
    setSearch(title);
    setExpanded(false);
  };
  const handleHover = (value) => {
    setHovered(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      onChange({
        title: optionText,
        value: optionText,
        description: '',
      });
      setSelected({
        title: optionText,
        value: optionText,
        description: '',
      });
    }, 2000);
  }, [optionText]);

  useEffect(() => {
    const changeFilterOptions = () => {
      let valueToCheck = removeAccents(search);
      valueToCheck = valueToCheck?.toLowerCase();

      let res = props?.options.filter((value) => {
        let text = (value.titleSlug && t(value.titleSlug, lang || locale)) || value.title;
        text = removeAccents(text);
        text = text?.toLowerCase();
        return text?.includes(valueToCheck);
      });
      if (search == null || search?.trim() == '') {
        res = props?.options;
      }
      setFilterOptions(res);
    };
    if (noSearch) {
      setFilterOptions(props?.options);
    } else {
      changeFilterOptions();
    }
  }, [search, props.options, t, lang, locale]);

  const handleInputChange = (e) => {
    setExpanded(true);
    setSearch(e.target.value);
  };
  return (
    <DropdownContent dark={dark} huge={preview} ref={dropdownContentRef}>
      <InputWrapper id="input-wrapper" onClick={toggleExpanded} dark={dark} preview={preview} expanded={expanded}>
        <Input placeholder={placeholder} value={search} onChange={handleInputChange} hideIcon readOnly={noSearch} />
        <ChevronContainer preview={preview}>{expanded ? <Chevron.Up /> : <Chevron.Down />}</ChevronContainer>
      </InputWrapper>
      {filterOptions?.length > 0 && (
        <DropdownMenu
          dark={dark}
          expanded={expanded}
          onClick={(e) => e.stopPropagation()}
          ref={dropdownRef}
          huge={preview}
          preview={preview}
          className="dropdown-menu"
          id={`dropdown-menu-${name}`}
        >
          {filterOptions?.map((option, index) => {
            return (
              <DropdownButton
                type="button"
                key={index}
                dark={dark}
                onMouseEnter={() => handleHover(option.value)}
                onMouseLeave={handleHover}
                onClick={() => handleSelect(option)}
                selected={option?.value === selected?.value}
                hovered={hovered === option.value}
                preview={preview}
                id={`value-${option.value}`}
                font={option.value}
              >
                <CheckIcon />
                {option.titleSlug && t(option.titleSlug) ? t(option.titleSlug) : option.title}
              </DropdownButton>
            );
          })}
        </DropdownMenu>
      )}
    </DropdownContent>
  );
};

const Input = styled.input`
  ${InputStyle}
`;

DropdownAutocomplete.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  preview: PropTypes.bool,
  name: PropTypes.string,
  placeholer: PropTypes.string,
  placeholder: PropTypes.string,
  lang: PropTypes.string,
  placeholderSlug: PropTypes.string,
};

export default DropdownAutocomplete;
