import { createContext, useContext, useMemo } from 'react';
import qs from 'qs';
import { createStore, useStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import {
  getExperienceListAutoOnboarding,
  getResumeTitle,
  addExperience,
  deleteExperience,
  updateExperienceFields,
  updateResumeTitle,
  generateBPByTitle,
  finalizePostOnboarding,
  generateParagraphBulletPoint,
  updateExperienceBulk,
  updateResumeDetails,
  getResumeDetails,
} from 'imports/job-review/api/api';
import { getExperienceAlerts, getExperienceFieldsError } from 'imports/job-review/api/helper';
import { getDomainName, isBlogDomain } from 'lib/helpers';
import { Push } from '/components/Link';
import env from '/env';

const createExperienceReviewStore = (initialState, cookieData) =>
  createStore(
    persist(
      (set, get) => ({
        experienceList: initialState?.experienceList || [],
        step: 1,
        finalStep: 3,
        stepWithSuggesionSide: [3],
        recentExperienceBPAlerts: {},
        rencentExperiencesFieldsError: {},
        currentExperienceId: initialState?.currentExperienceId || null,
        loading: initialState?.loading || true,
        error: initialState?.error || null,
        builderUserId: initialState?.builderUserId || null,
        addBlockLoading: initialState?.addBlockLoading || false,
        resumeTitle: initialState?.resumeTitle || {},
        suggestionBPLoading: {},
        BPSuggestionOpen: false,
        experiencesBPRefinements: {},
        saLink: '',
        questionnaireType: '',
        candidateCategory: '',
        withTargetNatureQuestionCategories: ['Xp - High Lvl', 'No Xp - High Lvl'],
        onePageCV: 'one_page',
        currentExperienceErrorId: null,
        resumeDetails: {},
        showCompletionCTAs: true,
        referrer: '',
        locale: 'en',
        setLocale: (locale) => set({ locale }),
        setReferrer: (host) => {
          const originDomain = getDomainName(host);
          const isBlog = isBlogDomain(host);
          const referrer = `https://${originDomain}/${isBlog ? 'builder/' : ''}`;
          set({ referrer });
        },
        hideCompletionCTAs: () => set({ showCompletionCTAs: false }),
        setCurrentExperienceErrorId: (expId) => {
          set({ currentExperienceErrorId: expId });
        },
        fetchResumeDetails: async (builderUserId) => {
          try {
            const res = await getResumeDetails(builderUserId);
            set({ resumeDetails: res?.resumeDetails });
          } catch (e) {
            console.log('Error while  fetching resume details', e);
          }
        },
        getTotalCheckedSuggestions: () => {
          return (get().experienceList || [])
            .filter((exp) => exp?.requiredToAddMoreBP)
            .reduce((total, exp) => {
              const suggestions = exp.bulletPointSuggestion || [];
              const content = exp.experienceContent || [];

              const checkedCount = suggestions.filter((bp) => content.includes(bp)).length;
              return total + checkedCount;
            }, 0);
        },
        updateResumeDetails: (fields = {}) => {
          try {
            const { resumeDetails, builderUserId } = get();
            const updatedResumeDetails = { ...resumeDetails, ...fields };
            set({ resumeDetails: updatedResumeDetails });
            updateResumeDetails(builderUserId, updatedResumeDetails.isMultiplePage);
          } catch (e) {
            console.log('Error while updating resume details', e);
          }
        },
        showTargetNatureQuestion: () => {
          const { candidateCategory, withTargetNatureQuestionCategories } = get();
          return withTargetNatureQuestionCategories.includes(candidateCategory);
        },
        updateDefaultState: () => {
          const { experienceList, candidateCategory, step, finalStep } = get();
          const fStep = 3;
          set({
            finalStep: fStep,
            ...(step > finalStep && { step: fStep }),
            ...(experienceList?.[0]?.candidateCategory &&
              !candidateCategory && {
                candidateCategory: experienceList?.[0]?.candidateCategory,
              }),
          });
        },
        nextStep: () => {
          const { step, experienceList } = get();
          set({
            step: step + 1,
            ...(experienceList.length > 0 && { currentExperienceId: experienceList?.[0]?.expId }),
          });
        },
        resetStep: () => {
          set({ step: 1 });
        },
        prevStep: () => {
          const { step, experienceList } = get();
          if (step === 1) return false;
          set({
            step: step - 1,
            ...(experienceList.length > 0 && { currentExperienceId: experienceList?.[0]?.expId }),
          });
        },
        setExperienceBPRefinement: (expId, refinement = []) => {
          set({ experiencesBPRefinements: { ...get().experiencesBPRefinements, [expId]: refinement } });
        },
        setSuggestionBpLoading: (expId, loadingState) => {
          set({ suggestionBPLoading: { ...(get().suggestionBPLoading || {}), [expId]: loadingState } });
        },
        setCurrentExperienceId: (id) => set({ currentExperienceId: id }),
        isThereError: () => {
          const bpAlertErrorKey = Object.entries(get().recentExperienceBPAlerts).find(([key, BPAlert]) =>
            ['alert 2', 'alert 3'].includes(BPAlert?.alert),
          )?.[0]; // Get the key of the matching BPAlert

          const fieldErrorKey = Object.entries(get().rencentExperiencesFieldsError).find(
            ([key, fields]) => fields?.length > 0,
          )?.[0]; // Get the key of the matching field error
          const errorOrder = [bpAlertErrorKey, fieldErrorKey].sort((a, b) => {
            //sort the error by list order
            const first = get().experienceList.find((t) => t.expId === a);
            const second = get().experienceList.find((t) => t.expId === b);
            return first.order - second.order;
          });
          return {
            error: !!bpAlertErrorKey || !!fieldErrorKey,
            errorKeys: errorOrder,
          };
        },

        fetchRefinements: async (BPAlertsList, recentExperiences) => {
          const { experienceList, builderUserId } = get();
          let refinedExperiences = [];
          let updatedExperienceList = experienceList;
          // Filter experiences with alert 3 (exceeding number of BP alert)
          const experiencesToRefine = Object.entries(BPAlertsList)
            .filter(([_, BPAlert]) => BPAlert?.alert === 'alert 3') // Find alert 3
            .map(([expId, BPAlert]) => {
              const experienceItem = recentExperiences.find((exp) => exp.expId === expId);
              return { expId, BPAlert, experienceItem };
            });
          if (experiencesToRefine.length > 0) {
            // Fetch refinements for all experiences with alert 3
            refinedExperiences = await Promise.all(
              experiencesToRefine.map(({ expId, BPAlert, experienceItem }) =>
                generateParagraphBulletPoint({
                  targetBpNumber: BPAlert.target,
                  experienceContent: experienceItem?.experienceContent,
                }).then((result) => ({
                  expId,
                  updatedExperience: { experienceContent: result?.suggestionParagraph },
                })),
              ),
            );

            updatedExperienceList = experienceList.map((exp) => {
              const refinedIndex = refinedExperiences.findIndex((refinedExp) => refinedExp?.expId === exp?.expId);
              if (refinedIndex !== -1) {
                return {
                  ...exp,
                  experienceContent: refinedExperiences?.[refinedIndex]?.updatedExperience?.experienceContent,
                };
              }
              return exp;
            });
            try {
              // Bulk update the refined experienceContent
              updateExperienceBulk({
                builderUserId,
                experiences: refinedExperiences,
              });
            } catch (error) {
              console.error('Failed to update experiences:', error);
            }
          }

          return { refinedExperiences, updatedExperienceList };
        },

        fetchRecentExperiencesErrors: async (recentExperiences) => {
          const { showTargetNatureQuestion, fetchRefinements } = get();
          const { BPAlertsList, experiencesFieldsError } = recentExperiences?.reduce(
            (acc, experience, index) => {
              const alerts = getExperienceAlerts(experience, index, recentExperiences) || null;
              const missingFields = getExperienceFieldsError(experience, get()?.step, showTargetNatureQuestion());

              acc.BPAlertsList[experience.expId] = alerts;
              acc.experiencesFieldsError[experience.expId] = missingFields;

              return acc;
            },
            { BPAlertsList: {}, experiencesFieldsError: {} },
          ) || { BPAlertsList: {}, experiencesFieldsError: {} };
          set({
            recentExperienceBPAlerts: BPAlertsList,
            rencentExperiencesFieldsError: experiencesFieldsError,
          });
        },

        focusInput: () => {},

        setFocusInput: (focusFunc) => set({ focusInput: focusFunc }),

        selectExperience: (experienceId) => {
          const shouldClose = get().currentExperienceId === experienceId;
          set({
            currentExperienceId: shouldClose ? null : experienceId,
            ...(shouldClose && { BPSuggestionOpen: false }),
          });
        },

        setBPSuggestionOpen: (open) => set({ BPSuggestionOpen: open }),

        setExperienceList: (experiences) =>
          set({
            experienceList: experiences?.sort((a, b) => {
              return a.order - b.order;
            }),
          }),

        setBuilderUserId: (builderUserId) => set({ builderUserId }),

        setLoading: (loading) => set({ loading }),

        finalizePostOnboarding: async (callBack) => {
          try {
            const uniqueKey = cookieData[`Indicative_${env.INDICATIVE_KEY}`];
            const eventId = uniqueKey && JSON.parse(uniqueKey);
            const indicative_unique_id = cookieData?.indicative_unique_id;
            const indicative_unique_id_two = eventId && eventId?.defaultUniqueID ? eventId?.defaultUniqueID : null;
            const final_unique_id = indicative_unique_id || indicative_unique_id_two || '';
            const res = await finalizePostOnboarding(get().builderUserId, get().experienceList.length === 0);
            if (res?.message === 'Job review process is already completed!') {
              get().updateError({ message: 'Job review process is already completed!' });
            }
            if (res?.saLink) {
              set({ saLink: res?.saLink, questionnaireType: res?.questionnaireType });
              const saLinkWithIndicativeId = `${res?.saLink}${
                res?.saLink.includes('?') ? '&' : '?'
              }indicative_id=${final_unique_id}&referrer=${get().referrer}${
                res?.questionnaireType === 'extraQuiz' ? '&fromAutoOnboardingExtraQuiz=true' : ''
              }`;
              window.location.href = saLinkWithIndicativeId;
              get().updateError({ message: 'Job review process is already completed!' });
            }
          } catch (error) {
            callBack();
            get().updateError(error);
            throw new Error(error);
          }
        },
        fetchExperienceList: async (builderUserId) => {
          if (!builderUserId) throw new Error('Error fetching experience list: builderUserId is required');
          set({ error: null });
          try {
            const res = await getExperienceListAutoOnboarding(builderUserId);
            set({
              experienceList: res,
              loading: false,
              showCompletionCTAs: true,
              ...(res?.[0]?.candidateCategory && { candidateCategory: res[0].candidateCategory }),
            });
          } catch (error) {
            get().updateError(error);
            throw new Error(error);
          }
        },

        updateError: (error, locale = 'fr') => {
          let message;
          try {
            message = JSON.parse(error.message);
            message = message.message;
          } catch (e) {
            message = error.message;
          }
          if (message === 'Job review process is already completed!') {
            const uniqueKey = cookieData[`Indicative_${env.INDICATIVE_KEY}`];
            const eventId = uniqueKey && JSON.parse(uniqueKey);
            const indicative_unique_id = cookieData?.indicative_unique_id;
            const indicative_unique_id_two = eventId && eventId.defaultUniqueID ? eventId.defaultUniqueID : null;
            const final_unique_id = indicative_unique_id || indicative_unique_id_two || '';
            const saLink = get().saLink;
            window.location.href = saLink
              ? `${saLink}${saLink.includes('?') ? '&' : '?'}indicative_id=${final_unique_id}&referrer=${get().referrer}`
              : `https://successagency.io/${locale}/start?builderId=${get().builderUserId}&indicative_id=${final_unique_id}&referrer=${get().referrer}`;
            set({ error: { message } });
          } else if (message === 'Redirect to congratulation page') {
            const queryString = {
              userId: get().builderUserId,
              tab: 2,
            };
            Push(`/job-review?${qs.stringify(queryString)}`, get().locale, `/job-review?${qs.stringify(queryString)}`);
          }
        },
        fetchResumeTitle: async (builderUserId, locale) => {
          if (!builderUserId) throw new Error('Error fetching resume title : builderUserId is required');
          try {
            const res = await getResumeTitle(builderUserId);
            set({ resumeTitle: res });
          } catch (error) {
            get().updateError(error, locale);
            console.log(error);
            throw new Error(error);
          }
        },

        fetchNewExperienceBP: async (experienceId, experienceTitle) => {
          try {
            set({ suggestionBPLoading: true, BPSuggestionOpen: true });
            const res = await generateBPByTitle(get().builderUserId, experienceId, experienceTitle);
            set({ experienceList: res, suggestionBPLoading: false });
          } catch (e) {
            set({ suggestionBPLoading: false });
            console.log('Error generating BP', e);
          }
        },

        updateResumeTitle: (title) => {
          try {
            const resumeTitleObject = {
              ...get().resumeTitle,
              title,
            };
            set({ resumeTitle: resumeTitleObject });
            updateResumeTitle(get().builderUserId, resumeTitleObject);
          } catch (e) {
            console.log('Error while updating resume title', { title }, e);
          }
        },

        addNewExperience: async () => {
          set({ addBlockLoading: true });
          try {
            const { candidateCategory } = get();
            const isDefaultSelectedExperience = (get().experienceList || []).length < 4;
            const order = get().experienceList.length + 1;
            const additionalFields = { order, candidateCategory, isSelectedExperience: isDefaultSelectedExperience };
            const response = await addExperience(get().builderUserId, additionalFields);
            if (response) {
              set({ experienceList: response, currentExperienceId: response[response.length - 1]?.expId });
            }
          } catch (e) {
            console.log(e);
          } finally {
            set({ addBlockLoading: false });
          }
        },

        updateExperience: (experienceId, updatedData, updateLocale = false) => {
          try {
            updateExperienceFields(get().builderUserId, experienceId, updatedData);
          } catch (e) {
            console.log('Error while updating experience fields', { updatedData }, e);
          }
          if (updateLocale) {
            const updatedList = get().experienceList.map((exp) =>
              exp.expId === experienceId ? { ...exp, ...updatedData } : exp,
            );
            set({ experienceList: updatedList });
          }
        },

        removeExperience: (experienceId) => {
          const filteredList = get().experienceList.filter((exp) => exp.expId !== experienceId);
          const shouldClose = get().currentExperienceId === experienceId;
          set({
            experienceList: filteredList,
            ...(shouldClose && { currentExperienceId: null, BPSuggestionOpen: false }),
          });
          deleteExperience(get().builderUserId, experienceId);
        },

        selectSuggestion: (experienceId, suggestion, isRemoving) => {
          let updatedFields = null;
          const updatedList = get().experienceList.map((exp) => {
            let updatedExperienceContent;
            if (exp.expId === experienceId) {
              if (isRemoving) {
                updatedExperienceContent = exp.experienceContent
                  ? exp.experienceContent.filter((item) => item !== suggestion)
                  : [];
              } else {
                updatedExperienceContent = exp.experienceContent
                  ? [...exp.experienceContent, suggestion]
                  : [suggestion];
              }
              updatedFields = { experienceContent: updatedExperienceContent };
              return { ...exp, experienceContent: updatedExperienceContent };
            }
            return exp;
          });

          set({ experienceList: updatedList });
          const focus = get().focusInput(suggestion, true);
          if (focus && !isRemoving) focus();
          if (updatedFields) {
            get().updateExperience(experienceId, updatedFields, false);
          }
        },
      }),
      {
        name: 'storage:experience',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  );
const ExperienceReviewContext = createContext(null);
export const ExperienceReviewProvider = ({ children, initialState, cookieData }) => {
  const store = useMemo(() => createExperienceReviewStore(initialState, cookieData), [initialState]);
  return <ExperienceReviewContext.Provider value={store}>{children}</ExperienceReviewContext.Provider>;
};

const useExperienceReviewStore = () => {
  const store = useContext(ExperienceReviewContext);
  if (!store) {
    const error = new Error('useExperienceReviewStore must be used within a ExperienceReviewProvider');
    console.error(error.stack); // Loging the stack trace for debugging
    throw error;
  }
  return useStore(store);
};

export default useExperienceReviewStore;
