import React, { PureComponent } from 'react';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';

import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import { apolloClient as client } from '/lib/initApollo';
import Radio from '/imports/core/ui/atoms/Radio';
import { TEMPLATES, COVER_LETTER_TEMPLATES } from '/imports/generator/api/constants';
import { UPDATE_RESUME_DETAIL, UPDATE_COVER_LETTER_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import { withIntl } from '/imports/core/api/useIntl';
import { withAccount } from 'imports/core/api/accounts/accountContext';
import { withTracking } from 'imports/core/hooks/useTracking';

@withIntl
@compose(
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
  graphql(UPDATE_COVER_LETTER_DETAIL, { name: 'updateCoverLetterDetail' }),
)
@withTracking
@withAccount
class ColorSelect extends PureComponent {
  static propTypes = {
    template: PropTypes.string,
    updateResumeDetail: PropTypes.func,
    updateCoverLetterDetail: PropTypes.func,
    id: PropTypes.string,
    withBorder: PropTypes.bool,
    isCoverLetter: PropTypes.bool,
    ismargin: PropTypes.bool,
    trackEvent: PropTypes.func,
    componentId: PropTypes.string,
  };

  accentsHidden = () => {
    const { template, isCoverLetter } = this.props;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateData = templates.find((t) => t.id === template);

    return templateData.accentsHidden;
  };

  onChange = (e) => {
    if (this.accentsHidden()) return;

    const { id, updateCoverLetterDetail, updateResumeDetail, isCoverLetter, updateImmue } = this.props;
    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
    this.props.trackEvent('template_color_changed', {
      color: e.target.value,
    });
    client.writeData({
      data: {
        savingStaus: 'SAVING',
      },
    });
    const options = {
      variables: {
        docId: id,
        path: 'settings.color',
        value: e.target.value,
      },
      context: {},
    };

    if (isCoverLetter) options.context.client = 'coverLetter';
    blockDetailImmutableUpdate(updateImmue)(id, 'settings.color')(e.target.value);
    mutation(options);
  };

  render() {
    const { selected, withBorder, template, isCoverLetter, t, ismargin, componentId } = this.props;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const { colors } = templates.find((temp) => temp.id === template) || {};

    const accentWarning = t('accent_color_warning');
    return (
      <RadioCont isCoverLetter={isCoverLetter} ismargin={ismargin} id={componentId} role="radiogroup">
        {colors &&
          colors.map((color) => (
            <ColorRadio
              key={color}
              data-testid="color-radio"
              name="color"
              color={color}
              value={color}
              onChange={this.onChange}
              iconColor={'white'}
              checked={this.accentsHidden() ? color === 'black' : color === selected}
              lockedText={this.accentsHidden() && color !== 'black' ? accentWarning : ''}
              withBorder={withBorder}
              isCoverLetter={isCoverLetter}
            />
          ))}
      </RadioCont>
    );
  }
}

ColorSelect.displayName = 'ColorSelect';

const RadioCont = styled.div`
  margin-top: 3px;

  ${({ ismargin }) =>
    ismargin &&
    css`
      margin-top: 13px;

      @media (max-width: 449px) {
        margin-left: 20px;
        margin-top: 8px;
      }
    `}
`;

const ColorRadio = styled(Radio)`
  width: ${({ isCoverLetter }) => (isCoverLetter ? '46px' : '32px')};
  height: ${({ isCoverLetter }) => (isCoverLetter ? '46px' : '32px')};
  background: ${(p) => p.theme.colors[p.color]};
  border: 0;
  ${(p) =>
    p.withBorder &&
    p.color === 'black' &&
    css`
      border: 1px solid ${(p) => p.theme.colors.gray.regular};
    `}
  &:not(:last-of-type) {
    margin-right: ${({ isCoverLetter }) => (isCoverLetter ? '16px' : '8px')};
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      &:not(:last-of-type) {
        margin-right: 0;
        margin-left: ${({ isCoverLetter }) => (isCoverLetter ? '16px' : '8px')};
      }
      &:last-child {
        margin-right: 0;
        margin-left: ${({ isCoverLetter }) => (isCoverLetter ? '16px' : '8px')};
      }
    `}
`;

export default ColorSelect;
