import styled from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';

export const MainTitleFile = (props) => {
  const { title } = props;
  return <StyledTitle>{title}</StyledTitle>;
};

export const SubTitleFile = (props) => {
  const { title } = props;
  return <SubTitle>{title}</SubTitle>;
};

export const TitleTopGroup = (props) => {
  const { title, subtitle } = props;
  return (
    <TopSection direction="column" $fullWidth alignItems="center">
      <MainTitleFile title={title} />
      <SubTitleFile title={subtitle} />
    </TopSection>
  );
};

const TopSection = styled(Flex)`
  gap: 8px;
`;

const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteBlack};
  font-size: 32px;
  color: rgb(6, 14, 43);
  text-align: center;
  width: 100%;
`;

const SubTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 18px;
  color: rgb(6, 14, 43);
  text-align: center;
  width: 100%;
`;
