import Footer from '/imports/extensionOnboarding/components/Footer';
import HeaderV1 from '/imports/extensionOnboarding/components/HeaderV1';
import OnboardingList from './OnboardingList';
import OnBoardingProgressV1 from './OnBoardingProgressV1';
import OnboardingSliderV1 from './OnboardingSliderV1';

import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import ToogleExpPopUpDesign from 'imports/resume-options/ui/component/ToogleExpPopUpDesign';
import { withRouter } from 'next/router';
import { setCookie } from 'nookies';
import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { withWindowSize } from 'imports/core/api/useWindowSize';

import SkipIcon from 'imports/extensionOnboarding/assets/SkipIcon';
import { Push } from '/components/Link';
import env from '/env';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import Box from '/imports/core/ui/atoms/Box';
import Flex from '/imports/core/ui/atoms/Flex';
import Page from '/imports/core/ui/atoms/Page';
import { onboardingContent } from '/imports/onboarding/api/constants';
import { OnboardingSubTitle, OnboardingTitle } from '/imports/onboarding/ui/atoms';
import OnboardingFooter from '/imports/onboarding/ui/components/OnboardingFooter';
import OnboardingHeader from '/imports/onboarding/ui/components/OnboardingHeader';
import OnboardingProgress from '/imports/onboarding/ui/components/OnboardingProgress';
import OnboardingSlider from '/imports/onboarding/ui/components/OnboardingSlider';
import {
  ACTIVE_BRUNEI_EXP_DESKTOP_LOCALE,
  ACTIVE_BRUNEI_EXP_MOBILE_LOCALE,
  CALI_LIMA_EXP_DESKTOP_LOST_LOCALE,
  CALI_LIMA_EXP_DESKTOP_WON_LOCALE,
  CALI_LIMA_EXP_MOBILE_LOST_LOCALE,
  CALI_LIMA_EXP_MOBILE_WON_LOCALE,
  cookieParser,
  createAccount,
  expToogleLocaleToTest,
  getDeviceInformation,
  getPostHogDistinctId,
  isPopUpV2Active,
  removeExperiment,
  savedDataOnIndex,
} from '/lib/helpers';
import posthog from 'posthog-js';

const isDev = env.NODE_ENV === 'development';

@withTracking
@withResponsiveContext
@withWindowSize
@withIntl
@withRouter
class OnboardingStart extends PureComponent {
  static propTypes = {
    createResume: PropTypes.func,
    router: PropTypes.object,
    variant: PropTypes.string,
    locale: PropTypes.string,
  };

  state = {
    loaded: false,
    loading: false,
    skipIntroLoader: false,
    showTooglePopup: false,
    selected: {
      template: '',
      name: '',
      isSkip: false,
    },
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      skipIntroLoader: nextProps.userIdentified,
    };
  }

  componentDidMount() {
    const { isMobile, locale, width } = this.props;
    removeExperiment('templates_order_experiment_2');
    removeExperiment('template_thumbnails_experiment');
    removeExperiment('above_cta_experiment');
    removeExperiment('exp_onboarding_skip_button');
    removeExperiment('exp_budapest_cali_lima');
    if (isMobile && !ACTIVE_BRUNEI_EXP_MOBILE_LOCALE.includes(locale)) {
      removeExperiment('exp_new_template');
    }
    if (!isMobile && !ACTIVE_BRUNEI_EXP_DESKTOP_LOCALE.includes(locale)) {
      removeExperiment('exp_new_template');
    }
    if (
      (isMobile &&
        (CALI_LIMA_EXP_MOBILE_WON_LOCALE.includes(locale) || CALI_LIMA_EXP_MOBILE_LOST_LOCALE.includes(locale))) ||
      (!isMobile &&
        (CALI_LIMA_EXP_DESKTOP_WON_LOCALE.includes(locale) || CALI_LIMA_EXP_DESKTOP_LOST_LOCALE.includes(locale)))
    ) {
      removeExperiment('exp_cali_lima');
    }
    let obj = {};
    if (marvelEmitter.getActiveVariant('exp_new_template')) {
      obj.exp_new_template = marvelEmitter.getActiveVariant('exp_new_template');
    }

    if (marvelEmitter.getActiveVariant('exp_cali_lima')) {
      obj.exp_cali_lima = marvelEmitter.getActiveVariant('exp_cali_lima');
    }

    if (marvelEmitter.getActiveVariant('exp_ats_preview_v2')) {
      obj.exp_ats_preview_v2 = marvelEmitter.getActiveVariant('exp_ats_preview_v2');
    }

    if (marvelEmitter.getActiveVariant('exp_select_cv_newdesignV2'))
      obj.exp_select_cv_newdesignV2 = marvelEmitter.getActiveVariant('exp_select_cv_newdesignV2');
    this.props.trackEvent('template_view', obj);
    try {
      document.getElementsByTagName('body')[0].classList.remove('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
    const isToogleExpPopUpDesignV2 = isPopUpV2Active(this.props.locale) && this.props.locale != 'en';
    if (isToogleExpPopUpDesignV2) {
      this.setState({
        showTooglePopup: true,
      });
    }
  }

  redirectResumeOption = async (template, name, reloadNext = false, isSkip = false) => {
    //used to make the app reload on first render (new account) to update ApolloClient
    if (reloadNext) {
      localStorage.setItem('reload_next', true);
    }
    const { locale } = this.props;
    const templateName = template || name;
    return Push(`/resume-options?template=${templateName}`, locale);
  };

  identifyUser = (userInfo, register) => {
    const { trackUserIdentity, trackAlias } = this.props;
    trackAlias(userInfo.id);
    trackUserIdentity(userInfo, false, { createdAt: userInfo.createdAt }, register);
  };

  hideTooglePopup = () => {
    this.setState({
      showTooglePopup: false,
    });
  };

  selectTemplate = async (template, name, isSkip = false) => {
    const { isLanding, host, trackEvent, isExp = false, pageProps, setUserData } = this.props;

    trackEvent('template_selected', {
      template: isSkip ? 'Skipped' : template || name,
    });
    if (this.state.loading == true) return true;
    this.setState({ loading: true });
    const cookies = cookieParser();
    if ((!isLanding || pageProps?.token || cookies.token) && !isExp) {
      return this.redirectResumeOption(template, name, false, isSkip);
    }

    if ((pageProps?.token || cookies.token) && isExp) {
      return this.props.handleExpCallback({ template, name });
    }
    const deviceInfo = getDeviceInformation();
    createAccount(deviceInfo.type, host)
      .then((data) => {
        const { token, id } = data;
        const { FS } = window;
        FS?.identify(id);
        const { hostname } = window.location;

        setCookie({}, 'token', token, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
          // The dot prefix ensures the cookie works across subdomains too
          domain: isDev ? hostname : `.${hostname}`,
        });
        savedDataOnIndex('domain', hostname);
        this.identifyUser({ ...data }, true);
        if (window && window?.Indicative && id) window?.Indicative.setUniqueID(id, true);
        if (window && window?.Rollbar) {
          window.Rollbar.configure({
            payload: {
              person: {
                id: id.toString(),
              },
            },
          });
        }
        if (setUserData) setUserData(data);
        const distinctId = getPostHogDistinctId();
        if (distinctId) posthog.alias(id, distinctId);
        trackEvent('Account Created');
        if (this.props.isExp) {
          return this.props.handleExpCallback({ template, name, token, isSkip });
        }

        return this.redirectResumeOption(template, name, true);
      })
      .catch((e) => {
        console.error({ e });
        trackEvent('fetch_error: ', e);
      });
  };

  getTitle = () => {
    const { t, locale, host } = this.props;
    switch (host) {
      case 'mysmartcv':
        return locale === 'en' || locale === 'ar'
          ? t('select_template_mysmartcv.title')
          : onboardingContent['mysmartcv']?.[locale]?.title;
      default:
        return t('select_template.title');
    }
  };

  getSubTitle = () => {
    const { t, locale, host } = this.props;
    switch (host) {
      case 'mysmartcv':
        return locale === 'en' || locale === 'ar'
          ? t('onboarding_mysmartcv.info')
          : onboardingContent['mysmartcv']?.[locale]?.subTitle;
      default:
        return t('onboarding.info');
    }
  };
  invertTheme = () => (theme) => {
    return {
      ...theme,
    };
  };

  handleSkip = (e) => {
    e.stopPropagation();
    const { trackEvent } = this.props;
    trackEvent('skip_cta_trigger');
    this.selectTemplate('budapest', 'budapest', true);
  };

  isPopupV2ShouldBeactive = () => {
    return (
      this.state.showTooglePopup &&
      this.props.router.pathname == '/onboarding' &&
      (expToogleLocaleToTest.includes(this.props.locale) || isPopUpV2Active(this.props.locale))
    );
  };

  renderControlOnBoarding = () => {
    const { t, isMobile } = this.props;
    return (
      <>
        <OnboardingHeader />
        <Main>
          <StyledPage>
            <OnboardingTitle>{this.getTitle()}</OnboardingTitle>
            <Box alignX="center">
              <OnboardingProgress />
            </Box>
            <SkipWrapper fullWidth alignItems="center" justifyContent="center">
              <OnboardingSubTitle>{this.getSubTitle()}</OnboardingSubTitle>
              {!isMobile && (
                <SkipCta
                  onClick={this.handleSkip}
                  disabled={this.state.loading}
                  aria-label={t('skip_title')}
                  role="link"
                >
                  {t('skip_title')}
                </SkipCta>
              )}
            </SkipWrapper>
            <OnboardingSlider onSelect={this.selectTemplate} />
          </StyledPage>
          <OnboardingFooter />
        </Main>
      </>
    );
  };

  renderVariantA = () => {
    return (
      <>
        <HeaderV1 />
        <MainV1>
          <StyledPageV1>
            <OnBoardingProgressV1 />
            <BodyVariantA alignItems="center" direction="column" justifyContent="center">
              <WrapperTitleA alignItems="flex-start" direction="column">
                <OnboardingTitleV1 style={{ textAlign: 'left' }} $newOnboarding>
                  {this.getTitle()}
                </OnboardingTitleV1>
                <OnboardingSubTitleV1>{this.getSubTitle()}</OnboardingSubTitleV1>
              </WrapperTitleA>
              <OnboardingList onSelect={this.selectTemplate} />
            </BodyVariantA>
          </StyledPageV1>
          <Footer />
        </MainV1>
      </>
    );
  };

  renderVariantB = () => {
    const { t, isMobile, router } = this.props;
    const { query } = router;
    return (
      <Fragment>
        <HeaderV1 />
        <MainV1>
          <StyledPageV1>
            <OnBoardingProgressV1 />
            <WrapperTitle alignItems="center" direction="column">
              <OnboardingTitleV1>{this.getTitle()}</OnboardingTitleV1>
              <GapFlex alignItems="center">
                <OnboardingSubTitleV1>{this.getSubTitle()}</OnboardingSubTitleV1>
                {query.from !== 'extension' && !isMobile && (
                  <TitleHeaderWrapper alignItems="center" justifyContent="center">
                    <SkipButton
                      onClick={this.handleSkip}
                      disabled={this.state.loading}
                      aria-label={t('skip_title')}
                      role="link"
                    >
                      {t('skip_title')}
                    </SkipButton>
                    <Flex alignItems="center">
                      <SkipIcon />
                    </Flex>
                  </TitleHeaderWrapper>
                )}
              </GapFlex>
            </WrapperTitle>
            <OnboardingSliderV1 onSelect={this.selectTemplate} />
          </StyledPageV1>
          <Footer bottom />
        </MainV1>
      </Fragment>
    );
  };

  renderOnboarding = () => {
    const variant = marvelEmitter.getActiveVariant('exp_select_cv_newdesignV2');
    const {
      router: { query },
      isMobile,
    } = this.props;
    if (isMobile) return this.renderControlOnBoarding();
    switch (variant) {
      case 'control':
        return this.renderControlOnBoarding();
      case 'variant_A':
        return this.renderVariantA();
      case 'variant_B':
        return this.renderVariantB();
    }
    return this.renderControlOnBoarding();
  };

  render() {
    if (this.isPopupV2ShouldBeactive()) return <ToogleExpPopUpDesign hidePopup={this.hideTooglePopup} />;
    return (
      <ThemeProvider theme={this.invertTheme()}>
        <>{this.renderOnboarding()}</>
      </ThemeProvider>
    );
  }
}

const GapFlex = styled(Flex)`
  gap: 24px;
  position: relative;
`;

const SkipButton = styled.span`
  color: #4f5e75;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  font-family: Gilroy SemiBold;
`;

const TitleHeaderWrapper = styled(Flex)`
  padding: 8px 16px 7px;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  border: 1px solid #4f5e75;
  background: #f3f6fa;
  cursor: pointer;
  position: absolute;
  right: -45%;
  top: -9px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: -60%;
    `}
  @media (max-width: 768px) {
    width: 60%;
  }
`;
const WrapperSlider = styled.div`
  position: relative;
  top: 65px;
`;

const BodyVariantA = styled(Flex)`
  width: 980px;
  margin: auto;
  gap: 48px;
`;

const OnboardingTitleV1 = styled(OnboardingTitle)`
  color: #1d2c43;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.25px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
`;

const OnboardingSubTitleV1 = styled(OnboardingSubTitle)`
  color: #4f5e75;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 140% */
  margin-top: 0 !important;
`;

const WrapperTitleA = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const WrapperTitle = styled(Flex)`
  gap: 24px;
  align-self: stretch;
  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const SkipCta = styled.button`
  isplay: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid var(--primary-default-b400);
  background: var(--light-values-white);
  color: var(--primary-default-b400);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 14px;
  line-height: 20px;
  &:hover {
    background-color: #f2f2f4;
  }
`;

const SkipWrapper = styled(Flex)`
  margin-top: 24px;
  gap: 24px;
  position: relative;
  flex-direction: column;
  @media (min-width: 550px) {
    flex-direction: row;
  }
`;

const Main = styled.main`
  display: flex;
  flex-flow: column;
  padding: 76px 16px 0;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  ${
    '' /* The Experiment component
  becomes a span once rendered */
  }
  & > span {
    display: flex;
    flex-flow: column nowrap;
    min-height: calc(100vh - 76px);
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

const MainV1 = styled(Main)`
  min-height: calc(100vh - 70px);
  display: flex;
  gap: 24px;
  padding: 24px 16px 0;
`;

const StyledPage = styled(Page)`
  height: 100%;
  max-height: calc(100% - 178px);

  &:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    background-color: #f9fafc;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background-color: ${({ theme }) => theme.colors.gray.light2};
  }
`;

const StyledPageV1 = styled(StyledPage)`
  margin-bottom: 80px;
`;

OnboardingStart.displayName = 'OnboardingStart';

export default OnboardingStart;
