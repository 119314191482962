import styled, { css } from 'styled-components';

import Text from '/imports/core/ui/atoms/Text';

const WizardSubHeader = styled(Text)`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;
  color: #7171a6;
  margin-top: 0;
  margin-bottom: 36px;
  span {
    color: #1688fe !important;
    cursor: pointer;
  }
  ${({ theme }) =>
    theme.designV2 &&
    css`
      width: 100%;
      flex-grow: 0;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
    `}

  ${(p) =>
    p.intro &&
    css`
      font-family: ${(p) => p.theme.font.accent};
      font-size: 18px;
      color: #7e8592;
    `}
  ${(p) =>
    p.center &&
    css`
      text-align: center;
      font-weight: 600;
    `}

  span {
    color: ${(p) => p.theme.colors.primary};
  }
  ${({ theme, marginLess }) =>
    theme.max('xs')`
    font-size: 14px;
    padding: 0 5px;
    ${
      marginLess &&
      css`
        margin-bottom: 14px;
      `
    }
  `}

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

WizardSubHeader.displayName = 'WizardSubHeader';

export default WizardSubHeader;
