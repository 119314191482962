import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { withIntl } from 'imports/core/api/useIntl';
import _ from 'lodash';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { Fragment, PureComponent, useEffect, useRef } from 'react';
import { useMutation } from 'react-apollo';
import styled, { ThemeProvider, css } from 'styled-components';
import { GENERAL_FONTS_IMPORT } from 'styles/pdf-fonts/constants';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { useResponsive, withResponsiveContext } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';
import {
  blockImmutableUpdate,
  blockReorderOptimistic,
  updateBlocksAfterReorder,
} from '/imports/generator/api/apollo/client/helpers';
import { REORDER_BLOCK, UPDATE_BLOCK_FIELD } from '/imports/generator/api/apollo/client/mutations';
import { RTLLanguages } from '/imports/generator/api/constants';
import {
  determineMovementDirection,
  getBruneiHeaderTitleLinesFromResumes,
  getBruneiHeaderNameLinesFromResumes,
} from '/imports/generator/api/helpers';
import { withBlockResposition } from '/imports/generator/context/blockresposition.context';
import {
  A4_HEIGHT,
  A4_WIDTH,
  SA_RESUME_SETTINGS,
  TEMPLATES_FONTS,
  TEMPLATES_FONTS_ARABIK_EXP,
  convertNumber,
  convertPt,
  convertPx,
  getPaddingMargin,
} from '/imports/pdf/core/api/constants';
import { getCorrectTemplateColor } from '/imports/pdf/core/api/helpers';
import templates from '/imports/pdf/core/api/templates';
import { getDefaultLanguage, packItems } from '/lib/helpers';
import { getFontFamilies, getTemplateFonts } from '/styles/pdf-fonts/helpers';

const DragAndDropTemplateEnabledMap = {
  budapest: true,
  'budapest-v2': true,
  'budapest-v3': true,
  riga: true,
  chicago: true,
  perth: true,
  shanghai: true,
  prague: true,
  sydney: true,
  montecarlo: true,
  rotterdam: true,
  vladivostok: true,
  sf: true,
  kiev: true,
  atsresume: true,
  brunei: true,
};
function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
  }
  return true;
}

const DragDropContainer = ({ template: Template, ...rest }) => {
  const [reorder] = useMutation(REORDER_BLOCK);
  const { trackEvent } = useTracking();
  const [updateBlockField] = useMutation(UPDATE_BLOCK_FIELD);

  const { source: resume, isSwapable } = rest;
  const { isMobile } = useResponsive();
  const skillsBlockItemRef = useRef([]);
  const timeoutRef = useRef(null);
  const templateRef = useRef(null);
  const repositionSkillsTags = async (returnValue = false, moveDirection = null) => {
    if (isMobile) {
      return false;
    }

    const skillsBlock = resume.blocks.find((block) => block.type === 'SKILLS');
    const oldSkillItems = skillsBlock?.items;
    let elementClass = '.skills-item-tag';
    if (document.querySelectorAll('.skills-item-tag')?.length > 0 && skillsBlock) {
      let container = document.querySelector('.skills-item-tag').parentNode;
      if (container?.getAttribute('class').includes('click-to-edit-wrapper')) {
        container = container.parentNode;
        elementClass = '.click-to-edit-wrapper:has(div.skills-item-tag)';
      }

      const items = Array.from(document.querySelectorAll(elementClass))?.map((ele) => {
        const style = window.getComputedStyle(ele);
        const regex = /(\d+(\.\d+)?)px/g;
        const match = regex.exec(style.marginRight);
        let rightMargin = 0;
        if (match[1]) {
          rightMargin = parseInt(match[1]);
        }
        let id = ele.dataset.id;
        if (!id) {
          id = ele.querySelector('.skills-item-tag').dataset.id;
        }
        return { element: ele, width: ele.offsetWidth + rightMargin, id };
      });
      const packedRows = packItems(container.offsetWidth, items);
      let newOrdersBlocksItems = skillsBlock.items.map((item) => {
        let newOrderIndex = packedRows.findIndex((r) => r.id === item.id);
        if (newOrderIndex == -1) {
          newOrderIndex = packedRows.length;
        }
        let itemWithoutTypeName = Object.assign({}, item);
        delete itemWithoutTypeName['__typename'];
        return { ...itemWithoutTypeName, order: newOrderIndex };
      });

      const isOrderChanges = arraysEqual(oldSkillItems, newOrdersBlocksItems);
      if (!isOrderChanges && newOrdersBlocksItems && newOrdersBlocksItems.length > 0 && rest.updateImmue) {
        blockImmutableUpdate(rest.updateImmue, skillsBlock.id, 'items', newOrdersBlocksItems);
        if (returnValue == true) {
          return { field: 'items', value: newOrdersBlocksItems };
        }
        newOrdersBlocksItems.sort((a, b) => {
          if (a.order === -1) return 1;
          if (b.order === -1) return -1;
          return a.order - b.order;
        });
        const ids = _.map(newOrdersBlocksItems, 'id');
        skillsBlockItemRef.current = ids;
        await updateBlockField({
          variables: {
            docId: resume.id,
            blockId: skillsBlock.id,
            field: 'items',
            value: newOrdersBlocksItems,
          },
        });
      }
    }
  };

  const reorderSkillsShouldBeEnable = () => {
    if (window && window.localStorage.getItem('disable-tags-repositionning') == 1) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const updateSkillsOrder = () => {
      if (!reorderSkillsShouldBeEnable()) {
        return false;
      }
      const skillsBlockItems = resume?.blocks?.find((b) => b?.type == 'SKILLS')?.items;
      const ids = _.map(skillsBlockItems, 'id');
      if (
        templateRef.current != resume.settings.template ||
        JSON.stringify(ids) != JSON.stringify(skillsBlockItemRef.current)
      ) {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        templateRef.current = resume.settings.template;
        timeoutRef.current = setTimeout(async () => {
          await repositionSkillsTags();
        }, 2000);
      }
    };
    updateSkillsOrder();
  }, [resume]);
  const move = async (blockId, position, direction, customSource = null) => {
    const {
      source: {
        id: resumeId,
        settings: { template },
      },
      source,
      context: { dispatch, state },
    } = rest;
    const source_ = customSource || source;
    const { blocks } = source_;
    const currentBlock = blocks.find((block) => block.id === blockId);

    trackEvent('column_change', {
      block: currentBlock.type,
      direction: direction,
      template,
      variant: 'drag_drop',
    });

    trackEvent('block_position_changed', {
      block: currentBlock.type,
      direction: direction,
      template,
    });
    clearTimeout(timeoutRef.current);
    const variables = { resumeId, blockId, direction: 'DRAG', position };
    if (currentBlock.type == 'SKILLS' && reorderSkillsShouldBeEnable()) {
      const newOrderSkills = await repositionSkillsTags(true, direction);
      if (newOrderSkills?.field && newOrderSkills?.value) {
        variables.field = newOrderSkills?.field;
        variables.value = newOrderSkills?.value;
      }
    }

    await reorder({
      variables,
      optimisticResponse: blockReorderOptimistic(source_, blockId, 'DRAG', position),
      update: updateBlocksAfterReorder(resumeId),
    });
  };

  const handleBlockReorder = async (updatedBlock) => {
    if (!updatedBlock) return;
    const response = await updateBlockField({
      variables: {
        docId: resume.id,
        blockId: updatedBlock?.blockId,
        field: 'atsOrder',
        value: updatedBlock?.order,
      },
    });
    return response.data.updateBlockField;
  };

  const onDragEnd = (data) => {
    const { destination, draggableId, source } = data || {};
    if (!destination?.droppableId || !source?.droppableId) return;
    const direction = determineMovementDirection(source, destination);
    return move(draggableId, [destination?.droppableId === 'left' ? 0 : 1, destination.index], direction);
  };

  return (
    <Fragment>
      <Template
        {...rest}
        onDragEnd={onDragEnd}
        handleBlockReorder={handleBlockReorder}
        isSwapable={isSwapable}
        move={move}
        reorder={reorder}
      />
      <DraggableContainer id="draggable" />
    </Fragment>
  );
};

DragDropContainer.propTypes = { template: PropTypes.string };

@withResponsiveContext
@withAccount
@withIntl
class PDFViewer extends PureComponent {
  static propTypes = {
    source: PropTypes.object,
    width: PropTypes.number,
    withNegativeMargin: PropTypes.bool,
    template: PropTypes.string,
    currentUser: PropTypes.object,
    printHeight: PropTypes.any,
    isCoverLetter: PropTypes.bool,
    host: PropTypes.string,
    context: PropTypes.object,
    isPreview: PropTypes.bool,
    isSwapable: PropTypes.bool,
    isMobile: PropTypes.bool,
    cvFormat: PropTypes.string,
  };

  state = {
    height: null,
  };

  isCoverLetter = () => {
    const { source } = this.props;
    return source.__typename === 'CoverLetter';
  };

  poll = (func) => {
    [0, 500, 1500, 2000].forEach((delay) => {
      this.timeouts.push(
        setTimeout(() => {
          func();
        }, delay),
      );
    });
  };

  componentDidMount() {
    this.timeouts = [];
    const { resumeSource, handleSetBruneiLines } = this.props;
    if (resumeSource) {
      const bruneiTitleLine = getBruneiHeaderTitleLinesFromResumes(resumeSource);
      const bruneiNameLine = getBruneiHeaderNameLinesFromResumes(resumeSource);
      handleSetBruneiLines(bruneiTitleLine, bruneiNameLine);
    }
    const that = this;
    this.poll(this.setHeight);
    document.fonts?.ready.then(function () {
      that.setHeight();
    });
  }

  componentWillUnmount() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
  }

  componentDidUpdate(prevProps) {
    const { template } = prevProps;
    const { template: currentTemplate } = this.props;
    if (template !== currentTemplate) return this.poll(this.setHeight);
    return this.setHeight();
  }

  setHeight = () => {
    const { width } = this.props;
    const previewNode = document.getElementById('preview-template');
    if (previewNode) {
      const { offsetHeight } = previewNode;
      setTimeout(() => {
        this.setState({
          height: this.dragEnable() ? offsetHeight : offsetHeight * (width / A4_WIDTH),
        });
      }, 0);
    }
  };

  setGenderStyle =
    (gender) =>
    (a1) =>
    (...a2) => {
      if (gender === a1) return css(...a2);
    };

  dragEnable = () => {
    const {
      source: {
        settings: { template },
      },
    } = this.props;
    return !this.isCoverLetter() && DragAndDropTemplateEnabledMap[template];
  };

  getPadding = (padding, unit) => {
    const scale = this.dragEnable() ? this.props.width / A4_WIDTH : 1;
    return getPaddingMargin(scale, padding, unit);
  };

  getConvertPx = (value, isFontSize = false, unit, rounded) => {
    const { currentUser, template: ssrTemplate, source } = this.props;
    const { saDetails, settings } = source || {};
    const { agent_client_id } = nookies.get({});
    const isAgentOrAdmin =
      (currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin')) ||
      (agent_client_id !== undefined && agent_client_id !== null);
    let template = ssrTemplate || settings?.template;
    const useSAResumeSettings = template === 'budapest' && settings?.templateVersion == 3;
    const generalFontSize = saDetails?.generalFontSize;
    const fontPropotionScaling =
      SA_RESUME_SETTINGS['generalFontSize']['options'].find(
        (fontOption) => fontOption && fontOption.value === generalFontSize,
      )?.scaling || 0;
    const scale = this.dragEnable() ? this.props.width / A4_WIDTH : 1;
    return convertPx(scale, isFontSize && useSAResumeSettings ? value + fontPropotionScaling : value, unit, rounded);
  };

  getConvertPt = (value) => {
    const scale = this.dragEnable() ? this.props.width / A4_WIDTH : 1;
    return convertPt(scale, value);
  };

  getConvertNumber = (value) => {
    const scale = this.dragEnable() ? this.props.width / A4_WIDTH : 1;
    return convertNumber(scale, value);
  };

  isCurrentGender = (currentGender) => (gender) => {
    return currentGender === gender;
  };

  getFont =
    (currentFont) =>
    (fontStyle = 'regular') => {
      return (
        TEMPLATES_FONTS[currentFont]?.[fontStyle] ||
        TEMPLATES_FONTS_ARABIK_EXP[currentFont]?.[fontStyle] ||
        ((fontStyle === 'semiBold' || fontStyle === 'medium') && TEMPLATES_FONTS_ARABIK_EXP?.[currentFont]?.['bold']) ||
        TEMPLATES_FONTS?.['raleway']?.[fontStyle] ||
        TEMPLATES_FONTS?.['raleway']?.regular
      );
    };

  getMainColumnSize = () => {
    const { saDetails } = this.props.source;
    return saDetails?.mainColumnSize || SA_RESUME_SETTINGS['mainColumnSize']['defaultOption'];
  };

  getNameSize = () => {
    const { saDetails } = this.props.source;
    return saDetails?.nameSize || SA_RESUME_SETTINGS['nameSize']['defaultOption'];
  };

  getTitleSize = () => {
    const { saDetails } = this.props.source;
    return saDetails?.titleSize || SA_RESUME_SETTINGS['titleSize']['defaultOption'];
  };

  getParagraphSpacing = () => {
    const { saDetails } = this.props.source;
    return saDetails?.paragraphSpacing || SA_RESUME_SETTINGS['paragraphSpacing']['defaultOption'];
  };

  getGeneralFontSize = () => {
    const { saDetails } = this.props.source;
    return saDetails?.generalFontSize || SA_RESUME_SETTINGS['generalFontSize']['defaultOption'];
  };

  getSideBarContentLayout = () => {
    const { saDetails } = this.props.source;
    return saDetails?.sideBarContentLayout || SA_RESUME_SETTINGS['sideBarContentLayout']['defaultOption'];
  };

  getMiddleContentLayout = () => {
    const { saDetails } = this.props.source;
    return saDetails?.middleContentLayout || SA_RESUME_SETTINGS['middleContentLayout']['defaultOption'];
  };

  invertTheme =
    (language, currentGender, headingFont, contentFont, arabicHeadingFont, arabicContentFont, useSAResumeSettings) =>
    (theme) => {
      const scale = this.dragEnable() ? this.props.width / A4_WIDTH : 1;

      //Add resume settings to theme, which we access through query when downloading/exporting  , ONLY FOR SABudapest
      const saResumeSettings = [
        'middleContentLayout',
        'mainColumnSize',
        'nameSize',
        'titleSize',
        'paragraphSpacing',
        'generalFontSize',
        'sideBarContentLayout',
      ];
      const fieldsPresentInQuery =
        Object.fromEntries(saResumeSettings.filter((key) => this.props[key]).map((key) => [key, this.props[key]])) ||
        {};

      return {
        ...theme,
        getPaddingMargin: this.getPadding,
        convertPx: this.getConvertPx,
        convertPt: this.getConvertPt,
        convertNumber: this.getConvertNumber,
        scale,
        width: this.props.width,
        isRTL: RTLLanguages.includes(language),
        gender: this.isCurrentGender(currentGender),
        headingFont: this.getFont(headingFont),
        contentFont: this.getFont(contentFont),
        arabicHeadingFont: this.getFont(arabicHeadingFont),
        arabicContentFont: this.getFont(arabicContentFont),
        setGenderStyle: this.setGenderStyle(currentGender),
        isCoverLetter: this.isCoverLetter(),
        ...fieldsPresentInQuery,
        ...(useSAResumeSettings && {
          mainColumnSize: this.getMainColumnSize(),
          nameSize: this.getNameSize(),
          titleSize: this.getTitleSize(),
          paragraphSpacing: this.getParagraphSpacing(),
          generalFontSize: this.getGeneralFontSize(),
          sideBarContentLayout: this.getSideBarContentLayout(),
          middleContentLayout: this.getMiddleContentLayout(),
        }),
      };
    };

  get getGender() {
    const { gender } = this.props.currentUser || {};
    return ['male', 'female'].includes(gender) ? gender : 'male';
  }

  render() {
    const { height } = this.state;
    const {
      template: ssrTemplate,
      width,
      withNegativeMargin,
      printHeight,
      isCoverLetter,
      host,
      context,
      isSwapable,
      isBudapestDesignActive,
      currentUser,
      isMobile,
      locale,
      isDesignWithSocialLink,
      cvFormat,
      withBackgroundBrunei,
      isDesignWithIconSocialLink,
      isBruneiNewDesign,
      bruneiHeaderTitleLines,
      bruneiHeaderNameLines,
    } = this.props;
    const { agent_client_id } = nookies.get({});
    const isAgentOrAdmin =
      (currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin')) ||
      (agent_client_id !== undefined && agent_client_id !== null);

    let { source, isPreview } = this.props;
    const language = source.settings.language || getDefaultLanguage(host);
    const version = source.settings.templateVersion;
    const type = source.__typename || (isCoverLetter ? 'coverletter' : 'resume');

    let template = ssrTemplate || source.settings.template;
    const useSAResumeSettings = template === 'budapest' && source?.settings?.templateVersion == 3;
    if (version > 1 && template == 'budapest') {
      template = `${template}-v${version}`;
    }
    const Template = templates[template][type.toLowerCase()];

    const scale = width / A4_WIDTH;
    const isArabicLanguage = locale === 'ar';

    const { headingFont, contentFont, arabicHeadingFont, arabicContentFont } = getTemplateFonts(
      template,
      isArabicLanguage,
      source.settings.headingFont,
      source.settings.contentFont,
      source.settings.arabicHeadingFont,
      source.settings.arabicContentFont,
    );

    const settings = {
      ...source.settings,
      color: getCorrectTemplateColor(source.settings.color, source.settings.template),
      language,
      headingFont,
      contentFont,
    };

    source = { ...source, settings };

    const props = {
      id: 'preview-template',
      source,
      scale,
      printHeight,
      width,
      setHeight: this.setHeight.bind(this),
      gender: this.getGender,
      context,
      updateImmue: this.props.updateImmue,
      swapRtlColumns: true,
      host,
      isBudapestDesignActive,
      isRenameSectionTitle: !isMobile,
      isDesignWithSocialLink,
      cvFormat,
      isDesignWithIconSocialLink,
      isBruneiNewDesign,
      bruneiHeaderTitleLines,
      bruneiHeaderNameLines,
    };

    const getCurrentFonts = getFontFamilies(
      template,
      !(type.toLowerCase() === 'coverletter') && headingFont,
      !(type.toLowerCase() === 'coverletter') && contentFont,
    );

    return (
      <DocumentWrapper
        scale={scale}
        height={height}
        withNegativeMargin={withNegativeMargin}
        withBackgroundBrunei={withBackgroundBrunei}
        dragEnabled={this.dragEnable()}
        getFontFamilies={getCurrentFonts}
      >
        <ThemeProvider
          theme={this.invertTheme(
            language,
            this.getGender,
            headingFont,
            contentFont,
            arabicHeadingFont,
            arabicContentFont,
            useSAResumeSettings,
          )}
        >
          {isPreview ? (
            <DragDropContainer template={Template} {...props} currentUser={this.props.currentUser} />
          ) : (
            <Template {...props} isDragDisabled isSwapable={isSwapable} currentUser={this.props.currentUser} />
          )}
        </ThemeProvider>
      </DocumentWrapper>
    );
  }
}

const DocumentWrapper = styled.div`
  position: relative;
  width: ${A4_WIDTH}px;
  transform-origin: top left;
  height: ${(p) => (p.height ? `${p.height}px` : 'auto')};
  min-height: ${(p) => A4_HEIGHT * p.scale}px;

  ${(p) =>
    p.withNegativeMargin &&
    css`
      margin-top: -30px;
    `}
  ${(p) =>
    p.withBackgroundBrunei &&
    css`
      margin-top: 0px;
    `}
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  > section {
    position: absolute;
    top: 0;
    left: 0;
  }
  @media print {
    min-height: auto;
  }
  ${(p) => p.getFontFamilies}

  ${GENERAL_FONTS_IMPORT}
`;

const DraggableContainer = styled.div`
  position: absolute;
  cursor: move !important;
`;

export default withBlockResposition(PDFViewer);
