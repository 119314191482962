import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import animateScrollTo from 'animated-scroll-to';
import compose from 'lodash.flowright';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';

import { withAccount } from 'imports/core/api/accounts/accountContext';
import { withResponsiveContext } from 'imports/core/api/responsiveContext';
import { withIntl } from 'imports/core/api/useIntl';
import { useSelectStore } from 'zustand/SelectStore';
import { withTracking } from '/imports/core/hooks/useTracking';
import List from '/imports/core/ui/atoms/List';
import ListItem from '/imports/core/ui/atoms/ListItem';
import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import {
  REORDER_BLOCK,
  UPDATE_BLOCK_POSITION,
  UPDATE_COVER_LETTER_DETAIL,
  UPDATE_RESUME_DETAIL,
} from '/imports/generator/api/apollo/client/mutations';
import { COVER_LETTER_TEMPLATES, TEMPLATES } from '/imports/generator/api/constants';
import {
  extractBlockInfo,
  handleBlockRepositioning,
  moveLeftAtsBlocksToRight,
  moveRightAtsBlocksToLeft,
  replacedBlocks,
  setDefaultValueSABudapest,
} from '/imports/generator/api/helpers';
import { getPreviewBlocksHeight } from '/lib/helpers';

const extractTemplateVersionByID = (template) => {
  const match = template.match(/-v(\d+)$/);
  const version = match ? parseInt(match[1], 10) : 1;
  return isNaN(version) ? 1 : version;
};

@withTracking
@withAccount
@withIntl
@withResponsiveContext
@compose(
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
  graphql(UPDATE_COVER_LETTER_DETAIL, { name: 'updateCoverLetterDetail' }),
  graphql(UPDATE_BLOCK_POSITION, { name: 'updateBlockPosition' }),
  graphql(REORDER_BLOCK, { name: 'reorder' }),
)
class TemplatePicker extends PureComponent {
  static propTypes = {
    updateResumeDetail: PropTypes.func,
    updateCoverLetterDetail: PropTypes.func,
    source: PropTypes.object,
    isCoverLetter: PropTypes.bool,
  };

  handleColorUpdate = (id, value, isCoverLetter) => {
    const { updateResumeDetail, updateCoverLetterDetail } = this.props;
    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateObject = templates.find((tem) => tem.id === value);
    const options = {
      variables: {
        docId: id,
        path: 'settings.color',
        value: templateObject?.defaultColor || 'black',
      },
    };
    if (isCoverLetter) {
      options.context = {
        client: 'coverLetter',
      };
    }
    mutation(options);
  };

  select = async ({
    target: {
      dataset: { value: newValue },
    },
  }) => {
    const templatesWithVersion = ['budapest-v2', 'budapest-v3'];
    const value = templatesWithVersion.includes(newValue) ? 'budapest' : newValue;
    const version = templatesWithVersion.includes(newValue) ? extractTemplateVersionByID(newValue) : 1;
    const {
      source,
      source: {
        id,
        settings: { template },
        blocks,
      },
      updateResumeDetail,
      updateCoverLetterDetail,
      isCoverLetter,
      updateImmue,
      updateBlockPosition,
      trackEvent,
      reorder,
    } = this.props;
    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
    this.props.trackEvent('switch_mobile_template', {
      template: newValue,
    });
    if (!isCoverLetter) {
      const type = useSelectStore.getState();
      type.setSelectedResume(newValue);
      if (template === 'atsresume' && newValue !== 'atsresume') {
        moveLeftAtsBlocksToRight(source, updateImmue, updateBlockPosition);
      }
      if (newValue === 'atsresume') {
        moveRightAtsBlocksToLeft(source, updateImmue, updateBlockPosition);
      }
      if (newValue === 'budapest' && version === 3) {
        setDefaultValueSABudapest(source, updateResumeDetail);
      }
      updateResumeDetail({
        variables: {
          docId: id,
          path: 'settings.templateVersion',
          value: `${(version && parseInt(version)) || 1}`,
        },
      });
      blockDetailImmutableUpdate(updateImmue)(id, 'settings.templateVersion')(version);
    }

    const options = {
      variables: {
        docId: id,
        path: 'settings.template',
        value,
      },
    };

    if (isCoverLetter) {
      options.context = {
        client: 'coverLetter',
      };
    }

    if (value !== source.settings.template) this.handleColorUpdate(id, value, isCoverLetter);
    blockDetailImmutableUpdate(updateImmue)(id, 'settings.template')(value);
    if (!isCoverLetter) {
      mutation({ ...options }).then(async (res) => {
        const resume = res.data.updateResumeDetail;
        const customBlocks =
          resume && resume.blocks && resume.blocks.length > 0
            ? resume.blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b)
            : [];
        const customSkillsBlocks =
          resume && resume.blocks && resume.blocks.length > 0
            ? resume.blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
            : [];
        const heights = getPreviewBlocksHeight(customBlocks, customSkillsBlocks);
        const updatedBlocks = handleBlockRepositioning(resume.blocks, heights, null, true, true);
        const newResume = replacedBlocks(resume, updatedBlocks.blocks);
        const blocks = extractBlockInfo(updatedBlocks.blocks);
        updateImmue(newResume);
        updateBlockPosition({
          variables: {
            resumeId: newResume.id,
            blocks: blocks,
          },
        });
        trackEvent('mobile_preview_block_respositioning');
      });
    }
  };

  componentDidMount() {
    this.scrollTo();
  }

  componentDidUpdate(prevProps) {
    const { source } = this.props;
    const { source: prevSource } = prevProps;

    if (source.settings.template !== prevSource.settings.template) return this.scrollTo();
  }

  scrollTo = () => {
    animateScrollTo(this.selected, {
      offset: -50,
      horizontal: true,
      element: this.cont,
    });
  };

  filterTemplateOptionsByRole = (options) => {
    const { isMobile } = this.props;
    const { agent_client_id } = nookies.get({});
    const isAgentOrAdmin =
      (this.props?.currentUser &&
        (this.props?.currentUser.role === 'agent' || this.props?.currentUser.role === 'admin')) ||
      (agent_client_id !== undefined && agent_client_id !== null);
    const activeBruneiExp = marvelEmitter.getActiveVariant('exp_new_template') === 'with_brunei';
    const activeBruneiOptionExp = marvelEmitter.getActiveVariant('exp_brunei_option') === 'with_option';
    if (agent_client_id !== undefined && agent_client_id !== null) {
      return options;
    } else {
      return options.filter((t) => {
        if (t?.role) {
          return t?.role?.includes(this.props?.currentUser?.role);
        }
        if (t?.displayCondition && typeof t.displayCondition == 'function') {
          return t?.displayCondition(
            this.props.locale,
            activeBruneiExp,
            isMobile,
            activeBruneiOptionExp,
            isAgentOrAdmin,
          );
        }
        return true;
      });
    }
  };

  render() {
    const { source, isCoverLetter, locale } = this.props;
    const templates = isCoverLetter
      ? COVER_LETTER_TEMPLATES
      : TEMPLATES.filter((data) => !['cali', 'lima'].includes(data.id));
    const filteredTemplates = this.filterTemplateOptionsByRole(templates);

    return (
      <PickerCont ref={(r) => (this.cont = r)} horizontal unstyled>
        {filteredTemplates.map((template) => {
          let selected = template.id === source.settings.template;
          if (source.settings.templateVersion > 1 && source.settings.template == 'budapest') {
            selected = template.id == `budapest-v${source.settings.templateVersion}`;
          }
          if (template.hidden) return;
          return (
            <TemplateItem
              key={template.id}
              onClick={this.select}
              data-value={template.id}
              selected={selected}
              ref={(ref) => {
                if (selected) {
                  this.selected = ref;
                }
              }}
            >
              {template.name}
            </TemplateItem>
          );
        })}
      </PickerCont>
    );
  }
}

TemplatePicker.displayName = 'TemplatePicker';

const PickerCont = styled(List)`
  display: inline-block;
  width: 95%;
  padding: 10px 15px;
  overflow-y: scroll;
  flex-wrap: nowrap;
  justify-content: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TemplateItem = styled(ListItem)`
  color: ${(p) => p.theme.colors.gray.regular};
  display: inline;
  font-size: 16px;
  margin-right: 10px;
  padding: 0 10px;
  white-space: nowrap;
  ${(p) =>
    p.selected &&
    css`
      color: white;
    `}
`;

export default TemplatePicker;
