import styled, { css } from 'styled-components';
import Flex from 'imports/core/ui/atoms/Flex';
import AtomLabel from 'imports/core/ui/atoms/Label';
export const InputWrapper = styled.div`
  width: 400px;
  position: relative;
`;
export const LoaderWrapper = styled(Flex)`
  gap: 48px;
`;

export const Subtitle = styled.h4`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 24px;
  width: 100%;
  text-align: center;
  margin: 0px;
`;
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 32px;
  width: 100%;
  text-align: center;
  margin: 0px;
`;

export const PrevButton = styled.button`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  color: #858c98;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
export const Footer = styled(Flex)`
  width: 100%;
  gap: 24px;
`;
export const CrossIconWrapper = styled(Flex)`
  position: absolute;
  top: 16px;
  right: 16px;
  flex-shrink: 0;
  cursor: pointer;
`;

export const Label = styled(AtomLabel)`
  span {
    font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  }
`;

export const BorderedButton = styled.button`
  display: flex;
  align-items: center;
  min-width: 150px;
  justify-content: center;
  padding: 12px 20px;
  border: 1px solid #1688fe;
  gap: 6px;
  background-color: transparent;
  border-radius: 4px;
  color: #1688fe;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  transition:
    background-color 0.3s,
    color 0.3s;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  &:hover {
    background-color: #1688fe;
    color: white;
    svg g {
      stroke: white;
    }
  }
`;

export const CTA = styled.div`
  cursor: pointer;
  text-align: center;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 13px 18px;
  border-radius: 4px;
  border: 1px solid #1688fe;
  background: var(--light-values-white);
  color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  min-width: 126px;
  line-height: 20px;
  &:hover {
    background-color: var(--light-values-dark);
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
      cursor: not-allowed;
    `}
`;
export const TranslateCTA = styled(CTA)`
  background: #1688fe;
  color: white;
  &:hover {
    background: #1688fe;
  }
`;

export const CancelCta = styled(CTA)`
  background: #f6f6f6;
  color: #4c4c55;
  border: none;
  &:hover {
    background: #f6f6f6;
  }
`;
export const Wrapper = styled(Flex)`
  flex-direction: column;
  position: relative;
  height: 100%;
`;
export const Body = styled(Flex)`
  align-self: stretch;
  color: #000;
  padding: 32px;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 24px;
  font-weight: 400;
  line-height: 24px;
  height: 100%;
`;
