import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { isJobTrackEnable } from '/lib/helpers';
import Theme from '/imports/job-tracking/api/theme';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('/imports/dashboard/ui/components/Header'), { ssr: false });

const HeaderLayout = ({ requestLoad, children }) => {
  const invertTheme = (theme) => {
    if (isJobTrackEnable) {
      return {
        ...theme,
        isJobTrack: true,
        jobTrack: Theme,
      };
    } else {
      return (theme) => theme;
    }
  };

  return (
    <ThemeProvider theme={invertTheme}>
      <StyleDdiv>
        <Header />
        {children}
      </StyleDdiv>
    </ThemeProvider>
  );
};

const StyleDdiv = styled.div`
  *:not(input, textarea) {
    user-select: text !important;
    -moz-user-select: text !important;
    -webkit-user-select: text !important;
  }
`;

export default HeaderLayout;
