import TickIcon from 'imports/ApplyForm/ui/assets/TickIcon';

export const LOADING_DATA_INFO = [
  {
    icon: <TickIcon />,
    title: 'Scanning your education.',
  },
  {
    icon: <TickIcon />,
    title: 'Scanning your experience.',
  },
  {
    icon: <TickIcon color="#909090" />,
    title: 'Checking your skills.',
  },
];
