export const ReviewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
        d="M9.49045 6.02734C9.18471 6.02734 8.98089 5.82734 8.98089 5.52734C8.98089 3.57734 7.40127 2.02734 5.41401 2.02734C4.44586 2.02734 3.57962 2.37734 2.86624 3.02734C2.71338 3.22734 2.35669 3.22734 2.15287 3.02734C1.94904 2.82734 1.94904 2.52734 2.15287 2.32734C3.01911 1.47734 4.19108 1.02734 5.41401 1.02734C7.96178 1.02734 10 3.02734 10 5.52734C10 5.82734 9.79618 6.02734 9.49045 6.02734Z"
        fill="#008BFF"
      />
      <path
        d="M5.58599 10.0273C3.03822 10.0273 1 8.02734 1 5.52734C1 5.22734 1.20382 5.02734 1.50955 5.02734C1.81529 5.02734 2.01911 5.22734 2.01911 5.52734C2.01911 7.47734 3.59873 9.02734 5.58599 9.02734C6.55414 9.02734 7.42038 8.67734 8.13376 8.02734C8.33758 7.82734 8.64331 7.82734 8.84713 8.02734C9.05096 8.22734 9.05096 8.52734 8.84713 8.72734C7.98089 9.57734 6.80892 10.0273 5.58599 10.0273Z"
        fill="#008BFF"
      />
      <path
        d="M9.45347 6.02734C9.3413 6.02734 9.22913 5.98265 9.11695 5.93795L7.21001 4.82049C6.98566 4.68639 6.92957 4.3735 7.09783 4.19471C7.26609 4.01592 7.6587 3.97122 7.88305 4.10531L9.78999 5.22277C10.0143 5.35687 10.0704 5.66976 9.90217 5.84855C9.78999 5.98265 9.62173 6.02734 9.45347 6.02734Z"
        fill="#008BFF"
      />
      <path
        d="M9.43806 6.02734C9.34815 6.02734 9.25824 5.97152 9.16833 5.9157C8.98851 5.74824 8.94355 5.35751 9.07842 5.13423L10.2023 3.23635C10.3372 3.01307 10.6519 2.95725 10.8317 3.12471C11.0115 3.29217 11.0564 3.68291 10.9216 3.90619L9.7977 5.80406C9.70779 5.97152 9.57292 6.02734 9.43806 6.02734Z"
        fill="#008BFF"
      />
      <path
        d="M3.45347 7.02734C3.3413 7.02734 3.22913 6.98265 3.11695 6.93795L1.21001 5.82049C0.985661 5.68639 0.929574 5.3735 1.09783 5.19471C1.26609 5.01592 1.60261 4.97122 1.88305 5.10531L3.78999 6.22277C4.01434 6.35687 4.07043 6.66976 3.90217 6.84855C3.78999 6.98265 3.62173 7.02734 3.45347 7.02734Z"
        fill="#008BFF"
      />
      <path
        d="M0.438059 8.02734C0.348149 8.02734 0.258238 7.97152 0.168327 7.9157C-0.0114936 7.74825 -0.0564488 7.35751 0.078417 7.13423L1.2023 5.23635C1.33716 5.01307 1.6069 4.95725 1.83167 5.12471C2.01149 5.29217 2.05645 5.68291 1.92158 5.90619L0.797701 7.80406C0.707791 7.97152 0.572925 8.02734 0.438059 8.02734Z"
        fill="#008BFF"
      />
    </svg>
  );
};

export const EqualToIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
        d="M2.02061 4.64554C2.02061 4.48159 2.09031 4.32435 2.21438 4.20841C2.33845 4.09248 2.50673 4.02734 2.68219 4.02734H9.33842C9.51023 4.03235 9.67321 4.09964 9.79285 4.21497C9.91249 4.33031 9.97939 4.48462 9.97939 4.64524C9.97939 4.80587 9.91249 4.96018 9.79285 5.07552C9.67321 5.19085 9.51023 5.25814 9.33842 5.26315H2.68155C2.50631 5.26283 2.33836 5.19756 2.21457 5.08165C2.09078 4.96575 2.02125 4.80869 2.02125 4.64494L2.02061 4.64554ZM9.99936 7.40884C9.99936 7.5728 9.92966 7.73004 9.80559 7.84598C9.68152 7.96191 9.51324 8.02704 9.33778 8.02704H2.68219C2.59362 8.02962 2.50539 8.01555 2.42275 7.98566C2.34012 7.95577 2.26474 7.91067 2.20112 7.85303C2.13749 7.7954 2.0869 7.72641 2.05235 7.65016C2.0178 7.57391 2 7.49195 2 7.40914C2 7.32634 2.0178 7.24438 2.05235 7.16813C2.0869 7.09187 2.13749 7.02288 2.20112 6.96525C2.26474 6.90762 2.34012 6.86252 2.42275 6.83263C2.50539 6.80274 2.59362 6.78866 2.68219 6.79124H9.33907C9.51441 6.7914 9.68253 6.8566 9.80646 6.97252C9.93039 7.08844 10 7.24559 10 7.40944L9.99936 7.40884Z"
        fill="#55D794"
      />
    </svg>
  );
};

export const UpArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
        d="M6.24264 10.27V1.7847M6.24264 1.7847L2.42426 5.60308M6.24264 1.7847L10.061 5.60308"
        stroke="#55D794"
        strokeWidth="1.18971"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
