import env from '/env';

export const denyQuiz = async (builderUserId) => {
  const data = {
    builderUserId,
  };
  const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/quiz-denied`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(`An error occured on qui-denied api: ${text}`);
  }
  return res.json();
};

export const preQuizTemplate = async (builderUserId) => {
  const data = {
    builderUserId,
  };
  const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/pre-quiz-template`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(`An error occured on pre-quiz-template api: ${text}`);
  }
  return res.json();
};

export const finalizePostOnboarding = async (builderUserId, isExperienceEmpty = false) => {
  const data = {
    builderUserId,
    isExperienceEmpty,
  };
  const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/post-onboarding`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(`Failed to finalize post-onboarding: ${text}`);
  }
  return res.json();
};

export const generateParagraphBulletPoint = async (data) => {
  const url = `${env.JOB_TRACK_API}/auto-onboarding/generate-paragraph-summary`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Failed to update experiences: ${response.statusText}`);
  }

  const results = await response.json();
  return results;
};

export async function updateExperienceBulk(data) {
  const url = `${env.JOB_TRACK_API}/auto-onboarding/experience/bulk-update`;
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Failed to update experiences: ${response.statusText}`);
  }

  const results = await response.json();
  return results;
}

export const getResumeDetails = async (builderUserId) => {
  const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/resume-details?builderUserId=${builderUserId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(`Failed to fetch resumeDetails: ${text}`);
  }
  return res.json();
};

export const updateResumeDetails = async (builderUserId, isMultiplePage) => {
  const data = {
    builderUserId,
    field: 'isMultiplePage',
    value: isMultiplePage,
  };
  const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/resume-details`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (res.status === 400) {
    return res.json();
  }
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(`Failed to update resumeDetails: ${text}`);
  }
  return res.json();
};

export const getResumeTitle = async (builderUserId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/resume-title?builderUserId=${builderUserId}`, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const updateResumeTitle = async (builderUserId, resumeTitleObject) => {
  const data = {
    builderUserId,
    resumeTitle: resumeTitleObject,
  };

  try {
    const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/resume-title`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (res.status !== 200) {
      const text = await res.text();
      throw new Error(`Failed to update resume title: ${text}`);
    }
    return res.json();
  } catch (error) {
    console.error('Error occurred while calling API:', error);
  }
};

export const generateReforumationBPbySuggestionItem = async (builderUserId, expId, bulletPoint) => {
  const data = {
    builderUserId,
    expId,
    bulletPoint,
  };
  try {
    const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/generate-bp-reformulation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (res.status !== 200) {
      const text = await res.text();
      throw new Error(`Failed to generate reforumationBP by SuggestionItem: ${text}`);
    }
    return res.json();
  } catch (error) {
    console.error('Error occurred while generating reforumationBP by SuggestionItem:', error);
  }
};

export const getExperienceListAutoOnboarding = async (builderUserId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/experience?builderUserId=${builderUserId}`, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const updateExperienceFields = async (builderUserId, experienceId, updatedFields) => {
  const data = {
    builderUserId,
    expId: experienceId,
    updatedExperience: updatedFields,
  };

  try {
    const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/experience`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (res.status !== 200) {
      const text = await res.text();
      throw new Error(`Failed to update experience: ${text}`);
    }
    return res.json();
  } catch (error) {
    console.error('Error occurred while calling API:', error);
  }
};

export const addExperience = async (builderUserId, additionalFields = {}) => {
  const data = {
    builderUserId,
    newExperience: {
      role: '',
      type: 'experience',
      location: '',
      hideMonth: false,
      expEndDate: null,
      companyName: '',
      expStartDate: null,
      currentWorking: true,
      experienceTitle: '',
      companyDescription: null,
      experienceContent: [],
      targetNatureQuestion: null,
      ...additionalFields,
    },
  };
  try {
    const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/experience`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (res.status !== 200) {
      const text = await res.text();
      throw new Error(`Failed to add experience: ${text}`);
    }
    return res.json();
  } catch (error) {
    console.error('Error occurred while adding experience:', error);
  }
};

export const deleteExperience = async (builderUserId, experienceId) => {
  const data = {
    builderUserId,
    expId: experienceId,
  };

  try {
    const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/experience`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (res.status !== 200) {
      const text = await res.text();
      throw new Error(`Failed to delete experience: ${text}`);
    }
    return res.json();
  } catch (error) {
    console.error('Error occurred while deleting experience:', error);
  }
};

export const generateBPByTitle = async (builderUserId, expId, title) => {
  const data = {
    builderUserId,
    expId,
    title,
  };
  try {
    const res = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/generate-bp-suggestion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (res.status !== 200) {
      const text = await res.text();
      throw new Error(`Failed to generate BP: ${text}`);
    }
    return res.json();
  } catch (error) {
    console.error('Error occurred while generating BP:', error);
  }
};

export const getSimpleQuestionnaries = async (builderId) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let url = `${env.JOB_TRACK_API}/extra-quiz?builderId=${builderId}`;
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};
