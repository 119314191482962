import StripeFormV3 from './StripeFormV3';
import React, { useState, Fragment } from 'react';
import { darken } from 'polished';
import { useRouter } from 'next/router';
import { cookieParser, getDefaultLanguage, isBlogDomain, refreshToken } from 'lib/helpers';
import styled, { css } from 'styled-components';
import { useResponsive } from 'imports/core/api/responsiveContext';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import { CheckIcon } from '/imports/core/ui/assets';
import ErrorMessage from '/imports/core/ui/atoms/ErrorMessage';
import Flex from '/imports/core/ui/atoms/Flex';
import useBilling from '/imports/checkout/api/useBilling';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { setCookie } from 'nookies';
import env from '/env';
import { useMutation } from 'react-apollo';
import { CREATE_STRIPE_SUBSCRIPTION_SCA } from 'imports/checkout/api/apollo/client/mutations';
import AccountContext, { useAccount } from 'imports/core/api/accounts/accountContext';
import useToaster from '/imports/core/api/useToaster';
import { Push } from 'components/Link';
import { useCheckoutResumeStore } from 'zustand/CheckoutResumeStore';

const isDev = env.NODE_ENV === 'development';

const StripeFromV2 = ({
  ctaTitle,
  agreeStatement,
  onError,
  isSubmitting: formSubmitting,
  error,
  isMobile,
  newDesign,
  id,
  resumeReview,
  resumeId,
  userId,
}) => {
  const { bruneiTitleLines, bruneiNameLines } = useCheckoutResumeStore();

  const stripe = useStripe();
  const elements = useElements();
  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { query } = useRouter();
  const { refetch } = useAccount(AccountContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [agreeError, setAgreeError] = useState(null);
  const { currentPlan, showTerms, rule } = useBilling();
  const [paymentMode, setPaymentMode] = useState('');
  const { host } = useResponsive();
  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      trackEvent('Payment Initiated');
      if (resumeReview) trackEvent('review_payment_initiated');
      const localeString = locale === getDefaultLanguage(host) ? '' : `${locale}/`;
      let url = '';
      if (resumeReview) {
        url = `${window.location.origin}/${localeString}resume-review-payment/success?id=${resumeId}&userId=${userId}`;
        if (isBlogDomain(host)) {
          url = `${window.location.origin}/builder/${localeString}resume-review-payment/success?id=${resumeId}&userId=${userId}`;
        }
      } else {
        url = `${window.location.origin}/${localeString}checkout/success?success=true&plan=${currentPlan.id}&bv=1&mode=${paymentMode}&isStripe=true&id=${query?.resumeId || resumeId || id}&currency=${currentPlan?.currency}&rwAmount=${currentPlan?.rwAmount}`;
        if (isBlogDomain(host)) {
          url = `${window.location.origin}/builder/${localeString}checkout/success?success=true&plan=${currentPlan.id}&bv=1&mode=${paymentMode}&isStripe=true&id=${query?.resumeId || resumeId || id}&currency=${currentPlan?.currency}&rwAmount=${currentPlan?.rwAmount}`;
        }
      }
      typeof localStorage !== 'undefined' && localStorage.setItem('skip_checkout', query?.resumeId || resumeId || id);
      if (query?.isSkip) {
        url += `&isSkip=true`;
      }
      url += `&bruneiTitleLines=${bruneiTitleLines}&bruneiNameLines=${bruneiNameLines}`;

      await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: url,
        },
      });
      if (resumeReview) trackEvent('review_paymet_success');
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      onError(e);
    }
  };

  const handleChange = (event) => {
    const mode = event.value.type;
    setPaymentMode(mode.toString());
  };

  const checkDisclaimer = (e) => {
    const clickedTag = e.target.tagName;
    // prevent updating state if user clicked on link
    if (clickedTag === 'A') return;
    setAgreeError(null);
    setChecked(!checked);
  };

  const errorHandler = () => {
    if (!rule?.showStripeCheckbox) return;
    if (!checked) setAgreeError('you have to click on the checkbox');
  };

  const disableSubmit = rule?.showStripeCheckbox
    ? formSubmitting || isSubmitting || !checked
    : formSubmitting || isSubmitting;
  const updated = !isMobile;
  return (
    <StyledForm updated={updated} newDesign={newDesign} onSubmit={onSubmit} $resumeReview={resumeReview}>
      <PaymentElement
        onChange={handleChange}
        options={{
          layout: 'tabs',
        }}
      />
      <Button id="payment-button" disabled={disableSubmit}>
        <div>{isSubmitting ? `${t('payment.loading')}...` : ctaTitle}</div>
      </Button>
      {rule?.showStripeCheckbox && (
        <Group alignItems="center" updated={updated}>
          <Disclaimer updated={updated}>
            {
              <Fragment>
                <CheckIconContainer onClick={checkDisclaimer} checked={checked}>
                  {checked && (
                    <CheckIconWrap>
                      <CheckIcon color={checked ? '#0099da' : '#cccccc'} />
                    </CheckIconWrap>
                  )}
                </CheckIconContainer>
                <span onClick={checkDisclaimer} dangerouslySetInnerHTML={{ __html: agreeStatement }} />
              </Fragment>
            }
          </Disclaimer>
        </Group>
      )}
      {showTerms && !rule?.showStripeCheckbox && (
        <Group alignItems="center" updated={updated}>
          <Disclaimer updated={updated}>
            {
              <Fragment>
                <span onClick={checkDisclaimer} dangerouslySetInnerHTML={{ __html: agreeStatement }} />
              </Fragment>
            }
          </Disclaimer>
        </Group>
      )}
    </StyledForm>
  );
};

const Button = styled.button`
  display: flex;
  border: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 14px 12px;
  margin: 15px 0;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fc8302;
  color: #fff;
  gap: 12px;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  &:hover {
    background-color: #ffa726;
  }
  &:active {
    background-color: #d65b00;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: #f7c797;
    `}
`;

const CheckoutWrapper = styled(Flex)`
  margin-bottom: 40px;
`;

const CheckIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin: 1px 5px;
  object-fit: contain;
  min-width: 13px;
  height: 13px;
  border: solid 1px #cccccc;
  cursor: pointer;
`;

const CheckIconWrap = styled.span`
  display: block;
  width: 8px;
  height: 7px;
  display: flex;
  svg {
    margin: auto;
  }
`;

const StyledForm = styled.form`
  padding: 10px 30px;
  background-color: white;
  ${({ theme: { larger, medium, smaller }, updated, $resumeReview }) => css`
    ${larger`
      padding-top: 1px;
    `}
    ${updated &&
    css`
      padding: 0 37px 0 44px;
      ${larger`
        padding: 0 33px 0 39px;
      `}
      ${medium`
        padding: 0 24px 0 29px;
      `}
      ${smaller`
        padding: 0 23px 0 27px;
      `}
    `}
    ${$resumeReview &&
    css`
      ${larger`
        padding: 0 33px 16px 39px;
      `}
      ${medium`
        padding: 0 24px 16px 29px;
      `}
      ${smaller`
        padding: 0 23px 16px 27px;
      `}
    `}
  `}
  width: 100%;
`;

const StyleError = styled.small`
  color: red;
`;

const CheckoutButton = styled.button`
  display: block;
  width: 100%;
  line-height: 1;
  padding: 14px 0 13px;
  border-radius: 4px;
  background-color: #ff6600 !important;
  font-family: ${({ theme }) => theme.font.family.correctText};
  font-size: 17px;
  text-align: center;
  color: white !important;
  letter-spacing: 2px;
  font-weight: 600;
  border: none;
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
  &:hover {
    background: ${darken(0.1, '#ff6600')};
  }
  :disabled {
    opacity: 0.5;
  }
  ${({ updated, theme: { larger, medium, smaller } }) =>
    updated &&
    css`
      height: 49px;
      border-radius: 6.7px;
      background-color: #0099da;
      font-family: ${({ theme }) => theme.font.family.websiteMedium};
      font-size: 18px;
      line-height: 0.69;
      letter-spacing: 2.7px;
      ${larger`
        height: 43px;
        border-radius: 5px;
        font-size: 14.5px;
        line-height: 0.76;
        letter-spacing: 2.18px;
      `}
      ${medium`
        height: 32px;
        border-radius: 3.6px;
        font-size: 10.5px;
        line-height: 0.76;
        letter-spacing: 1.58px;
      `}
      ${smaller`
        height: 31px;
        border-radius: 2.6px;
        font-size: 10px;
        line-height: 0.8;
        letter-spacing: 1.5px;
      `}
      text-align: center;
      color: var(--light-values-white);
      text-transform: uppercase;
      &:disabled {
        pointer-events: none;
      }
      &:hover {
        background-color: #1d8dd4;
      }
    `}
`;

const Disclaimer = styled.small`
  padding-left: 1em;
  color: #888888;
  a {
    color: inherit;
  }
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  ${({ updated, theme: { larger, medium, smaller } }) =>
    updated &&
    css`
      font-family: ${({ theme }) => theme.font.family.websiteRegular};
      font-size: 13px;
      line-height: 1.42;
      text-align: left;
      color: #a4a4a4;
      padding-left: 0;
      ${larger`
        font-size: 11.5px;
        line-height: 1.39;
      `}
      ${medium`
        font-size: 9.5px;
        line-height: 1.47;
      `}
      ${smaller`
        font-size: 8px;
        line-height: 1.5;
      `}
      a {
        color: inherit;
      }
    `}
`;

const Group = styled(Flex)`
  margin-bottom: 1em;
  ${({ updated, theme: { larger, medium, smaller } }) =>
    updated &&
    css`
      margin-bottom: 32px;
      ${larger`
        margin-bottom:29px;
      `}
      ${medium`
        margin-bottom: 18px;
      `}
      ${smaller`
        margin-bottom: 14px;
      `}
    `}
`;

export default StripeFromV2;
