import { prepareLanguageLabelBeforeTranslate } from 'imports/core/ui/components/LocaleSelect';
import { extractBlockInfo, replacedBlocks } from 'imports/generator/api/helpers';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import styled, { css } from 'styled-components';
import { useSelectStore } from 'zustand/SelectStore';
import { useLanguageSelectStore } from 'zustand/LanguageSelectedStore';
import { extractTemplateVersionByID, formattedDefaultSelected } from '../helpers';
import intlHook from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import CheckIcon from '/imports/core/ui/assets/new/CheckIcon';
import Button from '/imports/core/ui/atoms/Button';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';
import { UPDATE_BLOCK_POSITION } from '/imports/generator/api/apollo/client/mutations';
import { Chevron } from '/imports/generator/ui/assets';
import { TEMPLATES_FONTS } from '/imports/pdf/core/api/constants';
import { useTranslationData } from '/imports/generator/api/data/dropdownData';
const Dropdown = (props) => {
  const [updateBlockPosition] = useMutation(UPDATE_BLOCK_POSITION);
  const { t } = intlHook();
  const { trackEvent } = useTracking();
  const lastTargetButton = useRef(null);
  const { language } = useLanguageSelectStore();
  const { expanded, setExpanded } = useSelectStore();
  const [customValue, setCustomValue] = useState('');
  const [customValueTranslated, setCustomValueTranslated] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const { salutation_data, complimentaryclosing_data } = useTranslationData();
  const optionsMapping = {
    salutation: salutation_data,
    complimentaryclosing: complimentaryclosing_data,
  };
  const keyRef = useRef();
  const keyDownTimeoutRef = useRef();
  const [hovered, setHovered] = useState(() => {
    return props.hovered || '';
  });
  const dropdownComponent = useRef();
  const dropdown = useRef();
  const {
    name,
    dark,
    options,
    defaultSelected,
    preview,
    isControlled,
    selected: customSelected,
    borderActive,
    borderError,
    isFontSelector,
    id = undefined,
  } = props;

  const isExpanded = expanded === name;

  const getOptionsByName = (name) => {
    return optionsMapping[name] ? optionsMapping[name].map((v) => ({ title: t(v, language), value: v })) : [];
  };

  const optionsData = useMemo(() => {
    return [...getOptionsByName(name)];
  }, [name, language]);

  const foundOption = optionsData.find((option) => option.title === defaultSelected);
  const chevron = isExpanded ? <Chevron.Up /> : <Chevron.Down />;
  const selectedWrapperName = `interactive-element-clickable-${name}`;

  const sortedOptions = useMemo(() => {
    if (name === 'language') {
      const translatedOptions = options.map((option) => {
        const translatedTitle = t(`language.${prepareLanguageLabelBeforeTranslate(option.title)}`);
        return {
          ...option,
          translatedTitle,
        };
      });

      translatedOptions.sort((a, b) => {
        return a.translatedTitle.localeCompare(b.translatedTitle);
      });
      return translatedOptions;
    } else if (['salutation', 'complimentaryclosing'].includes(name)) {
      return [...options, ...optionsData];
    } else {
      return options;
    }
  }, [language, options, t, optionsData, name]);

  const [selected, setSelected] = useState(() => {
    const value_1 = props.options.find(
      (o) => o.value.toString() === (props.isControlled ? props.selected : formattedDefaultSelected(defaultSelected)),
    );
    let value_2 = {};
    if (['salutation', 'complimentaryclosing'].includes(name) && optionsMapping[name]) {
      value_2 = optionsData.find(
        (o) => o.value.toString() === (props.isControlled ? props.selected : formattedDefaultSelected(defaultSelected)),
      );
    }
    return value_1 || value_2 || {};
  });

  const title = useMemo(() => {
    const { dark, placeholder, placeholderSlug, expandTitle } = props;
    if (dark && isExpanded) {
      return expandTitle;
    }
    const languageTitle = prepareLanguageLabelBeforeTranslate(selected.title);
    const isSalutation = ['salutation', 'complimentaryclosing'].includes(name);
    const title =
      selected.titleSlug && t(selected.titleSlug)
        ? t(selected.titleSlug)
        : isSalutation
          ? t(selected.value, language) || selected.value
          : selected.title;
    if (title && selected.appendText) title = `${title} ${selected.appendText}`;
    return (
      t(`language.${languageTitle}`) ||
      title || <span>{placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}</span>
    );
  }, [selected, language]);

  const scrollOption = () => {
    const { scrollOption } = props;

    if (scrollOption) {
      const currentYear = new Date().getFullYear();
      const elem = document.getElementById(`value-${formattedDefaultSelected(defaultSelected) || currentYear}`);
      if (elem) {
        const offSet = elem.offsetTop;
        dropdown.current.scrollTop = offSet;
      }
    }
  };

  const outsideClick = (event) => {
    if (!dropdown.current.contains(event.target) && isExpanded) {
      setExpanded(!expanded);
    }
  };

  const handleDropdownScroll = (event) => {
    const { name } = props;
    const letter = event.key;
    keyRef.current = `${keyRef.current || ''}${letter}`;
    if (keyDownTimeoutRef.current) clearTimeout(keyDownTimeoutRef.current);
    keyDownTimeoutRef.current = setTimeout(() => {
      keyRef.current = '';
    }, 1000);
    if (isExpanded && /^[A-Za-z]$/.test(letter)) {
      const buttons = Array.from(
        document.querySelectorAll(
          `#dropdown-menu-${name} > button[id^="value-"],#dropdown-menu-${name} > div[id^="value-"]`,
        ),
      );
      const targetButton = buttons.find((button) => {
        const buttonText = button.textContent.trim();
        const isMatch =
          buttonText.startsWith(letter) || buttonText.toLowerCase().startsWith(keyRef.current.toLowerCase());
        return isMatch && lastTargetButton.current !== button;
      });

      if (targetButton) {
        lastTargetButton.current = targetButton;

        const yPosition =
          targetButton.getBoundingClientRect().top -
          dropdown.current.getBoundingClientRect().top +
          dropdown.current.scrollTop -
          5;

        dropdown.current.scrollTo({ top: yPosition });
      }
    }
  };

  useEffect(() => {
    if (['salutation', 'complimentaryclosing'].includes(name)) {
      if (!foundOption && defaultSelected) {
        setCustomValue(defaultSelected);
        setIsEditing(true);
      }
    }
  }, [defaultSelected, optionsData]);

  useEffect(() => {
    if (['salutation', 'complimentaryclosing'].includes(name) && customValue) {
      const translated = t(customValue, language);
      setCustomValueTranslated(translated);
    }
  }, [language, customValue, name]);

  useEffect(() => {
    const addOutsideClickListeners = (parentElement) => {
      if (!parentElement) return;
      const children = Array.from(parentElement.childNodes);
      children.forEach((childNode) => {
        if (childNode.nodeType === Node.ELEMENT_NODE && !childNode.contains(dropdownComponent)) {
          childNode.addEventListener('click', outsideClick);
        }
      });
    };

    const removeOutsideClickListeners = (parentElement) => {
      if (!parentElement) return;
      const children = Array.from(parentElement.childNodes);
      children.forEach((childNode) => {
        if (childNode.nodeType === Node.ELEMENT_NODE && !childNode.contains(dropdownComponent)) {
          childNode.removeEventListener('click', outsideClick);
        }
      });
    };

    const onComponentMount = () => {
      scrollOption();

      const parentElement = dropdownComponent.parentNode?.parentNode?.parentNode;
      addOutsideClickListeners(parentElement);

      if (props.scrollOnKeyPress) {
        document.addEventListener('keydown', handleDropdownScroll);
      }
    };

    const onComponentUnmount = () => {
      const parentElement = dropdownComponent.parentNode?.parentNode?.parentNode;
      removeOutsideClickListeners(parentElement);

      if (props.scrollOnKeyPress) {
        document.removeEventListener('keydown', handleDropdownScroll);
      }
    };

    onComponentMount();

    return onComponentUnmount;
  }, [isExpanded]);

  const windowClick = () => {
    setExpanded(false);
    document.removeEventListener('click', windowClick);
  };

  const toggleExpanded = (e) => {
    e.stopPropagation();
    if (['salutation', 'complimentaryclosing'].includes(name)) {
      setCustomValueTranslated(t(customValue, language));
      setIsEditing(true);
    }
    if (isExpanded === false) {
      document.addEventListener('click', windowClick);
    } else {
      document.removeEventListener('click', windowClick);
      lastTargetButton.current = null;
    }
    setExpanded(expanded == name ? null : name);
  };

  const filteredOptions = useMemo(() => {
    if (!customValue && !customValueTranslated) return sortedOptions;
    const filtered = sortedOptions.filter((option) =>
      option.title.toLowerCase().includes(customValueTranslated.toLowerCase()),
    );
    return filtered;
  }, [customValue, sortedOptions, customValueTranslated]);

  const changeCustomValue = (e) => {
    const value = e.target.value;
    if (!value.trim()) {
      setSelected({ title: '', value: '' });
    }
    setCustomValue(value);
    setCustomValueTranslated(t(value, language) || value);
    setExpanded(name);
  };

  const moveLeftBlocksToRight = () => {
    const { currentResume: resume, updateImmue, source } = props;
    const myResume = resume || source;
    const { blocks } = myResume;
    let highestY = Math.max(...blocks.filter((block) => block.position[0] === 1).map((block) => block.position[1]), -1);

    const newBlocks = blocks.map((block) => {
      if (block.position[0] === 0) {
        highestY += 1;
        return {
          ...block,
          position: [1, highestY],
        };
      }
      return block;
    });
    const newResume = replacedBlocks(myResume, newBlocks);
    updateImmue(newResume);
    updateBlockPosition({
      variables: {
        resumeId: myResume?.id || myResume?.resumeId,
        blocks: extractBlockInfo(newBlocks),
      },
    });
  };
  const handleSelect = (option) => {
    const { onChange } = props;
    if (window) {
      switch (name) {
        case 'language':
          trackEvent('resume_language_selected', { resume_language: option.value });
          break;
        case 'headingFont':
          trackEvent('resume_headingFont_selected', { resume_headingFont: option.value });
          break;
        case 'contentFont':
          trackEvent('resume_contentFont_selected', { resume_contentFont: option.value });
          break;
        case 'salutation':
          trackEvent('resume_salutation_selected', { resume_salutation: t(option.value) });
          break;
        case 'complimentaryclosing':
          trackEvent('resume_complimentaryclosing_selected', { resume_complimentaryclosing: t(option.value) });
          break;
        default:
          break;
      }
    }
    if (name === 'mainColumnSize' && option.value === 'none') {
      moveLeftBlocksToRight();
    }

    if (name === 'salutation' || name === 'complimentaryclosing') {
      setIsEditing(true);
      setSelected(option);
      setCustomValue(option.value);
      setCustomValueTranslated(t(option.value, language));
      if (onChange) {
        props.onChange({ target: { title: t(option.value, language), name: name, value: option.value } });
      }
    }

    if (name == 'template' && ['budapest-v2', 'budapest-v3'].includes(option.value)) {
      onChange({
        target: {
          title: option?.title,
          name: name,
          value: 'budapest',
          version: extractTemplateVersionByID(option.value),
        },
      });
    } else {
      onChange({ target: { title: option?.title, name: name, value: option.value } });
    }

    setSelected(option);
    setExpanded(false);
  };

  const handleHover = (hovered) => {
    setHovered(hovered);
  };

  const handleBlur = () => {
    const trimmedValue = customValue;
    if (trimmedValue) {
      setSelected({ title: customValueTranslated, value: trimmedValue });
    } else {
      setSelected({ title: '', value: '' });
    }
    setIsEditing(false);
    if (props.onChange) {
      props.onChange({ target: { title: customValueTranslated, name, value: trimmedValue } });
    }
  };

  return (
    <DropdownContent
      dark={dark}
      huge={preview}
      ref={dropdownComponent}
      id={id}
      role="combobox"
      aria-describedby={`selected-${selectedWrapperName}`}
    >
      <Selected
        onClick={toggleExpanded}
        dark={dark}
        preview={preview}
        expanded={isExpanded}
        borderActive={borderActive}
        borderError={borderError}
        font={isControlled ? customSelected : selected.value}
        isFontSelector={isFontSelector}
        name={selectedWrapperName}
        id={`selected-${selectedWrapperName}`}
      >
        {isEditing ? (
          <StyledInput
            key={`Input-${name}`}
            type="text"
            value={customValueTranslated || customValue}
            onChange={(e) => changeCustomValue(e)}
            onBlur={handleBlur}
            autoFocus
          />
        ) : (
          title
        )}
        <ChevronContainer preview={preview}>{chevron}</ChevronContainer>
      </Selected>
      {filteredOptions.length > 0 && (
        <DropdownMenu
          dark={dark}
          expanded={isExpanded}
          onClick={(e) => e.stopPropagation()}
          ref={dropdown}
          huge={preview}
          preview={preview}
          className="dropdown-menu"
          id={`dropdown-menu-${name}`}
        >
          {filteredOptions.map((option, index) => (
            <DropdownButton
              key={`${option.value}-${index}`}
              dark={dark}
              onMouseEnter={() => handleHover(option.value)}
              onMouseLeave={handleHover}
              onClick={() => handleSelect(option)}
              selected={isControlled ? option.value === customSelected : option.value === selected.value}
              hovered={hovered === option.value}
              preview={preview}
              id={`value-${option.value}`}
              font={option.value}
              isFontSelector={isFontSelector}
              role="option"
            >
              <CheckIcon />
              {name === 'language' ? (
                <> {t(`language.${prepareLanguageLabelBeforeTranslate(option.title)}`)}</>
              ) : option.titleSlug && t(option.titleSlug) ? (
                t(option.titleSlug)
              ) : (
                option.title
              )}
            </DropdownButton>
          )) || props.children}
        </DropdownMenu>
      )}
    </DropdownContent>
  );
};

Dropdown.propTypes = {
  selected: PropTypes.string,
  defaultSelected: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  stripe: PropTypes.bool,
  scrollOption: PropTypes.bool,
  expandTitle: PropTypes.string,
  isControlled: PropTypes.bool,
  borderActive: PropTypes.bool,
  borderError: PropTypes.bool,
  isFontSelector: PropTypes.bool,
  scrollOnKeyPress: PropTypes.bool,
  name: PropTypes.string,
  locale: PropTypes.string,
  placeholderSlug: PropTypes.string,
  preview: PropTypes.any,
  children: PropTypes.node,
  id: PropTypes.string,
};

export const StyledInput = styled.input`
  width: 100%;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  border: none;
  outline: none;
`;

export const DropdownContent = styled.div`
  position: relative;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;

  > button {
    font-size: 32px;
  }

  ${(p) =>
    p.dark &&
    css`
      width: auto;
    `}

  ${({ preview }) =>
    preview &&
    css`
      margin-left: 2.8%;
    `}

  ${({ theme: { isRTL }, preview }) =>
    isRTL &&
    css`
      direction: rtl;
      margin-right: ${preview ? '2.8%' : 'auto'};
    `}
`;

export const DropdownMenu = styled.div`
  width: auto;
  height: auto;
  max-height: 154px;
  padding: 6px 0;
  background-color: var(--light-values-white);
  border: solid 2px #e6e6ff;
  border-top: none;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 18px;
  letter-spacing: normal;
  color: #3d4047;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 226;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.gray.regular};
    border: 4px solid ${(p) => p.theme.colors.gray.lighter};
    border-radius: 6px;
    ${(p) =>
      p.dark &&
      css`
        border-color: ${(p) => p.theme.colors.black};
      `}
    &:hover {
      background-color: ${(p) => p.theme.colors.primary};
    }
  }
  ${({ expanded }) =>
    expanded &&
    css`
      transform: scaleY(1);
      opacity: 1;
      box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.1);
    `}

  ${(p) =>
    p.dark &&
    css`
      top: calc(100% + 17px);
      width: 170px;
      height: 176px;
      background-color: ${(p) => p.theme.colors.black};
      box-shadow: none;
      padding: 0 20px 7px 20px;
    `}

    ${({ preview, theme }) =>
    preview &&
    css`
      width: 180px;
      border-top: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
      top: 100%;
      border: none;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.gray.regular};
        border: 4px solid transparent;
      }

      button {
        font-size: 14px;
        color: ${theme.colors.white};
      }
    `}
     ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      border: 1px solid #1688fe !important;
      border-radius: 4px;
      max-height: 184px !important;
      padding: 0;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(20, 20, 31, 0.48) !important;
        border-radius: 2px;
      }
    `}
`;

export const dropdownButtonCSS = css`
  cursor: pointer;
  text-align: left;
  width: 100%;
  display: block;
  color: #484870;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 400;
  padding: 6px 16px;
  line-height: 1.2;
  letter-spacing: normal;
  background: transparent;
  border: 0;
  svg {
    display: none;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: ltr;
      text-align: right;
    `}

  &:hover {
    box-shadow: none;
    color: #1688fe;
    background: #f7f7fc;
  }

  ${(p) =>
    p.selected &&
    css`
      color: ${p.theme.colors.gray.light};
      ${p.theme.max('xs')`
      color: #1688fe;
      background-color: #f7f7fc;
    `}
    `}
  ${(p) =>
    p.hovered &&
    css`
      color: ${p.theme.colors.primary};
      background: ${rgba(p.theme.colors.primary, 0.1)};
      border-radius: 0;
    `}
${(p) =>
    p.dark &&
    css`
      padding: 10px 0px;
      color: var(--light-values-white);
      ${p.selected &&
      css`
        color: ${p.theme.colors.gray.regular};
      `}
      &:hover {
        background: none;
      }
      ${p.hovered &&
      css`
        background: none;
      `}
    `}
  ${(p) =>
    p.isFontSelector &&
    css`
      font-family: ${(p) => TEMPLATES_FONTS[p.font]?.regular};
      &:hover {
        font-family: ${(p) => TEMPLATES_FONTS[p.font]?.regular};
      }
    `}
    ${({ theme }) =>
    theme.isJobTrack &&
    css`
      font-family: ${theme.font.family.websiteSemiBold} !important;
      font-size: 13px !important;
      line-height: 20px !important;
      &:hover {
        background-color: var(--light-values-light-extra) !important;
        color: var(--dark-values-black) !important;
      }
    `}
`;

export const DropdownButton = styled((p) => <div {...p} />)`
  ${dropdownButtonCSS}
`;

export const ChevronContainer = styled.span`
  line-height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-30%);

  svg > path {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  ${({ preview }) =>
    preview &&
    css`
      svg > path {
        stroke: ${({ theme }) => theme.colors.primary}!important;
      }
    `}

  ${({ SABudapestHide }) =>
    SABudapestHide &&
    css`
      svg > path {
        stroke: #b5b6b6 !important;
      }
    `}

  ${({ theme }) =>
    theme.max('xs')`
      svg > path {
        stroke: #1688fe !important;
      }
    `}
    ${({ theme }) =>
    theme.isJobTrack &&
    theme.max('xs')`
       svg > path {
        stroke: #4C4C55 !important;
      }
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      left: 10px !important;
    `}
`;

export const selectedStyle = css`
  ${inputStyle}
  ${overrideInputStyleMixin}
  padding: 13px 35px 9px 16px;
  color: #484870;
  border: solid 2px #e6e6ff;
  box-shadow: none;
  border: 2px solid #e6e6ff;
  ${({ theme }) =>
    theme.designV2 &&
    css`
      height: 48px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 13px 18px 13px;
      border: solid 1px #e3e3e4;
      background-color: #ffffff;
      flex-direction: row;
    `}
  ${(p) =>
    p.dark &&
    css`
      background: transparent;
      color: white;
      border: 0;
      padding: 6px 56px 2px 12px;
      ${({ theme: { isRTL } }) =>
        isRTL &&
        css`
          padding: 6px 12px 2px 56px;
        `}

      > i {
        color: white;
        right: 24px;
      }
    `}

border-radius: ${({ expanded, theme }) =>
    theme.designV2 ? (expanded ? '8px 8px 0 0' : '8px') : expanded ? '3px 3px 0 0' : '3px'};
  ${({ preview, theme, expanded }) =>
    preview &&
    css`
      min-width: 180px;
      width: 100%;
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
      padding-left: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 16px;
      box-shadow: 0 3px 4px -3px rgba(15, 15, 15, 0.12);
      color: ${theme.colors.white};

      ${expanded &&
      css`
        border-radius: 3px 3px 0 0;
      `}
    `}

  ${({ borderActive, expanded }) =>
    borderActive &&
    expanded &&
    css`
      border: solid 2px #1688fe !important;
    `}
  ${({ borderError, theme }) =>
    borderError &&
    css`
      border: solid 2px ${theme.colors.danger} !important;
    `}
span {
    color: ${({ theme }) => theme.colors.gray.regular} !important;
  }
  ${(p) =>
    p.isFontSelector &&
    css`
      font-family: ${(p) => TEMPLATES_FONTS[p.font]?.regular};
    `}
`;

export const Selected = styled.p`
  ${selectedStyle}
  margin: 0;
  user-select: none;
  cursor: pointer;
  outline: none;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      border: 1px solid #e3e3e4 !important;
      border-radius: 8px !important;
      padding: 13px 12px !important;
      span {
        font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
        color: ${(p) => p.theme.colors.gray.regular};
        font-size: 15px;
        line-height: 22px;
      }
    `}
  ${({ SABudapestHide }) =>
    SABudapestHide &&
    css`
      opacity: 0.9;
      cursor: not-allowed;
      color: #b5b6b6;

      &:hover {
        color: #b5b6b6 !important;
      }
    `}
`;

export default Dropdown;
