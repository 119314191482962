import React from 'react';
import styled, { css } from 'styled-components';
import Proptypes from 'prop-types';
import View from '/imports/pdf/core/ui/atoms/View';

const BlockItems = styled(View)`
  position: relative;
  ${({ breakableView }) =>
    breakableView &&
    css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    `}
  ${({ theme, isList, left }) =>
    isList &&
    css`
      display: flex !important;
      flex-direction: row;
      padding: 0px !important;
      flex-wrap: wrap;
      ${!left &&
      css`
        page-break-inside: avoid !important;
      `}

      align-items: center;
    `}
`;

const BlockItemsWrapper = ({ isList, children, breakableView, left }) => {
  return (
    <BlockItems isList={isList} breakableView={breakableView} left={left}>
      {children}
    </BlockItems>
  );
};

BlockItemsWrapper.propTypes = {
  isList: Proptypes.bool,
  breakableView: Proptypes.number,
  left: Proptypes.bool,
  children: Proptypes.node,
};

export default BlockItemsWrapper;
