import React from 'react';

export const CheckoutCtaListIcon1 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <g clipPath="url(#clip0_20658_29374)">
        <path
          d="M20.2121 15.3834C19.6889 15.1225 19.0751 15.1784 18.6083 15.5278L16.4672 17.1333C16.3858 17.194 16.2851 17.2283 16.1826 17.2283H15.9665C16.0154 17.0787 16.0418 16.919 16.0418 16.7532V15.7499C16.0418 14.9051 15.3549 14.2188 14.511 14.2188H9.73173C9.49721 14.2188 9.26093 14.2746 9.04709 14.3807L6.01021 15.8991V14.7467C6.01021 14.455 5.77393 14.2188 5.48221 14.2188H1.46941C1.17769 14.2188 0.941406 14.455 0.941406 14.7467V21.7696C0.941406 22.0613 1.17769 22.2976 1.46941 22.2976H5.48441L16.0479 22.243C16.4404 22.2936 16.8368 22.1885 17.158 21.9491L20.4448 19.4833C20.8285 19.1965 21.0578 18.7389 21.0578 18.2584V16.7536C21.0578 16.1697 20.7335 15.6448 20.2116 15.3843L20.2121 15.3834ZM1.99741 15.2739H4.95421V21.2407H1.99741V15.2739ZM20.0022 18.2575C20.0022 18.4071 19.9314 18.5484 19.8126 18.6368L16.5258 21.1025C16.425 21.1778 16.304 21.2108 16.1126 21.1901L6.00977 21.2385V17.0783L9.51745 15.3253C9.58521 15.2915 9.65869 15.2739 9.73129 15.2739H14.5106C14.7724 15.2739 14.9853 15.4873 14.9853 15.7491V16.7523C14.9853 17.0141 14.7724 17.2275 14.5106 17.2275H9.49457C9.20285 17.2275 8.96657 17.4638 8.96657 17.7555C8.96657 18.0472 9.20285 18.2835 9.49457 18.2835H16.1821C16.5126 18.2835 16.839 18.1743 17.1008 17.9772L19.2406 16.3725C19.454 16.2115 19.6594 16.2867 19.7386 16.3272C19.8174 16.3664 20.0009 16.4869 20.0009 16.7523V18.2571L20.0022 18.2575Z"
          fill="#14141F"
        />
        <path
          d="M10.3843 7.75134H11.6168C11.7136 7.75134 11.7928 7.83054 11.7928 7.92778V8.63222C11.7928 8.72902 11.714 8.80734 11.6168 8.80734H9.94478C9.65306 8.80734 9.41678 9.04362 9.41678 9.33534C9.41678 9.62706 9.65306 9.86334 9.94478 9.86334H10.4728V10.3913C10.4728 10.6831 10.7091 10.9193 11.0008 10.9193C11.2925 10.9193 11.5288 10.6831 11.5288 10.3913V9.86334H11.6168C12.2957 9.86334 12.8488 9.3107 12.8488 8.63222V7.92778C12.8488 7.24798 12.2961 6.69534 11.6168 6.69534H10.3843C10.2875 6.69534 10.2083 6.61702 10.2083 6.52022V5.81578C10.2083 5.71898 10.2871 5.63934 10.3843 5.63934H12.0563C12.3481 5.63934 12.5843 5.40306 12.5843 5.11134C12.5843 4.81962 12.3481 4.58334 12.0563 4.58334H11.5283V4.05534C11.5283 3.76362 11.2921 3.52734 11.0003 3.52734C10.7086 3.52734 10.4723 3.76362 10.4723 4.05534V4.58334H10.3843C9.70542 4.58334 9.15234 5.13598 9.15234 5.81578V6.52022C9.15234 7.1987 9.70498 7.75134 10.3843 7.75134Z"
          fill="#14141F"
        />
        <path
          d="M11.0007 13.2709C14.3341 13.2709 17.0463 10.5587 17.0463 7.22485C17.0463 3.89097 14.3341 1.17969 11.0007 1.17969C7.66724 1.17969 4.95508 3.89185 4.95508 7.22485C4.95508 10.5578 7.66724 13.2709 11.0007 13.2709ZM11.0007 2.23569C13.752 2.23569 15.9903 4.47353 15.9903 7.22485C15.9903 9.97617 13.752 12.2149 11.0007 12.2149C8.24936 12.2149 6.01108 9.97617 6.01108 7.22485C6.01108 4.47353 8.24936 2.23569 11.0007 2.23569Z"
          fill="#14141F"
        />
      </g>
      <defs>
        <clipPath id="clip0_20658_29374">
          <rect width="22" height="22" fill="white" transform="translate(0 0.738281)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckoutCtaListIcon2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <g clipPath="url(#clip0_20658_29380)">
        <path
          d="M10.9994 15.5808C13.1219 15.5808 14.8425 13.8602 14.8425 11.7377C14.8425 9.61516 13.1219 7.89453 10.9994 7.89453C8.87688 7.89453 7.15625 9.61516 7.15625 11.7377C7.15625 13.8602 8.87688 15.5808 10.9994 15.5808Z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.873 10.7244C11.873 10.7244 11.6668 10.25 10.8761 10.25C10.0855 10.25 9.54926 11.3741 10.6458 11.6697C11.8902 12.0031 12.0586 12.2334 11.9555 12.7216C11.8214 13.3575 10.1096 13.4641 10.0374 12.5703"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10.9785 13.8169V13.2188" stroke="#231F20" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.9785 10.2544V9.65625" stroke="#231F20" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M16.1255 6.04766V7.80422L19.9824 4.66578L16.1255 1.52734V3.28047H4.25586V6.04766H16.1255Z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.87445 17.4245V15.668L2.01758 18.8064L5.87445 21.9448V20.1917H17.7441V17.4245H5.87445Z"
          stroke="#231F20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20658_29380">
          <rect width="22" height="22" fill="white" transform="translate(0 0.738281)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckoutCtaListIcon3 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <g clipPath="url(#clip0_20658_29392)">
        <path
          d="M20.0807 15.6416C19.8768 14.6824 18.8586 14.0875 17.9221 14.3887C17.919 14.3897 15.0335 15.3177 13.8069 15.7122C13.6762 15.0901 13.2095 14.5692 12.5521 14.3876L9.88439 13.7131C9.10388 13.5161 8.28719 13.6246 7.58499 14.0196L3.26182 16.4447C3.12416 16.5214 3.03906 16.6664 3.03906 16.8238V22.1573C3.03906 22.397 3.23355 22.5915 3.47327 22.5915H6.17635C6.25522 22.5915 6.33296 22.57 6.40052 22.5293L8.5126 21.2564L11.5073 21.6852C11.6019 21.6995 11.6971 21.6809 11.7804 21.6346L19.2541 17.4647C19.9043 17.1023 20.2362 16.3693 20.0807 15.6416ZM6.05565 21.7231H3.9075V17.0788L5.67459 16.0934L7.85808 20.6367L6.05565 21.7231ZM18.8312 16.7065L11.4853 20.8049L8.71147 20.4078L6.43187 15.6647L8.01072 14.7766C8.51815 14.4911 9.10868 14.4131 9.67152 14.555L12.3299 15.2269C12.7692 15.3485 13.0412 15.7779 12.9632 16.2257C12.8808 16.6896 12.4011 17.0265 11.9373 16.9344C11.9327 16.9334 10.5055 16.6497 10.5055 16.6497C10.2717 16.6056 10.0418 16.7557 9.99464 16.9909C9.94799 17.2264 10.1007 17.4548 10.3358 17.5017L11.7629 17.7853C12.5914 17.9602 13.4542 17.4242 13.7393 16.646L18.1878 15.2153C18.6431 15.0701 19.1321 15.3556 19.2315 15.8227C19.3067 16.1757 19.1461 16.5307 18.8312 16.7065Z"
          fill="#14141F"
          stroke="#14141F"
          strokeWidth="0.3"
        />
        <path
          d="M12.8822 9.29341V12.896C12.8822 13.213 13.2241 13.4275 13.5104 13.2844C13.5191 13.28 15.0532 12.5129 15.0532 12.5129L16.5962 13.2844C16.8722 13.4223 17.2243 13.2056 17.2243 12.896C17.2243 12.8904 17.2243 10.1568 17.2243 9.29341C18.6037 8.52715 19.5401 7.05635 19.5401 5.36965C19.5401 2.89556 17.5273 0.882812 15.0532 0.882812C14.5071 0.882812 13.983 0.978362 13.4956 1.16663C13.2717 1.25313 13.1609 1.50472 13.2474 1.72833C13.3334 1.95194 13.5855 2.0636 13.8088 1.97681C14.1956 1.82699 14.6145 1.75123 15.0532 1.75123C17.0485 1.75123 18.6717 3.37443 18.6717 5.36965C18.6717 7.36488 17.0485 8.98808 15.0532 8.98808C13.058 8.98808 11.4348 7.36488 11.4348 5.36965C11.4348 4.93092 11.5106 4.51226 11.6604 4.12526C11.7469 3.90165 11.6355 3.65006 11.4119 3.56356C11.1897 3.47847 10.9373 3.58872 10.8502 3.81204C10.6619 4.29912 10.5664 4.82322 10.5664 5.36966C10.5664 7.05635 11.5028 8.52715 12.8822 9.29341ZM16.3559 9.66222V12.1935L15.2472 11.6391C15.125 11.5781 14.9814 11.5781 14.8593 11.6391L13.7506 12.1935V9.66222C14.1632 9.78766 14.6002 9.8565 15.0532 9.8565C15.5063 9.8565 15.9433 9.78766 16.3559 9.66222Z"
          fill="#14141F"
          stroke="#14141F"
          strokeWidth="0.3"
        />
        <path
          d="M14.2138 6.73921C14.3802 6.90565 14.662 6.90502 14.8278 6.73921L16.9508 4.61621C17.1204 4.4466 17.1204 4.17182 16.9508 4.00221C16.7812 3.8326 16.5064 3.8326 16.3368 4.00221L14.5208 5.81821L13.7666 5.06371C13.5976 4.89466 13.3228 4.89353 13.1526 5.06371C12.983 5.23304 12.983 5.5081 13.1526 5.67771C13.1526 5.67771 14.2095 6.73487 14.2138 6.73921Z"
          fill="#14141F"
          stroke="#14141F"
          strokeWidth="0.3"
        />
        <path
          d="M12.1862 2.93873C12.426 2.93873 12.6204 2.74433 12.6204 2.50452C12.6204 2.26472 12.426 2.07031 12.1862 2.07031C11.9464 2.07031 11.752 2.26472 11.752 2.50452C11.752 2.74433 11.9464 2.93873 12.1862 2.93873Z"
          fill="#14141F"
          stroke="#14141F"
          strokeWidth="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_20658_29392">
          <rect width="22" height="22" fill="white" transform="translate(0 0.738281)" />
        </clipPath>
      </defs>
    </svg>
  );
};
