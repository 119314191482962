import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Push } from '/components/Link';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { RightArrow } from '/imports/landing/ui/assets';
import {
  DISABLE_UPLOAD_RESUME_EXP,
  DISABLE_UPLOAD_RESUME_EXP_CONTROL_ON_DESK,
  DISABLE_UPLOAD_RESUME_EXP_MOBILE,
  HIDE_UPLOAD_RESUME,
} from '/lib/helpers';

const CTA = ({ onClick, userIdentified, isBottom = false, isHomepage = false }) => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const { trackEvent } = useTracking();
  const { isMobile } = useResponsive();
  const aiTextActive = marvelEmitter.getActiveVariant('exp_old_landing_text') === 'ai_text';
  const getButtonText = () => {
    if (marvelEmitter.getActiveVariant('exp_old_landing_text') === 'ai_text') return t('ai_headline_landing_text2');
    return isMobile ? t('landing_cta_text').toLocaleUpperCase(locale) : t('landing.cta').toLocaleUpperCase(locale);
  };

  const handleRedirect = () => {
    trackEvent('cta_click', { isUploadResume: true });
    Push(`/upload-resume`, locale);
  };

  const isDisableUploadResume = DISABLE_UPLOAD_RESUME_EXP.includes(locale) && !isMobile;

  const disableOnDesk = DISABLE_UPLOAD_RESUME_EXP_CONTROL_ON_DESK.includes(locale) && !isMobile;

  const showUploadCTA = isDisableUploadResume && !disableOnDesk && !HIDE_UPLOAD_RESUME.includes(locale);

  const handleOnClick = (data) => {
    if (showUploadCTA) {
      return;
    }
    onClick(data);
  };

  const handleOnClick2 = (data) => {
    if (showUploadCTA) {
      return onClick(data);
    }
  };

  return (
    <Container
      $domain={host}
      $isHomepage={isHomepage}
      onClick={handleOnClick}
      $showUploadCTA={showUploadCTA}
      $aiTextActive={aiTextActive}
    >
      {showUploadCTA && (
        <UploadCTA onClick={handleRedirect} className="ai-text-upload-cta">
          <UploadText>{t('landing.uploadcta')}</UploadText>
          <RightArrow />
        </UploadCTA>
      )}
      <Inner1 onClick={handleOnClick2}>
        <Inner2 className="ai-text-upload-cta">
          <CTAText $isBottom={isBottom} $otherlocale={locale === 'hr'}>
            {!userIdentified ? getButtonText() : t('landing.my_resumes').toLocaleUpperCase(locale)}
          </CTAText>
          <RightArrow />
        </Inner2>
      </Inner1>
    </Container>
  );
};

const UploadCTA = styled.span`
  padding: 16px;
  background: #1688fe;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  gap: 8px;
  align-items: center;
  display: flex;
  &:hover {
    background-color: #0072e8;
  }
`;

const UploadText = styled.div`
  color: #fff;
  font-family: Gilroy SemiBold;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`;

const Container = styled.a`
  position: relative;
  bottom: auto;
  left: auto;
  width: max-content;
  display: block;
  margin: 0 auto 40px;
  z-index: 100;
  ${({ $aiTextActive }) =>
    $aiTextActive &&
    `
    max-width: 90%;
    @media only screen and (max-width:1024px){
      display: flex;
      flex-direction: column;
      gap:24px;
      span.ai-text-upload-cta{
        min-width: 300px;
        display: flex;
        justify-content:center;
      }
    }
  `}

  &:hover::after {
    width: 120px;
  }

  &:active::after {
    width: 20px;
  }
  ${({ $showUploadCTA }) =>
    $showUploadCTA &&
    css`
      display: flex;
      gap: 12px;
    `}
  ${({ $isHomepage }) =>
    $isHomepage &&
    css`
      margin: 30px auto 10px;
      ${({ theme }) =>
        theme.max('sm')`
          margin: 20px auto 22px;
      `}
    `}
  ${({ $domain }) =>
    $domain === 'mysmartcv' &&
    css`
      ${({ theme }) =>
        theme.min('sm')`
          max-width: unset;
      `}
    `}
`;

const Inner1 = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Container}:hover & {
    &::before {
      left: -21px;
    }

    &::after {
      top: -21px;
    }
  }

  ${Container}:active & {
    &::before {
      left: -25px;
    }

    &::after {
      top: -25px;
    }
  }
`;

const Inner2 = styled.span`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 0;
  background: #f37112;
  border-radius: 6px;
  transition: all 0.2s ease 0s;

  &:hover {
    box-shadow: 0px 10px 20px 0px rgba(243, 113, 1, 0.3);
  }
  @media (min-width: 768px) {
    max-width: unset;
    width: max-content;
  }
`;

const CTAText = styled.span`
  display: block;
  padding: 1px 20px 0;
  color: var(--light-values-white);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  left: 0;
  transition: all 0.2s;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  line-height: 50px;

  ${({ $otherlocale }) =>
    $otherlocale &&
    css`
      line-height: 35px;
      @media (min-width: 768px) {
        line-height: 50px;
      }
    `}

  ${({ theme }) =>
    theme.min('sm')`
      line-height: 60px;
    `}

  ${Container}:active & {
    left: 8px;
  }

  & ~ div {
    padding-right: 20px;
    ${({ $isBottom, theme: { isRTL } }) =>
      $isBottom &&
      css`
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        ${isRTL &&
        css`
          left: 0;
          right: unset;
        `}
      `}
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

CTA.propTypes = {
  onClick: PropTypes.func,
  userIdentified: PropTypes.bool,
};

export default CTA;
