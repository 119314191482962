import ChooseLanguageStep from './ChooseLanguageStep';
import LoadingSteps from './LoadingSteps';
import BlockTitle from '../../atoms/BlockTitle';
import { CheckIcon } from '../../assets';
import Globe from '../../assets/Globe';

import { useEffect, useState } from 'react';
import { BorderedButton, Label, PrevButton } from '/imports/generator/ui/components/translateResume/Styles';
import intlHook from 'imports/core/api/useIntl';
import Flex from 'imports/core/ui/atoms/Flex';

export default function NewTranslation({
  handleViewTranslatedResume,
  resumeTranslationState,
  selectedLanguage,
  resume,
  handleLanguageChange,
  handleClose,
  handleTranslateCV,
}) {
  const { t } = intlHook();
  const { state: translationState } = resumeTranslationState;
  const [step, setStep] = useState(1);
  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  useEffect(() => {
    if (translationState.finish === true) {
      setStep(4);
    }
  }, [translationState]);

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };
  return (
    <>
      {step === 1 && (
        <>
          <Flex $alignItems="center" style={{ gap: 8, marginBottom: 16, marginTop: 36 }}>
            {<Globe />}
            <BlockTitle style={{ margin: 0 }}>{t('translate_cv.ai_translate_title')}</BlockTitle>
          </Flex>
          <Label dangerouslySetInnerHTML={{ __html: t('translate_cv.ai_translate_description') }} />
          <BorderedButton $isLoading={translationState.loading} onClick={nextStep}>
            {t('translate_cv.ai_translate_button')}
          </BorderedButton>
        </>
      )}
      {step === 2 && (
        <>
          <Flex $alignItems="center" style={{ gap: 8, marginBottom: 16 }}>
            {<Globe />}
            <BlockTitle style={{ margin: 0 }}>{t('translate_cv.ai_translate_title')}</BlockTitle>
          </Flex>
          <Label dangerouslySetInnerHTML={{ __html: t('translate_cv.ai_translate_choose_lang') }} />
          <div style={{ margin: '16px 0' }}>
            <ChooseLanguageStep
              handleClose={handleClose}
              handleTranslateCV={handleTranslateCV}
              handleChange={handleLanguageChange}
              resume={resume}
              inputOnly={true}
              selectedLanguage={selectedLanguage}
            />
          </div>
          <Flex $direction="row" $justifyContent="space-between" $alignItems="center" style={{ gap: 8 }}>
            {/* <PrevButton onClick={prevStep}>{t('translate_cv.cancel')}</PrevButton> */}
            <BorderedButton
              $isLoading={translationState.loading}
              onClick={() => {
                nextStep();
                handleTranslateCV();
              }}
            >
              {t('translate_cv.translate')}
            </BorderedButton>
          </Flex>
        </>
      )}
      {step === 3 && (
        <>
          <Flex $alignItems="center" style={{ gap: 8, marginBottom: 16 }}>
            {<Globe />}
            <BlockTitle style={{ margin: 0 }}>{t('translate_cv.translating_cv')}...</BlockTitle>
          </Flex>
          <Label style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: t('translate_cv.ai_translating') }} />
          <Flex $direction="row" $justifyContent="space-between" $alignItems="center" style={{ gap: 8 }}>
            <LoadingSteps translationState={translationState} />
          </Flex>
        </>
      )}
      {step === 4 && (
        <div style={{ border: '1px solid #1688fe', borderRadius: 4, padding: 16 }}>
          <Flex $alignItems="center" style={{ margin: '16px 0', gap: 8 }}>
            <BlockTitle style={{ margin: 0 }}>{t('translate_cv.ai_completed')}</BlockTitle>
            <CheckIcon fill={'#1688fe'} />
          </Flex>
          <Label style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: t('translate_cv.ai_translated') }} />
          <Flex
            $direction="row"
            $justifyContent="space-between"
            $alignItems="center"
            style={{ gap: 8, marginTop: '25px' }}
          >
            <PrevButton onClick={() => setStep(1)}>{t('translate_cv.translate_another_language')}</PrevButton>
            <BorderedButton $isLoading={translationState.loading} onClick={handleViewTranslatedResume}>
              {t('translate_cv.view')}
            </BorderedButton>
          </Flex>
        </div>
      )}
    </>
  );
}

const Arrow = () => {
  return (
    <svg width="20" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" stroke={'#1688fe'} strokeWidth="2">
        <path strokeLinecap="round" d="M15.804 12.062L21.834 6.031 15.804 0" transform="translate(0 1)" />
        <path d="M0 6.031L22 6.031" transform="translate(0 1)" />
      </g>
    </svg>
  );
};
