import Link from 'components/Link';
import Flex from 'imports/core/ui/atoms/Flex';
import { RTLLanguages } from 'imports/generator/api/constants';
import {
  getBruneiHeaderTitleLines,
  getBruneiHeaderNameLines,
  getSABudapestResumeSettingsMap,
  isBruneiNewDesign,
  isBudapestNewDesignActive,
  isDesignWithIconSocialLink,
  isDesignWithSocialLink,
} from '/imports/generator/api/helpers';
import { Push } from 'imports/help/ui/components/Link';
import last from 'lodash/last';
import { withRouter } from 'next/router';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Fragment, PureComponent } from 'react';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';
import { useCheckoutResumeStore } from 'zustand/CheckoutResumeStore';
import PDFViewer from './PDFViewer';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import LinkButton from '/imports/core/ui/atoms/LinkButton';
import { GET_DOWNLOAD_LINK } from '/imports/generator/api/apollo/client/mutations';
import { freeAccessForYear, getDefaultLanguage, getRoute, isBlogDomain, isPaidUser } from '/lib/helpers';

@withTracking
@withResponsiveContext
@withAccount
@withRouter
@withIntl
@graphql(GET_DOWNLOAD_LINK)
class DownloadButton extends PureComponent {
  static propTypes = {
    source: PropTypes.object,
    isCoverLetter: PropTypes.bool,
    unorderedBulletPoint: PropTypes.string,
    orderedBulletPoint: PropTypes.string,
  };

  state = {
    href: '/checkout',
    isExternal: true,
    documentFormat: 'pdf',
    bruneiTitleLines: 0,
    bruneiNameLines: 0,
    resumeSource: null,
  };

  componentDidUpdate(nextProps) {
    const { source } = this.props;
    const { source: nextSource } = nextProps;

    if (source !== nextSource) {
      this.updateDownloadLinkHandler();
    }
  }

  componentDidMount() {
    if (!this.props.source || !this.props.currentUser) return;

    this.updateDownloadLinkHandler();
  }

  getTemplates() {
    return ['montecarlo', 'kiev', 'sf', 'vladivostok', 'shanghai'];
  }

  updateDownloadLinkHandler = () => {
    const {
      currentUser,
      currentUser: { role, gender, freeAccessUntil },
      source: {
        id,
        settings: { language, template },
      },
      source,
      isCoverLetter,
      locale,
      host,
      router,
      router: {
        query: { page },
      },
    } = this.props;
    const isRTL = RTLLanguages.includes(language);
    const [fileType] = this.state.documentFormat.split('/');
    const cvFormat = this.state.documentFormat;

    const { agent_client_id } = nookies.get({});
    const { setResume } = useCheckoutResumeStore.getState();
    const templates = this.getTemplates();
    if (router.pathname !== '/resumes') {
      setResume({ template, language: templates.includes(template) ? 'en' : language });
    } else {
      setResume(null);
    }
    const paidUser = role === 'agent' || !!agent_client_id || isPaidUser(role) || freeAccessForYear(freeAccessUntil);
    if (paidUser) {
      const { origin } = window.location;
      const isBrunei = this.props.source.settings.template === 'brunei';
      const queryString = {
        type: fileType,
        onePage: true,
        redneg: gender,
        isDesignWithSocialLink: isDesignWithSocialLink(this.props.currentUser, agent_client_id),
        isDesignWithIconSocialLink: isDesignWithIconSocialLink(this.props.currentUser, agent_client_id),
        isBudapestDesignActive: isBudapestNewDesignActive(this.props.currentUser, agent_client_id),
        ...getSABudapestResumeSettingsMap(this.props.source, this.props.currentUser, agent_client_id),
        userId: currentUser && currentUser?.id,
        cvFormat,
        isBruneiNewDesign: isBruneiNewDesign(this.props.currentUser, agent_client_id, isBrunei),
      };
      if (!isCoverLetter) {
        queryString.locale = locale || getDefaultLanguage(host);
      }

      const path = isCoverLetter ? 'cover-letter' : 'resume';

      const {
        source: {
          settings: { template },
        },
      } = this.props;

      this.setState({
        isExternal: false,
        href: `${origin}${getRoute(
          `/export/${path}/${id}?${qs.stringify(queryString)}${isCoverLetter ? (isRTL && template !== 'budapest' ? '&isSwapable=true' : '') : isRTL ? '&isSwapable=true' : ''}`,
          locale,
          false,
          host,
          isBlogDomain(host),
        )}`,
      });
    }
  };

  clickHandler() {
    const {
      router: { asPath },
      source,
      source: {
        settings: { template, language, headingFont, contentFont, templateVersion },
      },
      isCoverLetter,
      trackEvent,
      currentUser: { role },
      handleResumeHeaderLines,
    } = this.props;
    const { fileType } = this.state;
    const bruneiTitleLine = getBruneiHeaderTitleLines(source);
    const bruneiNameLine = getBruneiHeaderNameLines(source);
    if (handleResumeHeaderLines && typeof handleResumeHeaderLines == 'function')
      handleResumeHeaderLines(bruneiTitleLine, bruneiNameLine);
    this.setState({ bruneiTitleLines: bruneiTitleLine, bruneiNameLines: bruneiNameLine });

    if (!isCoverLetter) {
      return trackEvent('download_cta', {
        context: last(asPath.split('/')),
        is_subscribed: isPaidUser(role),
        template: `${template}${templateVersion > 1 ? `-v${templateVersion}` : ''}`,
        resume_language: language,
        cv_format: fileType,
        resume_headingFont: headingFont,
        resume_contentFont: contentFont,
        unordered_bullet_point: this.props.unorderedBulletPoint,
        ordered_bullet_point: this.props.orderedBulletPoint,
      });
    }

    return trackEvent('cover_letter_download_cta', {
      template,
      format: fileType,
    });
  }

  handleSetBruneiLines(bruneiTitleLine, bruneiNameLine) {
    if (!this.state.isExternal) {
      let href = this.state.href;
      if (href.includes('?')) {
        href = `${href}&bruneiHeaderTitleLines=${bruneiTitleLine}&bruneiHeaderNameLines=${bruneiNameLine}`;
      }
      window.open(href, '_blank');
    } else {
      const resume = useCheckoutResumeStore.getState();
      resume?.setBruneiTitleLines(bruneiTitleLine);
      resume?.setBruneiNameLines(bruneiNameLine);
    }
    this.setState({ resumeSource: null });
  }

  handleDownladResume() {
    const {
      router: { asPath },
      source,
      source: {
        settings: { template, language, headingFont, contentFont, templateVersion },
      },
      trackEvent,
      currentUser: { role },
    } = this.props;
    const { fileType } = this.state;
    this.setState({ resumeSource: source });
    trackEvent('download_cta', {
      context: last(asPath.split('/')),
      is_subscribed: isPaidUser(role),
      template: `${template}${templateVersion > 1 ? `-v${templateVersion}` : ''}`,
      resume_language: language,
      cv_format: fileType,
      resume_headingFont: headingFont,
      resume_contentFont: contentFont,
      unordered_bullet_point: this.props.unorderedBulletPoint,
      ordered_bullet_point: this.props.orderedBulletPoint,
    });
  }

  checkoutClickHandler() {
    const { source, handleResumeHeaderLines } = this.props;
    const bruneiTitleLine = getBruneiHeaderTitleLines(source);
    const bruneiNameLine = getBruneiHeaderNameLines(source);
    handleResumeHeaderLines(bruneiTitleLine, bruneiNameLine);
  }

  handleDownloadPdfButton(path, props) {
    const templates = this.getTemplates();
    const { setResume } = useCheckoutResumeStore.getState();
    const {
      source,
      source: {
        settings: { template, language },
      },
      locale,
    } = props;

    if (template === 'brunei' && !!source?.details?.userPic) {
      this.setState({ resumeSource: source });
    }

    Push(path, locale);
    setResume({ template, language: templates.includes(template) ? 'en' : language });
  }

  render() {
    const {
      className,
      children,
      cta,
      router: { asPath },
      source,
      source: { id = '', settings: { template, language }, settings, __typename: sourceType } = {},
      preview,
      t,
      locale,
      documentHeight,
      isCoverLetter,
    } = this.props;

    const { isExternal, resumeSource } = this.state;
    let { href, bruneiTitleLines, bruneiNameLines } = this.state;
    const queryString = {
      from: asPath,
      id,
      sourceType,
      resumeLang: language,
    };

    if (!isExternal && documentHeight) {
      href = `${href}&documentHeight=${documentHeight}`;
    }

    if (!isExternal && template === 'brunei' && sourceType === 'Resume' && !!source?.details?.userPic) {
      href = `${href}&bruneiHeaderTitleLines=${bruneiTitleLines}&bruneiHeaderNameLines=${bruneiNameLines}`;
    }

    return (
      <Fragment>
        {!!resumeSource && (
          <Wrappper>
            <Hiddenblock>
              <PDFViewer
                source={resumeSource}
                width={928}
                updateCount={resumeSource.updatesCount}
                resumeSource={resumeSource}
                handleSetBruneiLines={(bruneiTitleLine, bruneiNameLine) =>
                  this.handleSetBruneiLines(bruneiTitleLine, bruneiNameLine)
                }
              />
            </Hiddenblock>
          </Wrappper>
        )}
        <ButtonCont className={className} cta={cta} preview={preview}>
          {isExternal ? (
            <Fragment>
              {preview ? (
                <Link
                  href={`${href}?${qs.stringify(queryString)}&isSwapable=true`}
                  passHref
                  onClick={() => this.checkoutClickHandler()}
                >
                  <div>{children || t('download').toLocaleUpperCase(locale)}</div>
                </Link>
              ) : (
                <DownloadPdfButton
                  role="link"
                  onClick={() =>
                    this.handleDownloadPdfButton(`${href}?${qs.stringify(queryString)}&isSwapable=true`, this.props)
                  }
                >
                  {children || t('download').toLocaleUpperCase(locale)}
                </DownloadPdfButton>
              )}
            </Fragment>
          ) : preview || isCoverLetter || !(template === 'brunei' && !!source?.details?.userPic) ? (
            <a href={href} onClick={this.clickHandler.bind(this)} target="_blank" rel="noreferrer">
              {children || t('download').toLocaleUpperCase(locale)}
            </a>
          ) : (
            <DownloadBtn onClick={this.handleDownladResume.bind(this)}>
              {children || t('download').toLocaleUpperCase(locale)}
            </DownloadBtn>
          )}
        </ButtonCont>
      </Fragment>
    );
  }
}

const Wrappper = styled(Flex)`
  position: relative;
`;

const DownloadPdfButton = styled.div`
  color: #282b32;
  cursor: pointer;
  &:hover {
    color: #0087c1;
    svg > path {
      fill: #0087c1;
    }
  }
`;

const ButtonCont = styled((p) => <LinkButton {...p} />)`
  padding: 0 !important;
  font-family: ${(p) => p.theme.font.family.correctAccent};
  background-color: ${(p) => p.theme.colors.cta};
  border-color: ${(p) => p.theme.colors.cta};
  text-transform: none;
  font-size: 18px;

  a {
    display: block;
    padding: 14px 24px;
    color: ${({ theme }) => theme.colors.white};
    line-height: 1;
    text-decoration: none;

    ${(p) =>
      p.cta &&
      css`
        padding: 0.9em 1.7em 0.7em;
      `}

    ${({ preview, theme }) =>
      preview &&
      css`
        font-family: ${theme.font.family.systemFont};
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.35px;
        color: #f0efef;
        text-transform: uppercase;
      `}
  }
`;

const DownloadBtn = styled.div`
  color: #282b32;
  cursor: pointer;
  &:hover {
    color: #0087c1;
    svg > path {
      fill: #0087c1;
    }
  }
`;

const Hiddenblock = styled.div`
  position: absolute;
  top: -999999999px;
  left: -999999999px;
`;

export default DownloadButton;
