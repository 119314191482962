const SearchIcon = () => (
  <svg width="13" height="13">
    <path
      fillRule="evenodd"
      fill="#9C9C9C"
      d="M12.801 11.841 9.597 8.623a5.125 5.125 0 0 0 1.275-3.374C10.872 2.355 8.434 0 5.437 0 2.44 0 .002 2.355.002 5.249c0 2.894 2.438 5.248 5.435 5.248a5.51 5.51 0 0 0 3.114-.949l3.228 3.242c.135.135.316.21.511.21a.72.72 0 0 0 .491-.191.669.669 0 0 0 .02-.968zM5.437 1.369c2.215 0 4.017 1.741 4.017 3.88S7.652 9.128 5.437 9.128c-2.215 0-4.017-1.74-4.017-3.879 0-2.139 1.802-3.88 4.017-3.88z"
    />
  </svg>
);

export default SearchIcon;
