import Spinner from '../../atoms/Spinner';
import intlHook from 'imports/core/api/useIntl';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StepsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 8px;
`;

const StepItem = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyCircle = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 2px solid #d1d5db;
`;

const StepText = styled.span`
  margin-left: 0.75rem;
  color: ${(props) => (props.status === 'completed' ? '#1688fe' : props.status === 'current' ? '#3b82f6' : '#6b7280')};
`;

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ color: '#1688fe' }}
  >
    <path d="M20 6L9 17l-5-5" />
  </svg>
);

const LoadingSteps = ({ translationState }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = intlHook();
  const steps = [
    { id: 1, text: t('translate_cv.ai_translating.check.one'), complete: false, time: 5000 },
    { id: 2, text: t('translate_cv.ai_translating.check_two'), complete: false, time: 5000 },
    { id: 3, text: t('translate_cv.ai_translating.check_three'), complete: false, time: 200000 },
  ];
  useEffect(() => {
    if (translationState.finish === true) {
      setCurrentStep(steps?.length);
    }
  }, [translationState]);
  useEffect(() => {
    if (currentStep < steps.length) {
      const timer = setTimeout(() => {
        setCurrentStep((prevStep) => prevStep + 1);
      }, steps[currentStep].time);

      return () => clearTimeout(timer);
    }
  }, [currentStep]);

  const getStepStatus = (index) => {
    if (index < currentStep) return 'completed';
    if (index === currentStep) return 'current';
    return 'pending';
  };

  return (
    <StepsList>
      {steps.map((step, index) => (
        <StepItem key={step.id}>
          <IconWrapper>
            {index < currentStep ? <CheckIcon /> : index === currentStep ? <Spinner $width="20px" /> : <EmptyCircle />}
          </IconWrapper>
          <StepText status={getStepStatus(index)}>{step.text}</StepText>
        </StepItem>
      ))}
    </StepsList>
  );
};

export default LoadingSteps;
