import React, { PureComponent } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import nookies from 'nookies';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import Box from '/imports/core/ui/atoms/Box';
import Button from '/imports/core/ui/atoms/Button';
import Container from '/imports/core/ui/atoms/Container';
import Flex from '/imports/core/ui/atoms/Flex';
import { freeAccessForYear, isJobTrackEnable, isPaidUser, getJobTrackRoute } from '/lib/helpers';
import { getBoardList } from '/imports/job-tracking/api/api';
import Link, { Push } from '/components/Link';
import LocaleSelect from '/imports/core/ui/components/LocaleSelect';
import LoginForm from '/imports/core/ui/components/LoginForm';
import Logo from '/imports/core/ui/components/Logo';
import UserDropdown from '/imports/dashboard/ui/components/UserDropdown';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';

const JOBTRACK_NAV_LINK = ['jobtrack_header_item1', 'jobtrack_header_item2', 'jobtrack_header_item3'];

@withResponsiveContext
@withIntl
@withAccount
@withRouter
@onClickOutside
class Header extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    router: PropTypes.object,
    loginModal: PropTypes.bool,
  };

  state = {
    checkoutSeen: undefined,
    isOpenLogin: false,
    active: 0,
    boardId: null,
  };
  setActiveIndex = () => {
    const {
      router: { asPath, query },
    } = this.props;
    let activeTab = 0;
    if (query?.template === 'search') {
      this.setState({
        active: 1,
      });
      return;
    }
    if (query?.template === 'tracking') {
      this.setState({
        active: 2,
      });
      return;
    }
    switch (asPath) {
      case '/resumes':
        activeTab = 0;
        break;
      case '/cover-letters':
        activeTab = 0;
        break;
      case '/app/search':
        activeTab = 1;
        break;
      case '/app/tracking':
        activeTab = 2;
        break;
    }
    this.setState({
      active: activeTab,
    });
  };

  fetchBoardId = async () => {
    const { token } = nookies.get({});
    if (!token) return;
    const res = await getBoardList(token);
    if (res.length > 0)
      this.setState({
        boardId: res[0].boardId,
      });
  };

  componentDidMount() {
    const { isMobile, boardId } = this.props;
    const { checkoutSeen } = this.state;
    const withHeader = isJobTrackEnable() && !isMobile;
    if (checkoutSeen !== undefined) return;
    this.setState({
      checkoutSeen: localStorage.getItem('resumedone:checkout-seen'),
    });
    this.setActiveIndex();
    if (withHeader && !boardId) {
      this.fetchBoardId();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      router: { asPath },
    } = this.props;
    if (asPath !== prevProps.router.asPath) {
      this.setActiveIndex();
    }
  }

  goToCheckout = () => {
    const {
      router: { asPath },
      locale,
    } = this.props;
    const queryString = {
      from: asPath,
    };
    Push(`/checkout?${qs.stringify(queryString)}`, locale, '/checkout');
  };

  showUpgrade = () => {
    const { currentUser = {} } = this.props;
    const { checkoutSeen } = this.state;

    const isPaid = isPaidUser(currentUser.role) || freeAccessForYear(currentUser.freeAccessUntil);

    return !isPaid && checkoutSeen;
  };

  handleClickDashboard = () => {
    const { locale, host } = this.props;
    const link = /.+\/cover-letters/.test(document.referrer)
      ? getJobTrackRoute(host, 'coverletters').href
      : getJobTrackRoute(host, 'resumes').as;
    Push(`${link}`, locale, getJobTrackRoute(host, 'resumes').as);
  };

  handleModal = () => {
    this.setState({ isOpenLogin: !this.state.isOpenLogin });
  };

  handleClickOutside = () => {
    this.setState({ isOpenLogin: false });
  };

  handleNavigate = (navItem) => () => {
    let query = {};
    const { boardId } = this.state;
    const { locale } = this.props;
    const isFooterExp = isJobTrackEnable();
    switch (navItem) {
      case 0:
        return Push(`/resumes`, locale);
      case 1:
        if (isFooterExp) {
          if (!boardId) return;
          query = { ...query, template: 'tracking', id: boardId };
          return Push(`/app?${qs.stringify(query)}`, locale, `/app/${query.template}/${boardId}`);
        }
        query = { ...query, template: 'search' };
        return Push(`/app?${qs.stringify(query)}`, locale, `/app/${query.template}`);
      case 2:
        if (!boardId) return;
        query = { ...query, template: 'tracking', id: boardId };
        return Push(`/app?${qs.stringify(query)}`, locale, `/app/${query.template}/${boardId}`);
      default:
        return Push(`/resumes`, locale);
    }
  };

  getUserDisplayName = () => {
    const { currentUser } = this.props;
    if (currentUser.role === 'agent') {
      return 'Agent';
    }

    const firstName = currentUser.firstName?.trim() || '';
    const lastName = currentUser.lastName?.trim() || '';

    return firstName || lastName ? `${firstName} ${lastName}`.trim() : 'User';
  };

  render() {
    const {
      currentUser,
      showDashboard = false,
      t,
      locale,
      loginModal,
      isMobile,
      hideNavigation,
      displayUserName,
      noRedirection,
    } = this.props;
    const withHeader = isJobTrackEnable() && !isMobile;
    const { active } = this.state;
    return (
      <HeaderCont isJobTrack={withHeader}>
        <ContainerCont>
          <Flex alignItems="center">
            <LinkFlex withHeader={withHeader}>
              <Box withHeader={withHeader}>
                <Logo path={currentUser ? '/dashboard' : '/'} withHeader={withHeader} noRedirection={noRedirection} />
              </Box>
              {withHeader && !hideNavigation && (
                <SearchLinkWrap alignItems="center">
                  {JOBTRACK_NAV_LINK.map((item, k) => {
                    if (item === 'jobtrack_header_item2') return;
                    return (
                      <NavLink active={active === k} onClick={this.handleNavigate(k)} key={k}>
                        {t(item)}
                      </NavLink>
                    );
                  })}
                </SearchLinkWrap>
              )}
            </LinkFlex>
            {displayUserName && (
              <Box grow={1}>
                <NavCont>
                  <ItemContainer>
                    <StyledLink data-testid="dashboard-link">{this.getUserDisplayName()}</StyledLink>
                  </ItemContainer>
                </NavCont>
              </Box>
            )}
            {!hideNavigation && (
              <Box grow={1}>
                {currentUser ? (
                  <NavCont>
                    <LocaleWrapper>
                      <LocaleSelect />
                    </LocaleWrapper>
                    {this.showUpgrade() && (
                      <ItemContainer>
                        <Button upgrade hiddenSM hiddenXS onClick={this.goToCheckout}>
                          {t('header.upgrade')}
                        </Button>
                      </ItemContainer>
                    )}
                    {showDashboard && (
                      <NavLinks>
                        <ItemContainer onClick={this.handleClickDashboard}>
                          <StyledLink data-testid="dashboard-link">{t('header.dashboard')}</StyledLink>
                        </ItemContainer>
                      </NavLinks>
                    )}
                    <ItemContainer>
                      <UserDropdown isJobTrack={withHeader} />
                    </ItemContainer>
                  </NavCont>
                ) : (
                  <NavCont>
                    <HeaderLogin>
                      {loginModal ? (
                        <LoginLabel onClick={this.handleModal}>{t('landing.login')}</LoginLabel>
                      ) : (
                        <Link href="/login" passHref>
                          <div>{t('landing.login')}</div>
                        </Link>
                      )}
                    </HeaderLogin>
                  </NavCont>
                )}
              </Box>
            )}
          </Flex>
        </ContainerCont>
        {this.state.isOpenLogin && (
          <PopupContainer onClickOutside={this.handleClickOutside}>
            <LoginForm isPopup />
          </PopupContainer>
        )}
      </HeaderCont>
    );
  }
}

const LinkFlex = styled(Flex)`
  ${({ withHeader }) =>
    withHeader &&
    css`
      gap: 40px;
      align-items: center;
      justify-content: center;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      flex-direction: row-reverse;
    `}
`;

const SearchLinkWrap = styled(Flex)`
  gap: 24px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      flex-direction: row-reverse;
    `}
`;

const NavLink = styled(Flex)`
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 22px;
  color: var(--grayscale-n800);
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      color: #1688fe;
    `}
`;

const HeaderCont = styled.header`
  height: ${(p) => p.theme.general.headerHeight};
  background-color: white;
  > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ${({ theme }) => theme.max('md')`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: ${theme.zIndex.headerFixed};
    padding: 0 20px;
  `}
  ${({ isJobTrack }) =>
    isJobTrack &&
    css`
      border-bottom: 1px solid #e3e3e4;
    `}
`;

const HeaderLogin = styled.p`
  font-size: 16px;
  a {
    color: ${(p) => p.theme.colors.blueLighter};
    font-weight: 600;
    text-decoration: none;
  }
`;

const NavCont = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin: 0 -10px;
`;

const ContainerCont = styled(Container)`
  width: 100%;
  padding: 0 32px;
  > div {
    height: 100%;
    > div:nth-child(2) {
      height: 100%;
    }
    > div:first-child {
      z-index: 20;
      direction: ltr;
    }
  }
  ${(p) => p.theme.min('lg')`
    width: 100%;
  `}
  ${(p) => p.theme.max('md')`
    padding: 0;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const StyledLink = styled.a`
  text-decoration: none;
  z-index: 2;
  width: auto;
  border-radius: 23.5px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #f0f0f2;
  color: var(--black);
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.06em;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
`;

const NavLinks = styled.div`
  z-index: 20;
  ${({ theme }) => theme.max('sm')`
      display: none;
   `}
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 10px;
  height: 100%;
  cursor: pointer;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      @media (max-width: 425px) {
        margin-right: -10px;
      }
    `}
`;

const LocaleWrapper = styled.div`
  padding: 0 10px;
  ${({ theme }) => theme.max('sm')`
       margin-right: 0px;
   `}
`;

const LoginLabel = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: right;
  color: #282b32;
  cursor: pointer;
`;

const PopupContainer = styled.div`
  position: absolute;
  z-index: 3;
  right: 22px;
  padding: 29px 36px 36px;
  border-radius: 3px;
  box-shadow:
    0 15px 35px 0 rgba(50, 50, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.07);
  background-color: var(--light-values-white);
  width: 372px;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 22px;
    `}

  ${({ theme }) => theme.max('md')`
    width: 100%;
    right: 0;
 `}
`;
export default Header;
