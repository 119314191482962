import ChooseLanguageStep from '/imports/generator/ui/components/translateResume/ChooseLanguageStep';
import { Body, CTA, CrossIconWrapper, Wrapper } from '/imports/generator/ui/components/translateResume/Styles';
import ProcessTranslationStep from '/imports/generator/ui/components/translateResume/ProcessTranslationStep';
import TranslationFinished from '/imports/generator/ui/components/translateResume/TranslationFinishedStep';
import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import Modal from '/imports/core/ui/atoms/Modal';
import CloseModalIcon from '/imports/coaching/ui/assets/CloseModalIcon';
import { pullTranslatedResume, startTranslatingResume } from 'imports/generator/api/api';
import { cookieParser } from 'lib/helpers';
import PropTypes from 'prop-types';
import useTracking from 'imports/core/hooks/useTracking';
import intlHook from 'imports/core/api/useIntl';
import Spinner from '/imports/generator/ui/atoms/Spinner';
import nookies from 'nookies';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { Push } from 'components/Link';
import NewTranslation from './NewTranslation';

// Constants
const STORAGE_KEYS = {
  TASK_ID: 'translate-resume:task-id',
  NEW_RESUME_ID: 'translate-resume:new-resume-id',
  RESUME_ID: 'translate-resume:resume-id',
};

const MAX_RETRIES = 60;
const POLLING_INTERVAL = 5000;

// State reducer for translation process
const translationReducer = (state, action) => {
  switch (action.type) {
    case 'START_TRANSLATION':
      return { ...state, loading: true, finish: false, error: null };
    case 'TRANSLATION_COMPLETE':
      return { ...state, loading: false, finish: true, error: null, translatedResumeId: action.payload };
    case 'TRANSLATION_ERROR':
      return { ...state, loading: false, finish: false, error: action.payload };
    case 'RESET':
      return { loading: false, finish: false, error: null, translatedResumeId: null };
    default:
      return state;
  }
};

/**
 * Custom hook to manage translation process
 */
const useTranslationProcess = (resume) => {
  const [state, dispatch] = useReducer(translationReducer, {
    loading: false,
    finish: false,
    error: null,
    translatedResumeId: null,
  });

  const [retry, setRetry] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const intervalRef = useRef(null);

  const { token } = cookieParser();
  const { agent_client_id } = nookies.get({});

  // Clear interval on unmount or when no longer needed
  const clearPolling = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setRetry(0);
  }, []);

  // Poll for translation status
  const pollTranslationStatus = useCallback(
    (taskId) => {
      window?.localStorage.setItem(STORAGE_KEYS.RESUME_ID, resume.id);
      clearPolling();

      intervalRef.current = setInterval(() => {
        setRetry((prevRetry) => {
          if (prevRetry >= MAX_RETRIES) {
            clearPolling();
            window.localStorage.removeItem(STORAGE_KEYS.TASK_ID);
            dispatch({ type: 'TRANSLATION_ERROR', payload: 'Translation timed out. Please try again.' });
            return 0;
          }
          return prevRetry + 1;
        });

        pullTranslatedResume({ token, taskId, agent_client_id })
          .then((data) => {
            if (data?.status >= 400) {
              clearPolling();
              window.localStorage.removeItem(STORAGE_KEYS.TASK_ID);
              window.localStorage.removeItem(STORAGE_KEYS.NEW_RESUME_ID);
              dispatch({
                type: 'TRANSLATION_ERROR',
                payload: data?.message || 'An error occurred. Please try again.',
              });
              console.error('Translation error:', data?.message);
              return;
            }

            if (data?.resumeId) {
              clearPolling();
              window.localStorage.removeItem(STORAGE_KEYS.TASK_ID);
              window.localStorage.setItem(STORAGE_KEYS.NEW_RESUME_ID, data.resumeId);
              dispatch({ type: 'TRANSLATION_COMPLETE', payload: data.resumeId });
            }
          })
          .catch((error) => {
            clearPolling();
            window.localStorage.removeItem(STORAGE_KEYS.TASK_ID);
            dispatch({
              type: 'TRANSLATION_ERROR',
              payload: 'Network error. Please check your connection and try again.',
            });
            console.error('Translation fetch error:', error);
          });
      }, POLLING_INTERVAL);
    },
    [resume.id, token, agent_client_id, clearPolling],
  );

  // Start translation process
  const startTranslation = useCallback(async () => {
    if (state.loading || !selectedLanguage) return;

    dispatch({ type: 'START_TRANSLATION' });

    try {
      const response = await startTranslatingResume({
        resumeId: resume.id,
        sourceLanguage: resume.settings.language,
        destinationLanguage: selectedLanguage,
        agent_client_id,
        token,
      });

      if (!response?.taskId) {
        throw new Error('Failed to start translation');
      }

      window.localStorage.setItem(STORAGE_KEYS.TASK_ID, response.taskId);
      pollTranslationStatus(response.taskId);
    } catch (error) {
      console.error('Failed to start translation:', error);
      dispatch({
        type: 'TRANSLATION_ERROR',
        payload: 'Failed to start translation. Please try again.',
      });
    }
  }, [resume, selectedLanguage, state.loading, agent_client_id, token, pollTranslationStatus]);

  // Initialize from localStorage
  useEffect(() => {
    const initFromStorage = () => {
      clearPolling();

      // Check if we're working with the same resume
      const storedResumeId = window?.localStorage.getItem(STORAGE_KEYS.RESUME_ID);
      if (storedResumeId !== resume.id) {
        // Clear storage if we switched to a different resume
        Object.values(STORAGE_KEYS).forEach((key) => window.localStorage.removeItem(key));
        return;
      }

      // Check for completed translation
      const newResumeId = window?.localStorage.getItem(STORAGE_KEYS.NEW_RESUME_ID);
      if (newResumeId) {
        dispatch({ type: 'TRANSLATION_COMPLETE', payload: newResumeId });
        return;
      }

      // Check for in-progress translation
      const taskId = window?.localStorage.getItem(STORAGE_KEYS.TASK_ID);
      if (taskId) {
        dispatch({ type: 'START_TRANSLATION' });
        pollTranslationStatus(taskId);
      }
    };

    initFromStorage();

    return clearPolling;
  }, [resume.id, clearPolling, pollTranslationStatus]);

  return {
    state,
    retry,
    selectedLanguage,
    setSelectedLanguage,
    startTranslation,
    resetTranslation: () => dispatch({ type: 'RESET' }),
  };
};

/**
 * TranslateResumeCTA Component
 */
export default function TranslateResumeCTA({ resume, isNewDesign }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const { t, locale } = intlHook();
  const { trackEvent } = useTracking();
  const resumeTranslationState = useTranslationProcess(resume);
  const {
    state: translationState,
    retry,
    selectedLanguage,
    setSelectedLanguage,
    startTranslation,
    resetTranslation,
  } = resumeTranslationState;

  const handleLanguageChange = useCallback(
    (e) => {
      setSelectedLanguage(e.value);
    },
    [setSelectedLanguage],
  );

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleClose = useCallback(
    (returnWithCurrent = false) => {
      if (returnWithCurrent === true) {
        trackEvent('translate_finish_retun_with_current_click');
        window.localStorage.removeItem(STORAGE_KEYS.NEW_RESUME_ID);
        resetTranslation();
      }
      trackEvent('translate_modal_close');
      setModalOpen(false);
    },
    [trackEvent, resetTranslation],
  );

  const handleViewTranslatedResume = useCallback(() => {
    trackEvent('translate_finish_view_click');
    resetTranslation();
    setModalOpen(false);
    window.localStorage.removeItem(STORAGE_KEYS.NEW_RESUME_ID);
    Push(`/resume/${translationState.translatedResumeId}/finish`, locale, '', {}, '_blank');
  }, [trackEvent, translationState.translatedResumeId, locale, resetTranslation]);

  const handleTranslateCV = useCallback(() => {
    trackEvent('translate_resume_processing');
    startTranslation();
  }, [trackEvent, startTranslation]);

  // Button click handler with tracking
  const handleButtonClick = useCallback(() => {
    trackEvent('translate_modal_open', {
      exp_translate_resume_processing_behavior: marvelEmitter.getActiveVariant(
        'exp_translate_resume_processing_behavior',
      ),
      design_version: isNewDesign ? 'new' : 'classic',
    });
    openModal();
  }, [trackEvent, isNewDesign, openModal]);

  // Modal styles
  const modalStyles = {
    modalContainer: {
      display: 'flex',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(52, 60, 73, 0.30)',
      zIndex: 500,
    },
    modalBody: {
      width: '600px',
      height: translationState.finish ? '268px' : '350px',
      margin: 'auto',
      backgroundColor: 'var(--light-values-white)',
      borderRadius: '8px',
      flexGrow: 0,
      overflow: 'auto',
      transition: 'height 0.3s ease',
    },
  };

  // Render the appropriate CTA based on design version
  const renderCTA = () => {
    const buttonProps = {
      onClick: handleButtonClick,
      role: 'button',
      tabIndex: 0,
      'aria-label': t('translate_cv.translate_my_cv'),
      disabled: translationState.loading,
    };

    const buttonContent = translationState.finish ? (
      t('translate_cv.button_done')
    ) : translationState.loading ? (
      <>
        {t('translate_cv.progress_button')}
        <Spinner $width={'25px'} aria-hidden="true" />
      </>
    ) : (
      t('translate_cv.translate_my_cv')
    );

    return <CTA {...buttonProps}>{buttonContent}</CTA>;
  };

  if (isNewDesign) {
    return (
      <NewTranslation
        handleViewTranslatedResume={handleViewTranslatedResume}
        handleClose={handleClose}
        handleTranslateCV={handleTranslateCV}
        handleLanguageChange={handleLanguageChange}
        resume={resume}
        selectedLanguage={selectedLanguage}
        resumeTranslationState={resumeTranslationState}
      />
    );
  }

  return (
    <>
      {renderCTA()}
      <Modal
        onClose={handleClose}
        styles={modalStyles}
        animation="empty"
        openStateBase
        open={isModalOpen}
        timeout={0}
        aria-labelledby="translation-modal-title"
      >
        <Wrapper>
          <CrossIconWrapper onClick={handleClose}>
            <CloseModalIcon aria-label={t('common.close')} />
          </CrossIconWrapper>
          <Body>
            {translationState.error ? (
              <div role="alert" className="error-message">
                {translationState.error}
              </div>
            ) : (
              <>
                {translationState.finish ? (
                  <TranslationFinished
                    handleViewTranslatedResume={handleViewTranslatedResume}
                    handleClose={handleClose}
                  />
                ) : translationState.loading ? (
                  <ProcessTranslationStep closeModal={handleClose} processRefCount={retry} />
                ) : (
                  <ChooseLanguageStep
                    handleClose={handleClose}
                    handleTranslateCV={handleTranslateCV}
                    handleChange={handleLanguageChange}
                    resume={resume}
                    selectedLanguage={selectedLanguage}
                  />
                )}
              </>
            )}
          </Body>
        </Wrapper>
      </Modal>
    </>
  );
}

TranslateResumeCTA.propTypes = {
  resume: PropTypes.object.isRequired,
  isNewDesign: PropTypes.bool,
};

TranslateResumeCTA.defaultProps = {
  isNewDesign: false,
};
