import OnboardingListItem from './OnboardingListItem';

import useTracking from 'imports/core/hooks/useTracking';
import nookies from 'nookies';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { useAccount } from 'imports/core/api/accounts/accountContext';
import intlHook from 'imports/core/api/useIntl';
import Flex from 'imports/core/ui/atoms/Flex';
import { useResponsive } from '/imports/core/api/responsiveContext';
import { FAKE_TEMPLATES, FEATURED_TEMPLATES_MESSAGES, TEMPLATES } from '/imports/generator/api/constants';
import { randomizeTemplates } from '/imports/generator/api/helpers';
import useWindowSize from '/imports/core/api/useWindowSize';

const OnboardingList = ({ onSelect }) => {
  const [templates, setTemplates] = useState([]);
  const { currentUser } = useAccount();
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(true);
  const { locale } = intlHook();
  const { isMobile } = useResponsive();
  const { trackEvent } = useTracking();
  const { width } = useWindowSize();
  const activeBruneiExp = marvelEmitter.getActiveVariant('exp_new_template') === 'with_brunei';
  useEffect(() => {
    const _templates = shuffleTemplates();
    const templates = filterTemplatesByRole(_templates);
    let obj = {
      random_default_template: templates[0].name,
    };

    if (marvelEmitter.getActiveVariant('exp_new_template')) {
      obj.exp_new_template = marvelEmitter.getActiveVariant('exp_new_template');
    }

    if (marvelEmitter.getActiveVariant('exp_cali_lima')) {
      obj.exp_cali_lima = marvelEmitter.getActiveVariant('exp_cali_lima');
    }

    if (marvelEmitter.getActiveVariant('exp_ats_preview_v2')) {
      obj.exp_ats_preview_v2 = marvelEmitter.getActiveVariant('exp_ats_preview_v2');
    }

    obj.exp_select_cv_newdesignV2 = marvelEmitter.getActiveVariant('exp_select_cv_newdesignV2');
    obj.exp = marvelEmitter.getActiveVariant('job-tracking');
    obj.exp_template_budapest = marvelEmitter.getActiveVariant('exp_template_budapest');
    switch (locale) {
      case 'nl':
        obj.exp_template_english_vs_dutch = marvelEmitter.getActiveVariant('exp_template_english_vs_dutch');
        break;
      case 'it':
        obj.exp_template_english_vs_italian = marvelEmitter.getActiveVariant('exp_template_english_vs_italian');
        break;
      case 'zh-cn':
        obj.exp_template_english_vs_chinese = marvelEmitter.getActiveVariant('exp_template_english_vs_chinese');
        break;
      case 'da':
        obj.exp_template_english_vs_danish = marvelEmitter.getActiveVariant('exp_template_english_vs_danish');
        break;
      case 'et':
        obj.exp_template_english_vs_estonian = marvelEmitter.getActiveVariant('exp_template_english_vs_estonian');
        break;
      case 'sl':
        obj.exp_template_english_vs_slovenian = marvelEmitter.getActiveVariant('exp_template_english_vs_slovenian');
        break;
      case 'fi':
        obj.exp_template_english_vs_finnish = marvelEmitter.getActiveVariant('exp_template_english_vs_finnish');
        break;
    }
    let _temp = templates;
    ['budapest', 'chicago', 'rotterdam'].forEach((city, index) => {
      const cityIndex = _temp.findIndex((t) => t?.name.toLowerCase() === city);
      _temp = moveElement(_temp, cityIndex, index);
    });

    trackEvent('template_view', obj);

    setTemplates(_temp);
    setLoading(false);
  }, [currentUser]);

  const filterTemplatesByRole = (templates) => {
    const { agent_client_id } = nookies.get({});
    const isAgentOrAdmin = currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin');
    if ((agent_client_id !== undefined && agent_client_id !== null) || isAgentOrAdmin) {
      return templates;
    } else {
      return templates.filter(
        (template) => (template.id !== 'atsresume' || template.name === 'Tokyo') && template.id !== 'budapest-v2',
      );
    }
  };

  const shuffleTemplates = () => {
    const visibleTemplates = TEMPLATES.filter((template) => !template.hidden);
    const featured_experiment_templates = ['budapest', 'chicago', 'rotterdam'];

    return randomizeTemplates(
      visibleTemplates,
      FAKE_TEMPLATES,
      featured_experiment_templates.slice(0, 2),
      featured_experiment_templates.slice(2),
    ).filter((template) => {
      if (typeof template.hiddenOnSlider == 'function') {
        return !template.hiddenOnSlider({ locale, activeBruneiExp, isMobile, currentUser });
      }
      return !template.hiddenOnSlider;
    });
  };

  const moveElement = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);

    return arr;
  };

  const onTemplateSelect = (index) => {
    setCurrent(index);
  };

  const featured_experiment_templates = ['budapest', 'chicago', 'rotterdam'];
  if (loading) return null;
  return (
    <GridWrapper alignItems="center" justifyContent="center">
      {templates.map((template, i) => {
        if (locale !== 'en' && template.name === 'Tokyo') return;
        const featured = !template.hideLabel && featured_experiment_templates.includes(template.id);
        let message = '';
        if (featured) {
          switch (i) {
            case 0:
              message = FEATURED_TEMPLATES_MESSAGES[0];
              break;

            case 1:
              message = FEATURED_TEMPLATES_MESSAGES[1];
              break;

            case 2:
              message = FEATURED_TEMPLATES_MESSAGES[2];
              break;

            default:
              break;
          }
        }
        return (
          <GridItem onMouseOver={() => onTemplateSelect(i)} key={`${template.image || template.id}`}>
            <OnboardingListItem
              selected={i === current}
              {...template}
              featured={featured}
              message={message}
              onSelect={onSelect}
              onTemplateSelect={() => onTemplateSelect(i)}
            />
          </GridItem>
        );
      })}
    </GridWrapper>
  );
};

export default OnboardingList;

const GridWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 32px;
`;

const GridItem = styled.div``;
