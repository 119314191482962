import React from 'react';

const DownArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" class=" NMm5M hhikbc">
      <path d="M16.41 5.41L15 4l-8 8 8 8 1.41-1.41L9.83 12"></path>
    </svg>
  );
};

export default DownArrow;
