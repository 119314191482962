import Flex from 'imports/core/ui/atoms/Flex';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import RoundedButton from '/imports/job-tracking/ui/atoms/RoundedButton';
import JobCompanyIcon from '/imports/job-tracking/ui/assets/JobCompanyIcon';
import MoneyIcon from '/imports/job-tracking/ui/assets/MoneyIcon';
import { useSearchV2Store } from '/zustand/SearchV2Store';
import styled, { css } from 'styled-components';

const SearchV2DetailSection = () => {
  const { activeJob } = useSearchV2Store();
  const scrollElement = useRef(null);
  const [scrollHeight, setScrollHeight] = useState(0);

  const onScroll = useCallback(() => {
    const scrollEl = scrollElement.current?.getBoundingClientRect();
    setScrollHeight(scrollEl?.y + 20);
  }, [scrollElement]);

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [onScroll]);

  const handleRedirect = () => {
    const url = `${activeJob?.url}&redirectionURL=${window.location.href}`;
    window.open(url, '_blank');
  };

  return (
    <RightWrapper direction="column" $fullWidth ref={scrollElement} scrollHeight={scrollHeight}>
      <RightPageLayout direction="column" $fullWidth>
        <Flex direction="column" $fullWidth>
          <CompanyDetails $fullWidth direction="column">
            <JobDescDiv direction="column">
              <JobTitle>{activeJob.title}</JobTitle>
              <JobInfo>
                {activeJob.company}, {activeJob.city} ({activeJob.remote ? 'Remote' : 'On Site'})
              </JobInfo>
              <ChipContainer $fullWidth>
                {activeJob.salaray && (
                  <Chip>
                    <MoneyIcon /> {activeJob.salaray}
                  </Chip>
                )}
                {activeJob.companyInfo?.portal && <Chip>{activeJob.companyInfo?.portal}</Chip>}
                {activeJob.contractCategory && (
                  <Chip>
                    <JobCompanyIcon /> {activeJob.contractCategory}
                  </Chip>
                )}
              </ChipContainer>
            </JobDescDiv>
            <StyledRoundedButton onClick={handleRedirect}>Apply</StyledRoundedButton>
          </CompanyDetails>
          <JobDescription dangerouslySetInnerHTML={{ __html: activeJob.metadata?.summary }} />
        </Flex>
      </RightPageLayout>
    </RightWrapper>
  );
};

export default SearchV2DetailSection;

const RightWrapper = styled(Flex)`
  background-color: #fff;
  border: 1px solid var(--grayscale-n300);
  border-radius: 8px;
  display: flex;
  top: 16px;
  position: sticky;
  flex-grow: 1;
  border-top-right-radius: 12px;
  overflow: hidden;
  height: fit-content;
  max-height: ${({ scrollHeight }) => `calc(100vh - ${scrollHeight}px)`};
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const JobTitle = styled.h2`
  font-size: 24px;
  color: #14141f;
  margin: 0;
`;

const JobInfo = styled.p`
  color: #14141f;
  margin: 0;
`;

const JobDescription = styled.div`
  color: #4c4c55;
  padding: 12px 24px;
`;

const JobDescDiv = styled(Flex)`
  gap: 4px;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 100%;
  margin-top: 12px;
  @media (min-width: 768px) {
    width: fit-content;
  }
`;

const CompanyDetails = styled(Flex)`
  padding: 12px 24px;
  border-bottom: 1px solid var(--grayscale-n300);
`;

const RightPageLayout = styled(Flex)`
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 31, 0.12);
    border-radius: 2px;
  }
`;

const ChipContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 8px;
  ${({ $withMarginBottom }) =>
    $withMarginBottom &&
    css`
      margin-bottom: 4px;
    `}
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  background: #ececed;
  border-radius: 4px;
`;
