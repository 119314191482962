import PreviewMobileControls from 'imports/generator/ui/components/PreviewMobileControls';
import { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';

import { useAccount } from 'imports/core/api/accounts/accountContext';
import nookies from 'nookies';
import ButtonAtom from '/imports/core/ui/atoms/Button';
import { PreviewSliderIcon } from '/imports/generator/ui/assets';
import ControlsModal from '/imports/generator/ui/components/ControlsModal';

const SlidersMobileButton = (props) => {
  const {
    source: {
      settings: { template, templateVersion },
    },
  } = props;

  const { currentUser } = useAccount();
  const { agent_client_id } = nookies.get({});
  const [modalOpened, setModalOpened] = useState(false);
  const isAgentOrAdmin =
    (currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin')) ||
    (agent_client_id !== undefined && agent_client_id !== null);
  const SABudapestHide = template === 'budapest' && templateVersion === '3' && !isAgentOrAdmin;

  const toggleModal = () => {
    if (SABudapestHide) return;
    setModalOpened(!modalOpened);
  };

  return (
    <Fragment>
      <SlidersButton onClick={toggleModal} SABudapestHide={SABudapestHide}>
        <PreviewSliderIcon />
      </SlidersButton>
      <ControlsModal {...props} openStateBase open={modalOpened} onClose={toggleModal} noCancel>
        <PreviewMobileControls {...props} />
      </ControlsModal>
    </Fragment>
  );
};

export default SlidersMobileButton;

const Button = styled(ButtonAtom)`
  position: relative;
  top: 2px;
  color: ${(p) => p.theme.colors.gray.light} !important;
  ${({ theme }) => theme.max('sm')`
    z-index: 99;
  `}
`;

const SlidersButton = styled((p) => <Button unstyled {...p} />)`
  color: white !important;
  font-size: 26px;
  position: absolute;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%);
  left: calc(${(p) => p.theme.general.gridGap} * 2);

  ${({ theme: { max, isRTL, general } }) => max('md')`
    left: ${general.mobilePadding};
    display: flex;
    ${
      isRTL &&
      css`
        left: unset;
        right: ${general.mobilePadding};
      `
    }
  `}
  ${({ SABudapestHide }) =>
    SABudapestHide &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;
