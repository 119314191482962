const JobCompanyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clipPath="url(#clip0_4324_35746)">
      <path
        d="M4.375 3.5V1.875C4.375 1.32272 4.82272 0.875 5.375 0.875H8.625C9.17728 0.875 9.625 1.32272 9.625 1.875V3.5"
        stroke="#14141F"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.54036 9.91667H0.582031V4.08333C0.582031 3.76104 0.843073 3.5 1.16536 3.5H12.832C13.1543 3.5 13.4154 3.76104 13.4154 4.08333V9.91667H8.45703"
        stroke="#14141F"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M13.125 11.375V12.7361C13.125 12.951 12.8509 13.125 12.5125 13.125H1.4875C1.14909 13.125 0.875 12.951 0.875 12.7361V11.375"
        stroke="#14141F"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7.45573 8.75H6.53906C5.98678 8.75 5.53906 9.19772 5.53906 9.75V10.0833C5.53906 10.6356 5.98678 11.0833 6.53906 11.0833H7.45573C8.00801 11.0833 8.45573 10.6356 8.45573 10.0833V9.75C8.45573 9.19772 8.00801 8.75 7.45573 8.75Z"
        stroke="#14141F"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_4324_35746">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default JobCompanyIcon;
