import styled, { css } from 'styled-components';

const RoundedButton = styled.button`
  border: none;
  height: 40px;
  padding: 0 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background-color: #1688fe;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--light-values-white)', '1.47')};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0072e8;
  }
  &:active {
    background-color: #0062c7;
  }
  &:disabled {
    background-color: var(--grayscale-n300);
    pointer-events: none;
    color: var(--grayscale-n100);
  }
  ${({ small }) =>
    small &&
    css`
      padding: 0 16px;
      border-radius: 28px;
      ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--light-values-white)', '1.54')};
      height: 32px;
    `}
  ${({ styled }) =>
    styled &&
    css`
      background-color: var(--light-values-white);
      color: #4f5e75;
      &:hover {
        box-shadow: 0 8px 24px 0 rgba(29, 44, 67, 0.12);
        color: #1d2c43;
        background-color: var(--light-values-white);
      }
      &:disabled {
        color: #8c98aa;
        background-color: var(--light-values-white);
        pointer-events: none;
      }
    `}
  ${({ secondary }) =>
    secondary &&
    css`
      background-color: var(--light-values-light-extra);
      color: var(--dark-values-dark-regular);
      &:hover {
        color: var(--dark-values-black);
        background-color: var(--light-values-light-medium);
      }
      &:disabled {
        color: var(--mid-values-gray-light);
        background-color: var(--light-values-light-extra);
        pointer-events: none;
      }
    `}
  ${({ borderOnlyBtn }) =>
    borderOnlyBtn &&
    css`
      color: #1688fe;
      border: 1px solid #1688fe;
      background-color: var(--light-values-white);
      &:hover {
        background-color: var(--chip-blue);
        border-color: var(--primary-default-b400);
      }
    `}
  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
      padding: 0 20px;
      svg {
        margin-right: 10px;
      }
    `}

  ${({ updateFeed }) =>
    updateFeed &&
    css`
      padding: 16px;
      width: 93px;
      height: 40px;
      justify-content: center;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.47;
      flex-grow: 0;
      text-align: center;
      &:active {
        background-color: var(--additional-colors-yellow);
      }
    `}
  ${({ padded }) =>
    padded &&
    css`
      padding: 9px 56px;
    `}
`;

export default RoundedButton;
