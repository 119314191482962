import React from 'react';

const UserTrade = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <g clipPath="url(#clip0_21952_28059)">
        <path
          d="M11.5085 10.8447H1.33458V0.698539C1.33458 0.362365 1.06206 0.0898438 0.725883 0.0898438C0.389709 0.0898437 0.117188 0.362365 0.117188 0.698539V11.4534C0.117188 11.7896 0.389709 12.0621 0.725883 12.0621H11.5085C11.8447 12.0621 12.1172 11.7896 12.1172 11.4534C12.1172 11.1172 11.8447 10.8447 11.5085 10.8447Z"
          fill="#7C7C7C"
        />
        <path
          d="M2.74134 8.24825C2.9061 8.24825 3.07031 8.18175 3.19038 8.05069L4.88582 6.19949L7.91848 7.53142C8.17935 7.64599 8.48492 7.56425 8.65368 7.33462L10.5141 4.80373L10.6704 5.89316C10.714 6.19664 10.9743 6.41556 11.2722 6.41552C11.301 6.41552 11.3301 6.41347 11.3594 6.40926C11.6921 6.36154 11.9232 6.05309 11.8755 5.72032L11.5059 3.14342C11.4581 2.81065 11.1496 2.5797 10.8169 2.62731L8.24005 2.99691C7.90728 3.04464 7.67619 3.35309 7.72395 3.68585C7.77167 4.01862 8.08015 4.24971 8.41288 4.20196L9.56732 4.03639L7.96165 6.22071L4.97619 4.9095C4.73755 4.8047 4.45862 4.86349 4.28255 5.0557L2.29264 7.22839C2.06558 7.47629 2.08248 7.86133 2.33038 8.08839C2.44721 8.19542 2.59452 8.24825 2.74134 8.24825Z"
          fill="#7C7C7C"
        />
      </g>
      <defs>
        <clipPath id="clip0_21952_28059">
          <rect width="12" height="12" fill="white" transform="translate(0.117188 0.0742188)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserTrade;
