import React from 'react';
import styled from 'styled-components';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import AtomButton from '/imports/core/ui/atoms/Button';
import intlHook from 'imports/core/api/useIntl';
import useExperienceReviewStore from 'zustand/experienceReviewStore';
import nookies from 'nookies';
import env from '/env';

const Error = ({ error }) => {
  const { saLink, builderUserId, questionnaireType, referrer } = useExperienceReviewStore();
  const { t, locale } = intlHook();
  const cookieData = nookies.get({});
  const uniqueKey = cookieData[`Indicative_${env.INDICATIVE_KEY}`];
  const eventId = uniqueKey && JSON.parse(uniqueKey);
  const indicative_unique_id = cookieData?.indicative_unique_id;
  const indicative_unique_id_two = eventId && eventId?.defaultUniqueID ? eventId?.defaultUniqueID : null;
  const final_unique_id = indicative_unique_id || indicative_unique_id_two || '';

  const redirect = () => {
    const baseSaLink = saLink
      ? `${saLink}${questionnaireType === 'extraQuiz' ? '&fromAutoOnboardingExtraQuiz=true' : ''}`
      : `https://successagency.io/${locale}/start?builderId=${builderUserId}`;
    const finalUrl = `${baseSaLink}${baseSaLink.includes('?') ? '&' : '?'}indicative_id=${final_unique_id}&referrer=${referrer}`;
    window.location.href = finalUrl;
  };
  return (
    <Wrapper>
      <StyledMessage>{t('auto_onboarding.job_review_already_completed')}</StyledMessage>
      <Button onClick={redirect}>{t('auto_onboarding.go_to_quiz')}</Button>
    </Wrapper>
  );
};

Error.displayName = 'Error';

const Button = styled(AtomButton)`
  padding-bottom: 0.5em;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
`;
const StyledMessage = styled(WizardHeader)`
  text-align: center;
`;

export default Error;
