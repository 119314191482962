import React, { useState, useRef, useEffect } from 'react';
import Container from '../atmos/Container';
import Flex from 'imports/core/ui/atoms/Flex';
import { cdnFile } from 'lib/helpers';
import { content_landing_slugs } from 'imports/homepageV2/api/constants';
import styled, { css } from 'styled-components';

import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useWindowSize from '/imports/core/api/useWindowSize';

const LandingPageCollapse = ({ onClickLandingCTA, userIdentified, isNewMobileDesign = false }) => {
  const { width } = useWindowSize();
  const { t } = useIntl();
  const { host } = useResponsive();
  const slug = content_landing_slugs(host, t);
  const [expanable, setExpandable] = useState(null);
  const [backgroundHeight, setBackgroundHeight] = useState(430);
  const backgroundRef = useRef(null);

  useEffect(() => {
    const backgroundElement = backgroundRef.current;
    const computedStyle = getComputedStyle(backgroundElement);
    const imageUrl = computedStyle.backgroundImage.slice(4, -1).replace(/"/g, '');
    const img = new Image();
    img.src = imageUrl;

    img.addEventListener('load', () => {
      const imageAspectRatio = img.height / img.width;
      const newHeight = imageAspectRatio * (width - 32); //minus 32 (with padding)
      setBackgroundHeight(newHeight);
    });
  }, [width]);
  const element = [
    {
      icon: '🚀',
      title: slug['resume_nation_landing_s5_p1'],
      content: slug['resume_nation_landing_s5_p2'],
    },
    {
      icon: '🛠',
      title: slug['resume_nation_landing_s5_p3'],
      content: slug['resume_nation_landing_s5_p4'],
    },
    {
      icon: '🔍',
      title: slug['resume_nation_landing_s5_p5'],
      content: slug['resume_nation_landing_s5_p6'],
    },
    {
      icon: '🏆',
      title: slug['resume_nation_landing_s5_p7'],
      content: slug['resume_nation_landing_s5_p8'],
    },
    {
      icon: '⏱',
      title: slug['resume_nation_landing_s5_p9'],
      content: slug['resume_nation_landing_s5_p10'],
    },
    {
      icon: '🧩',
      title: slug['resume_nation_landing_s5_p11'],
      content: slug['resume_nation_landing_s5_p12'],
    },
    {
      icon: '☑️',
      title: slug['resume_nation_landing_s5_p13'],
      content: slug['resume_nation_landing_s5_p14'],
    },
    {
      icon: '📋',
      title: slug['resume_nation_landing_s5_p15'],
      content: slug['resume_nation_landing_s5_p16'],
    },
    {
      icon: '💬',
      title: slug['resume_nation_landing_s5_p17'],
      content: slug['resume_nation_landing_s5_p18'],
    },
    {
      icon: '🔐',
      title: slug['resume_nation_landing_s5_p19'],
      content: slug['resume_nation_landing_s5_p20'],
    },
    {
      icon: '✨',
      title: slug['resume_nation_landing_s5_p21'],
      content: slug['resume_nation_landing_s5_p22'],
    },
  ];

  const handleExpandable = (i) => () => {
    setExpandable(!!expanable ? (expanable === i + 1 ? null : i + 1) : i + 1);
  };

  return (
    <Wrapper $fullWidth $direction="column">
      <StyledContainer $isNewMobileDesign={isNewMobileDesign}>
        <Title
          dangerouslySetInnerHTML={{ __html: slug['resume_nation_landing_s4_p1'] }}
          $isNewMobileDesign={isNewMobileDesign}
        />
        <SubTitle
          dangerouslySetInnerHTML={{ __html: slug['resume_nation_landing_s4_p2'] }}
          $isNewMobileDesign={isNewMobileDesign}
        />
        <LeftSection>
          <Collapse $direction="column" role="list">
            {element.map((el, i) => (
              <CollapseButton
                key={i}
                onClick={handleExpandable(i)}
                $isNewMobileDesign={isNewMobileDesign}
                role="listitem"
              >
                <StyledFlex $alignItems="center">
                  <IconWrapper $alignItems="center" $justifyContent="center" role="img">
                    {el.icon}
                  </IconWrapper>
                  <Content $show={expanable === i + 1} $isNewMobileDesign={isNewMobileDesign}>
                    {el.title}
                  </Content>
                </StyledFlex>
                <Description $direction="column" $show={expanable === i + 1}>
                  <CollapseText $isNewMobileDesign={isNewMobileDesign} id={`accordion-content-${i}`}>
                    {el.content}
                  </CollapseText>
                  <CreateMyCv
                    onClick={onClickLandingCTA}
                    $isNewMobileDesign={isNewMobileDesign}
                    aria-label={!userIdentified ? t('landing.cta') : t('landing.my_resumes')}
                    aria-describedby={`accordion-content-${i}`}
                    role="link"
                  >
                    {!userIdentified ? t('landing.cta') : t('landing.my_resumes')}
                  </CreateMyCv>
                </Description>
              </CollapseButton>
            ))}
          </Collapse>
        </LeftSection>
        <RightSection
          $isNewMobileDesign={isNewMobileDesign}
          ref={backgroundRef}
          $height={backgroundHeight}
          role="img"
          alt="resume builder image"
        />
      </StyledContainer>
    </Wrapper>
  );
};

export default LandingPageCollapse;

const StyledContainer = styled(Container)`
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px 40px;
  ${({ theme }) => theme.max('md')`
    grid-template-columns: 1fr;
    grid-gap: 32px;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      grid-gap: 16px !important;
      padding: 0 16px !important;
    `}
`;

const CreateMyCv = styled.a`
  display: flex;
  padding: 6px 16px;
  border-radius: 20px;
  background-color: var(--primary);
  border: none;
  color: var(--light-values-white);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 13px;
  font-weight: 600;
  line-height: 1.54;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    background-color: var(--additional-colors-pink-old);
  }
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      height: 32px;
      padding: 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 28px;
      background: #ff6f5b;
    `}
`;

const StyledFlex = styled(Flex)`
  gap: 16px;
`;

const CollapseText = styled.p`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  color: var(--text);
  text-align: left;
  margin: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
    `}
  ${({ theme }) => theme.max('md')`
    font-size: 13px;
    line-height: 1.54;
    color: var(--dark-values-dark-regular-v1); 
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #4c4c55 !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 22px !important;
    `}
`;

const Description = styled(Flex)`
  display: none;
  padding: 0 20px 8px 48px;
  ${({ $show }) =>
    $show &&
    css`
      display: flex;
    `}
  ${({ theme }) => theme.max('md')`
    padding: ${theme.isRTL ? ' 0 48px 8px 20px' : ' 0 20px 8px 48px'}
  `}
`;

const Content = styled.h3`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  color: var(--text);
  text-align: left;
  margin: 0;
  ${({ $show }) =>
    $show &&
    css`
      color: var(--primary);
    `}
  ${({ theme }) => theme.max('md')`
      font-size: 15px;
      line-height: 1.47;
      text-align: ${theme.isRTL ? 'right' : 'left'};
    `}
  ${({ $isNewMobileDesign, $show }) =>
    $isNewMobileDesign &&
    css`
      color: ${$show ? '#FF6F5B' : '#14141f'};
      font-family: Gilroy Bold;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: ${$show ? '20px' : '24px'} !important;
    `}
`;

const IconWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 4px 0;
  background-color: var(--light-values-light-extra-v1);
`;

const CollapseButton = styled.button`
  width: 100%;
  border: none;
  gap: 1px;
  padding: 20px 0;
  background-color: var(--light-values-white);
  display: flex;
  gap: 16px;
  cursor: pointer;
  flex-direction: column;
  ${({ theme }) => theme.max('md')`
    padding: 16p$x 0;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      gap: 12px;
    `}
`;

const Collapse = styled(Flex)`
  background-color: var(--light-values-light-medium-v1);
  gap: 1px;
  width: 100%;
`;

const Wrapper = styled(Flex)`
  margin-top: 120px;
  margin-bottom: 80px;
  ${({ theme }) => theme.max('sm')`
    margin-top: 0;
    margin-bottom: 0;
  `}
  ${({ theme }) => theme.max('md')`
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

const LeftSection = styled(Flex)`
  background-color: var(--light-values-light-medium-v1);
  margin-right: 40px;
  gap: 1px;
  padding: 1px 0;
  height: fit-content;
  ${({ theme }) => theme.max('md')`
    margin: 0;
  `}
`;

const RightSection = styled(Flex)`
  background-image: url(${cdnFile('resume-nation-landing/img-standout.jpg')});
  display: flex;
  width: 620px;
  height: 620px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  box-shadow: 0 8px 24px 0 var(--border-transparent);
  border: solid 1px var(--border-transparent);
  background-repeat: no-repeat;

  ${({ theme }) => theme.max('md')`
    width: calc(100% + 60px);
    height: 0;
    padding-top: 100%;
    margin-left: ${theme.isRTL ? '0' : '-20px'};
    margin-right: ${theme.isRTL ? '-20px' : '0'};
    border-radius: 0;
    border: none;
    grid-row: 1;
  `}
  @media (min-width: 700px) and (max-width: 980px) {
    width: calc(100% + 70px) !important;
  }
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 0 !important;
      margin: 32px auto 16px !important;
      width: 100% !important;
      background-size: contain;
      height: ${(p) => p.height}px !important;
      box-shadow: none;
      border: 1px solid #e3e3e4 !important;
      border-radius: 4px !important;
    `}
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  flex: 1;
  letter-spacing: -0.25px;
  color: var(--text);
  span {
    color: var(--primary);
  }
  ${({ theme }) => theme.max('md')`
      font-size: 34px;
      line-height: 1.29;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #14141f;
      font-size: 32px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 44px !important;
    `}
`;

const SubTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 19px;
  font-weight: 500;
  line-height: 1.47;
  flex: 1;
  color: var(--text);
  padding-left: 40px;
  margin-top: 11px;
  span {
    font-weight: bold;
  }
  ${({ theme }) => theme.max('md')`
    padding: 0;
    margin-top: -16px;
    font-size: 15px;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      margin: 0 !important;
      color: #14141f;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 22px !important;
      span {
        font-family: Gilroy Bold;
      }
    `}
`;
