import React from 'react';
import Flex from 'imports/core/ui/atoms/Flex';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ButtonAtom from '/imports/core/ui/atoms/Button';
import Modal from '/imports/core/ui/atoms/Modal';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { useGeneralDesign } from '/imports/core/api/generalContext';
import { WIZARD_MODAL_DATA } from '/imports/generator/api/constants';
import useIntl from '/imports/core/api/useIntl';

const modalStyles = {
  modalContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    padding: '16px',
  },
  modalBackdrop: {
    backgroundColor: 'rgba(52, 60, 73, 0.5)',
  },
  modalBody: {
    maxWidth: '460px',
    background: '#fff',
    margin: 'auto',
    width: '100%',
    height: 'auto',
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '35px',
    flexGrow: '0',
    borderRadius: '4px',
  },
};

const WizardModal = (props) => {
  const { onClose, onAction, step, num } = props;
  const { t, locale } = useIntl();
  const onlyMobile = useGeneralDesign();
  const _step = num === 1 ? `${step}One` : step;
  const message = WIZARD_MODAL_DATA[_step] && t(WIZARD_MODAL_DATA[_step].messageSlug);
  const actionText = (WIZARD_MODAL_DATA[_step] && t(WIZARD_MODAL_DATA[_step].actionSlug)) || false;
  const header = t('generator.dialog.title');
  if (onlyMobile === 'xs') modalStyles.modalBody.width = '90%';
  const isLarge = locale === 'fr' && ['summary', 'experience'].includes(step);
  return (
    <Modal onClose={onClose} fullScreen styles={modalStyles}>
      <WizardPopupHeader>
        <IconContainer>
          <SvgIcon.Alert viewBox="0 0 18 18" />
        </IconContainer>
        {header}
      </WizardPopupHeader>
      <Message>{message}</Message>
      <WizardPopupButtons isLarge={isLarge}>
        {actionText && (
          <ActionButton onClick={onAction} aria-label={actionText}>
            {actionText}
          </ActionButton>
        )}
        <Button onClick={onClose} aria-label={t('generator.ok')}>
          {t('generator.ok')}
        </Button>
      </WizardPopupButtons>
    </Modal>
  );
};

WizardModal.propTypes = {
  num: PropTypes.number,
  step: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
};

const V2Button = styled.button`
  padding: 0.7em 1.3em;
  border-radius: 25px;
  background-color: #1688fe;
  border: none;
  cursor: pointer;
  height: auto;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 1.47;
  color: var(--light-values-white);
  margin-left: 0px;
  &:hover {
    background-color: #016fe0;
  }
`;

const V2ActionButton = styled(V2Button)`
  background-color: #f6f6f6;
  border: none;
  color: var(--dark-values-dark-regular);
  margin-right: 8px;

  &:hover {
    box-shadow: 0 8px 24px 0 var(--border-transparent);
    background-color: #ececed;
    color: var(--text);
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: 8px;
    `}
`;

const V2Footer = styled(Flex)`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 20px 35px;
  background-color: #ffffff;
  ${(p) => p.theme.max('xs')`
  flex-direction: column;
  align-items: stretch;
  padding: 10px 25px;
`}
  ${({ isLarge }) =>
    isLarge &&
    css`
      flex-direction: column;
      button {
        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    `}
${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Wrapper = styled(Flex)`
  gap: 1px;
  background-color: var(--light-values-light-medium-v1);
`;

const V2Message = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 1.47;
  color: var(--text);
  margin-top: 16px;
`;

const V2Header = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 22px;
  line-height: 1.36;
  color: var(--text);
`;

const V2Body = styled(Flex)`
  padding: 32px;
  background-color: var(--light-values-white);
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const WizardPopupHeader = styled.h2`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 28px;
  margin: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #429ff0;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 24px;
  color: var(--light-values-white);

  ${(p) => p.theme.max('xs')`
    font-size: 18px;
    padding: 12px 20px 8px;
    background-color: #1688fe;
    position: relative;
    @media (max-width: 320px) {
      font-size: 16px;
    }
  `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Message = styled.p`
  padding: 35px;
  margin: 0;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #7171a6;

  ${(p) => p.theme.max('xs')`
    font-size: 14px;
    padding: 25px;
    font-size: 16px;
    @media (max-width: 320px) {
      font-size: 14px;
    }
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const WizardPopupButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 10px 35px;

  ${(p) => p.theme.max('xs')`
    flex-direction: column;
    align-items: stretch;
    padding: 10px 25px;
  `}
  ${({ isLarge }) =>
    isLarge &&
    css`
      flex-direction: column;
      button {
        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Button = styled((p) => <ButtonAtom {...p} />)`
  height: auto;
  background-color: #1688fe;
  border: none;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  padding: 0.7em 1.3em;

  ${({ theme }) => theme.max('xs')`
    font-size: 12px;
    font-size: 16px;
    @media (max-width: 320px) {
      font-size: 14px;
    }
  `}

  &:hover {
    background-color: #0b78e8;
  }

  &:focus {
    background-color: #1275db;
  }
`;

const ActionButton = styled((p) => <Button {...p} />)`
  margin-right: 12px;
  border-radius: 3px;
  border: solid 2px #e6e6ff;
  background-color: #fff;
  color: #7171a6;

  &:hover {
    background-color: #fff;
    border-color: rgb(194, 194, 232);
  }

  ${(p) => p.theme.max('xs')`
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0;
    font-size: 16px;
    @media (max-width: 320px) {
      font-size: 14px;
    }
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 12px;
    `}
`;

const IconContainer = styled.div`
  padding-top: 10px;
  margin-right: 4px;
  ${({ theme }) =>
    theme.max('xs')`
    padding-top: 8px;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 4px;
    `}
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #add9ff;
  ${({ theme }) =>
    theme.max('xs')`
    top: 0;
    bottom: -5px;
    margin: auto 0;
    svg {
      margin-top: 3px;
    }
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      left: 17px;
    `}
`;

export default WizardModal;
