import React from 'react';

const MainLogo = ({ dark = false }) => {
  return (
    <svg width="144" height="24" viewBox="0 0 144 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0244 11.3063L14.8777 0.695639C14.3404 -0.231881 12.9971 -0.231879 12.4598 0.695641L0.219352 21.8257C-0.317952 22.7532 0.35368 23.9126 1.42829 23.9126H2.53455C10.6628 23.2065 17.5723 18.2606 21.0244 11.3063Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M25.9092 23.9126H24.5859C23.3202 23.8021 22.0601 23.586 20.8191 23.2639C19.2365 22.853 17.7113 22.2763 16.2672 21.5486C18.8585 19.5524 21.0578 17.0751 22.7301 14.2509L27.1182 21.8257C27.6555 22.7532 26.9838 23.9126 25.9092 23.9126Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M5.27379 13.1357L0.189105 21.9131C-0.348199 22.8406 0.323434 24 1.39804 24H2.5043C6.34132 23.6667 9.90675 22.3886 12.9651 20.4004C9.92515 18.5331 7.30379 16.0542 5.27379 13.1357Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M36.2433 18.7825L41.5056 4.22506H44.827L50.2494 18.7825H48.2285L43.3264 5.50133L44.2868 5.97993H41.9257L42.9462 5.50133L38.2041 18.7825H36.2433ZM39.3846 14.4352L40.0449 12.6803H46.3477L47.008 14.4352H39.3846Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M56.232 19.1016C54.9648 19.1016 53.991 18.6894 53.3107 17.8652C52.6304 17.0409 52.2903 15.8511 52.2903 14.2956V8.11369H54.2111V14.6745C54.2111 15.5121 54.4379 16.1701 54.8914 16.6487C55.3449 17.114 55.9585 17.3467 56.7322 17.3467C57.5326 17.3467 58.1862 17.0941 58.6931 16.5889C59.2 16.0704 59.4534 15.3658 59.4534 14.4751V8.11369H61.3742V18.7825H59.8536V14.2158H60.0937C60.0937 15.2661 59.9536 16.1568 59.6735 16.888C59.3934 17.6059 58.9732 18.1576 58.4129 18.5432C57.866 18.9154 57.1657 19.1016 56.312 19.1016H56.232Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M69.2563 18.9021C68.4559 18.9021 67.7623 18.7891 67.1754 18.5631C66.6018 18.3371 66.1549 17.9516 65.8348 17.4065C65.528 16.8481 65.3746 16.097 65.3746 15.1531V4.90308H67.2154V15.3924C67.2154 15.9641 67.3688 16.4094 67.6756 16.7285C67.9957 17.0343 68.4426 17.1872 69.0162 17.1872H70.897V18.9021H69.2563ZM63.5338 9.56943V8.13363H70.897V9.56943H63.5338Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M78.4578 19.1414C77.524 19.1414 76.7037 18.9819 75.9967 18.6628C75.2897 18.3438 74.6895 17.925 74.1959 17.4065C73.7157 16.8747 73.3489 16.2831 73.0955 15.6317C72.8553 14.9803 72.7353 14.3156 72.7353 13.6375V13.2786C72.7353 12.6006 72.862 11.9358 73.1155 11.2844C73.3689 10.6197 73.7424 10.0281 74.2359 9.50961C74.7295 8.97783 75.3298 8.55906 76.0367 8.25328C76.7437 7.93422 77.5507 7.77468 78.4578 7.77468C79.3649 7.77468 80.1719 7.93422 80.8788 8.25328C81.5858 8.55906 82.1861 8.97783 82.6796 9.50961C83.1732 10.0281 83.5467 10.6197 83.8001 11.2844C84.0536 11.9358 84.1803 12.6006 84.1803 13.2786V13.6375C84.1803 14.3156 84.0536 14.9803 83.8001 15.6317C83.56 16.2831 83.1932 16.8747 82.6996 17.4065C82.2194 17.925 81.6258 18.3438 80.9189 18.6628C80.2119 18.9819 79.3915 19.1414 78.4578 19.1414ZM78.4578 17.4265C79.2581 17.4265 79.9384 17.2536 80.4987 16.908C81.0723 16.549 81.5058 16.0704 81.7992 15.4722C82.106 14.8739 82.2595 14.2025 82.2595 13.4581C82.2595 12.7003 82.106 12.0223 81.7992 11.424C81.4924 10.8258 81.0523 10.3538 80.4787 10.0081C79.9184 9.66249 79.2448 9.48967 78.4578 9.48967C77.6841 9.48967 77.0105 9.66249 76.4369 10.0081C75.8633 10.3538 75.4231 10.8258 75.1163 11.424C74.8095 12.0223 74.6561 12.7003 74.6561 13.4581C74.6561 14.2025 74.8029 14.8739 75.0963 15.4722C75.4031 16.0704 75.8366 16.549 76.3969 16.908C76.9705 17.2536 77.6574 17.4265 78.4578 17.4265Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M85.7766 18.7825L91.0389 4.22506H94.3604L99.7827 18.7825H97.7618L92.8597 5.50133L93.8201 5.97993H91.4591L92.4795 5.50133L87.7375 18.7825H85.7766ZM88.918 14.4352L89.5783 12.6803H95.881L96.5413 14.4352H88.918Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M102.181 22.7708V8.13363H103.702V12.7402L103.341 12.7003C103.408 11.557 103.648 10.6263 104.062 9.90844C104.489 9.17725 105.042 8.63882 105.723 8.29317C106.403 7.94751 107.143 7.77468 107.943 7.77468C108.717 7.77468 109.417 7.92092 110.044 8.2134C110.685 8.50588 111.232 8.90471 111.685 9.4099C112.139 9.91509 112.485 10.5 112.726 11.1648C112.966 11.8295 113.086 12.5341 113.086 13.2786V13.6375C113.086 14.382 112.959 15.0866 112.706 15.7514C112.452 16.4161 112.092 17.001 111.625 17.5062C111.172 18.0114 110.625 18.4102 109.984 18.7027C109.357 18.9952 108.664 19.1414 107.903 19.1414C107.143 19.1414 106.416 18.9753 105.723 18.6429C105.029 18.3105 104.455 17.7721 104.002 17.0276C103.548 16.2831 103.288 15.306 103.221 14.0962L104.102 15.6915V22.7708H102.181ZM107.603 17.4663C108.324 17.4663 108.951 17.3002 109.484 16.9678C110.018 16.6221 110.431 16.1502 110.725 15.5519C111.018 14.9404 111.165 14.2424 111.165 13.4581C111.165 12.6604 111.018 11.9624 110.725 11.3642C110.431 10.7659 110.018 10.3006 109.484 9.96827C108.951 9.62261 108.324 9.44978 107.603 9.44978C106.963 9.44978 106.369 9.59602 105.823 9.8885C105.276 10.1677 104.835 10.5798 104.502 11.1249C104.182 11.6567 104.022 12.3014 104.022 13.0592V13.9765C104.022 14.7077 104.189 15.3326 104.522 15.8511C104.855 16.3695 105.296 16.7684 105.843 17.0476C106.389 17.3267 106.976 17.4663 107.603 17.4663Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M115.976 22.7708V8.13363H117.497V12.7402L117.137 12.7003C117.203 11.557 117.443 10.6263 117.857 9.90844C118.284 9.17725 118.837 8.63882 119.518 8.29317C120.198 7.94751 120.938 7.77468 121.739 7.77468C122.512 7.77468 123.213 7.92092 123.839 8.2134C124.48 8.50588 125.027 8.90471 125.48 9.4099C125.934 9.91509 126.281 10.5 126.521 11.1648C126.761 11.8295 126.881 12.5341 126.881 13.2786V13.6375C126.881 14.382 126.754 15.0866 126.501 15.7514C126.247 16.4161 125.887 17.001 125.42 17.5062C124.967 18.0114 124.42 18.4102 123.779 18.7027C123.153 18.9952 122.459 19.1414 121.699 19.1414C120.938 19.1414 120.211 18.9753 119.518 18.6429C118.824 18.3105 118.25 17.7721 117.797 17.0276C117.343 16.2831 117.083 15.306 117.017 14.0962L117.897 15.6915V22.7708H115.976ZM121.398 17.4663C122.119 17.4663 122.746 17.3002 123.279 16.9678C123.813 16.6221 124.226 16.1502 124.52 15.5519C124.813 14.9404 124.96 14.2424 124.96 13.4581C124.96 12.6604 124.813 11.9624 124.52 11.3642C124.226 10.7659 123.813 10.3006 123.279 9.96827C122.746 9.62261 122.119 9.44978 121.398 9.44978C120.758 9.44978 120.165 9.59602 119.618 9.8885C119.071 10.1677 118.631 10.5798 118.297 11.1249C117.977 11.6567 117.817 12.3014 117.817 13.0592V13.9765C117.817 14.7077 117.984 15.3326 118.317 15.8511C118.651 16.3695 119.091 16.7684 119.638 17.0476C120.185 17.3267 120.771 17.4663 121.398 17.4663Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M129.891 18.7825V4.22506H131.812V18.7825H129.891ZM128.411 5.74063V4.22506H131.812V5.74063H128.411Z"
        fill={dark ? '#000' : '#fff'}
      />
      <path
        d="M134.776 22.9304V21.2154H136.317C136.77 21.2154 137.15 21.1555 137.457 21.0359C137.777 20.9295 138.037 20.7434 138.237 20.4775C138.451 20.2116 138.624 19.8593 138.758 19.4206L142.119 8.13363H144L140.438 19.9192C140.225 20.6238 139.925 21.1954 139.538 21.6341C139.151 22.0862 138.678 22.4119 138.117 22.6113C137.571 22.824 136.93 22.9304 136.197 22.9304H134.776ZM138.037 18.5432V16.9479H140.358V18.5432H138.037ZM137.837 18.5432L134.016 8.13363H135.977L139.638 18.5432H137.837Z"
        fill={dark ? '#000' : '#fff'}
      />
    </svg>
  );
};

export default MainLogo;
