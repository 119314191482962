import {
  Title,
  InputWrapper,
  CancelCta,
  TranslateCTA,
  Footer,
} from '/imports/generator/ui/components/translateResume/Styles';
import intlHook from 'imports/core/api/useIntl';
import { ALL_LANGUAGES } from 'imports/generator/api/constants';
import React, { useMemo, useState } from 'react';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import PropTypes from 'prop-types';
import useTracking from 'imports/core/hooks/useTracking';
import { prepareLanguageLabelBeforeTranslate } from 'imports/core/ui/components/LocaleSelect';
import Flex from 'imports/core/ui/atoms/Flex';
import styled from 'styled-components';
import { isSAResume } from '/lib/helpers';
import { useGlobalState } from 'imports/core/api/global.context';
import Dropdown from 'imports/core/ui/atoms/Dropdown';

const ChooseLanguageStep = ({ handleChange, resume, handleTranslateCV, handleClose, inputOnly }) => {
  const { locale, t } = intlHook();
  const {
    state: { langauges },
  } = useGlobalState();
  const LSData = typeof localStorage !== 'undefined' && localStorage.getItem('filteroutLanguages');
  const filteroutData = LSData && JSON.parse(LSData);

  const [disabled, setDisabled] = useState(true);
  const { trackEvent } = useTracking();
  const options = useMemo(() => {
    const saResume = isSAResume(resume);
    return ALL_LANGUAGES.map((language) => {
      const translatedTitle = t(`language.${prepareLanguageLabelBeforeTranslate(language.title)}`);
      return {
        ...language,
        title: translatedTitle,
      };
    })
      .filter((l) => l.value !== resume.settings.language)
      .filter((l) =>
        saResume && !langauges && filteroutData && filteroutData?.id === resume?.id
          ? !filteroutData?.langauges?.includes(l.value)
          : !langauges?.includes(l.value),
      )
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [locale]);

  const handleCancel = () => {
    trackEvent('translate_cancel_click');
    handleClose();
  };

  const onSelect = (e) => {
    handleChange({ value: e.target.value });
    trackEvent('translate_select_language', {
      language: e?.target?.value,
      label: e?.target?.title,
    });
    if (e.value === resume.settings.language) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  if (inputOnly) {
    return (
      <Dropdown
        scrollOnKeyPress={true}
        placeholder={t('translate_cv.choose_language')}
        onChange={onSelect}
        options={options}
        name={'translate_language'}
      />
    );
  }

  return (
    <Wrapper $direction="column" $gap="5px">
      <Title>{t('translate_cv.choose_language')}</Title>
      <InputWrapper style={{ maxWidth: 304 }}>
        <FieldLabel summary label={t('translate_cv.translation')} />
        <Dropdown
          scrollOnKeyPress={true}
          placeholder={t('translate_cv.choose_language')}
          onChange={onSelect}
          options={options}
          name={'translate_language'}
        />
      </InputWrapper>
      <Footer $justifyContent="center">
        <CancelCta onClick={handleCancel}>{t('translate_cv.cancel')}</CancelCta>
        <TranslateCTA $disabled={disabled} onClick={handleTranslateCV}>
          {t('translate_cv.translate')}
        </TranslateCTA>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  gap: 40px;
`;
ChooseLanguageStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
  handleTranslateCV: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  resume: PropTypes.object,
};

export default ChooseLanguageStep;
