import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isJobTrackEnable } from 'lib/helpers';
import styled, { css } from 'styled-components';

import Icon from '/imports/core/ui/atoms/ValidatedInputIcon';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';

class InputCont extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    defaultvalue: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    field: PropTypes.object,
    describedBy: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    ariaLabel: PropTypes.string,
  };

  state = {
    value: this.props.value || '',
    hideIcon: false,
  };

  componentDidUpdate(prevProps) {
    const { defaultvalue: defaultValue, value: propsValue } = this.props;
    const { defaultvalue: prevDefaultValue, value: prevValue } = prevProps;
    const { value } = this.state;

    if (prevDefaultValue !== defaultValue && defaultValue !== value) {
      this.setState({ value: defaultValue });
    }

    if (prevValue !== propsValue && propsValue !== value) {
      this.setState({ value: propsValue });
    }
  }

  onChange = (e) => {
    this.setState({ value: e.target.value }, () => {
      const { onChange } = this.props;
      if (onChange) onChange({ target: { value: this.state.value } });
    });
  };

  handleFocus = () => {
    this.setState({ hideIcon: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleBlur = () => {
    this.setState({ hideIcon: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const { value, hideIcon } = this.state;
    const {
      name,
      error,
      type,
      placeholder,
      label,
      className,
      isFullWidth,
      describedBy = undefined,
      ariaLabelledBy = undefined,
      ariaLabel = undefined,
    } = this.props;

    return (
      <Wrap isFullWidth={isFullWidth}>
        <Input
          className={className}
          label={label}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          ref={(ref) => this.input === ref}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          error={error}
          empty={!value}
          inputValid={value && !error}
          {...this.props?.field}
          onChange={(e) => {
            this.props?.field?.onChange(e.target.value);
            this.onChange(e);
          }}
          aria-describedby={describedBy}
          aria-labelledby={ariaLabelledBy}
          aria-label={ariaLabel}
        />
        <Icon
          inputValid={value && !error}
          error={error}
          empty={!value}
          hide={hideIcon}
          isJobTrack={isJobTrackEnable()}
        />
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.general.borderRadius};
  overflow: hidden;

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
`;

const Input = styled.input`
  ${inputStyle}
  ${overrideInputStyleMixin} 
  color: #484870;
  box-shadow: none;
  border: 2px solid #e6e6ff;
  border-radius: 3px;
  padding: 13px 16px 9px;
  ${({ theme }) =>
    theme.designV2 &&
    css`
      height: 48px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 13px 16px 13px;
      border: solid 1px #e3e3e4;
      background-color: #ffffff;
      border-radius: 8px;
      flex-direction: row;
    `}

  &:focus {
    border-color: ${({ theme }) => (theme.designV2 ? '#428eff' : '#1688fe')};

    & ~ div {
      visibility: visible;
      transform: rotateY(0);
      background-color: ${(p) => p.theme.colors.primary};
    }
  }

  ${({ inputValid }) =>
    inputValid &&
    css`
      border-color: ${({ theme }) => (theme.designV2 ? ' ##e3e3e4' : '#19cca3')};
    `}

  && {
    ${({ error, theme }) =>
      error &&
      css`
        border-color: ${theme.colors.red};
      `};
    ${({ error, theme }) =>
      error &&
      theme.max('xs')`
    border-color: #e84c85;
  `}
  }
`;

export default InputCont;
