import { MainTitleFile } from 'imports/ApplyForm/ui/atoms/MainTitleFile';
import { Loader } from 'imports/generator/ui/atoms/Loader';
import OneLineCss from 'imports/job-tracking/ui/atoms/OneLineCss';
import React, { Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import MainLogo from '/imports/AutoApply/ui/assets/MainLogo';
import { useResponsive } from '/imports/core/api/responsiveContext';
import intlHook from '/imports/core/api/useIntl';
import useWindowSize from '/imports/core/api/useWindowSize';
import Flex from '/imports/core/ui/atoms/Flex';
import { LOADING_DATA_INFO } from '/imports/resume-options/api/constant';
import { FileUploadDoneIcon, UploadIconAutoApply } from '/imports/resume-options/ui/assest/AutoApplyIcons';
import { HiddenblockLayOut } from '/imports/resume-options/ui/component/UploadResume';

const UploadResumeAutoApply = (props) => {
  const {
    loading,
    file,
    uploadModal,
    inputfileref,
    getInputProps,
    getRootProps,
    setFile,
    handleNext,
    heightResume,
    onClose,
    prevRender,
    isSuccess,
  } = props;
  const { t } = intlHook();
  const { isMobile } = useResponsive();
  const { width, height } = useWindowSize();

  return (
    <MainContainer $fullWidth direction="column">
      <SubContainer $fullWidth direction="column">
        <HeadingSection $fullWidth $padding>
          <MainLogo dark={true} />
        </HeadingSection>
      </SubContainer>
      <HeadingSection $padding direction="column" $gap $fullWidth alignItems="center" $loading={loading} $main>
        {!loading && <MainTitleFile title={'Upload your resume'} />}
        <Input type="File" ref={inputfileref} className="input-zone" accept=".pdf" {...getInputProps()} />
        {!file ? (
          <Fragment>
            <AutoApplyUpload
              onClick={() => inputfileref.current?.click()}
              justifyContent="center"
              alignItems="center"
              direction="column"
              $fullWidth
              {...getRootProps({ className: 'dropzone' })}
              multiple={false}
              uploadModal={uploadModal}
            >
              <UploadIconAutoApply />
              <StyledFlex direction="column" alignItems="center">
                <Heading>{t('resume_options_sub_title_3')}</Heading>
              </StyledFlex>
            </AutoApplyUpload>
            <SubHeading lowmargin>{t('resume_options_sub_title_4')}</SubHeading>
          </Fragment>
        ) : (
          <Fragment>
            <LoaderWrapAutoApply fullWidth direction="column" uploadModal={uploadModal} loading={loading}>
              <PdfNameWrapper fullWidth direction="column" alignItems="center" uploadModal={uploadModal}>
                <FileUploadDoneIcon />
                <PdfFlex direction="column" fullWidth uploadModal={uploadModal} alignItems="center">
                  <PdfName uploadModal={uploadModal} isMobile={isMobile}>
                    {file?.name}
                  </PdfName>
                  <ChangeCTA onClick={() => setFile(null)}>Change</ChangeCTA>
                </PdfFlex>
              </PdfNameWrapper>
            </LoaderWrapAutoApply>
            <SubHeading lowmargin>{t('resume_options_sub_title_4')}</SubHeading>
          </Fragment>
        )}
      </HeadingSection>
      {loading && (
        <AutoApplyLoading alignItems="center" justifyContent="center" $fullWidth direction="column">
          <Loader />
          <AutoApplyLoadingTxt>Uploading your resume...</AutoApplyLoadingTxt>
          <LoadingWrapInfo direction="column">
            {LOADING_DATA_INFO.map((item, index) => (
              <AnimatedLine alignItems="center" key={index} delay={(index + 1) * 3}>
                <Flex>{item.icon}</Flex>
                <TxtFlex>{item.title}</TxtFlex>
              </AnimatedLine>
            ))}
          </LoadingWrapInfo>
        </AutoApplyLoading>
      )}
      {!loading && (
        <BottomSection $fullWidth>
          <HeadingSection justifyContent="space-between" $fullWidth>
            <BackCTA alignItems="center" onClick={onClose}>
              Back
            </BackCTA>
            <BackCTA
              alignItems="center"
              isnext
              onClick={file ? handleNext : undefined}
              disabled={file ? loading : false}
            >
              {file ? (loading ? t('payment.loading') : t('resume_options_btn_next')) : 'Next'}
            </BackCTA>
          </HeadingSection>
        </BottomSection>
      )}
      {heightResume && (
        <HiddenblockLayOut resume={heightResume} width={width} height={height} prevRender={prevRender} nocloseIcon />
      )}
    </MainContainer>
  );
};

export default UploadResumeAutoApply;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BackCTA = styled(Flex)`
  cursor: pointer;
  padding: 11px 36px 11px 36px;
  border: 1px solid rgb(11, 28, 86);
  border-radius: 200px;
  transition: all 0.3s;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  color: rgb(11, 28, 86);

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: rgb(11, 28, 86);
    color: #fff;

    > svg > g > path {
      stroke: #fff;
    }
  }

  ${({ isnext, disabled }) => css`
    ${isnext &&
    css`
      border: 1px solid rgb(28, 69, 214);
      background: rgb(28, 69, 214);
      color: #fff;

      &:hover {
        background: rgb(22, 55, 171);
      }
    `}

    ${disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
  `}
`;

const BottomSection = styled(Flex)`
  padding: 10px 0 15px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -0.2rem 0.5rem 0px;
  margin-top: auto;
`;

const Line1 = styled(Flex)`
  gap: 10px;
`;

const TxtFlex = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  color: rgb(11, 28, 86);
  font-size: 18px;
`;

const AnimatedLine = styled(Line1)`
  opacity: 0;
  transform: translateY(20px);
  animation: ${slideUp} 0.8s cubic-bezier(0.22, 1, 0.36, 1) forwards;
  animation-delay: ${(props) => props.delay}s;
`;

const LoadingWrapInfo = styled(Flex)`
  gap: 10px;
`;

const AutoApplyLoadingTxt = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  color: rgb(11, 28, 86);
  font-size: 18px;
`;

const AutoApplyLoading = styled(Flex)`
  height: 100%;
  gap: 40px;
`;

const ChangeCTA = styled(Flex)`
  background-color: rgb(210, 218, 247);
  border: 1px solid rgb(210, 218, 247);
  border-radius: 20vmax;
  cursor: pointer;
  padding: 8px 24px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
`;

const PdfName = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: rgb(11, 28, 86);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  text-align: center;

  ${({ uploadModal, isMobile }) => css`
    ${uploadModal &&
    css`
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 100px;

      @media (min-width: 768px) {
        max-width: none;
      }
    `}
    ${isMobile &&
    css`
      width: 75%;
      ${OneLineCss}
    `}
  `}
`;

const PdfFlex = styled(Flex)`
  ${({ uploadModal, theme }) =>
    uploadModal &&
    css`
      ${theme.max('xs')`
        margin: 0;
      `}
    `}

  margin: 0;
  gap: 15px;
`;

const PdfNameWrapper = styled(Flex)`
  gap: 10px;
  ${({ uploadModal, theme }) =>
    uploadModal &&
    css`
      ${theme.max('xs')`
      gap:5px;
    `}
    `}
`;

const LoaderWrapAutoApply = styled(Flex)`
  border: 4px solid #1688fe;
  padding: 24px;
  color: rgb(6, 14, 43);
  border-radius: 16px;
  padding: 24px 24px 28px 24px;
  height: 250px;
  gap: 20px;
  cursor: pointer;

  ${({ loading }) =>
    loading &&
    css`
      display: none;
    `}
`;

const SubHeading = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  text-align: center;
  margin-top: -20px;
  color: rgb(11, 28, 86);
  font-size: 16px;
  line-height: 1.5;
`;

const Heading = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #33334f;
`;

const StyledFlex = styled(Flex)`
  gap: 10px;
`;

const AutoApplyUpload = styled(Flex)`
  border: 4px dashed rgb(151, 157, 172);
  padding: 12px;
  color: rgb(6, 14, 43);
  border-radius: 16px;
  padding: 24px 24px 28px 24px;
  margin: 0 auto;
  height: 250px;
  gap: 20px;
  cursor: pointer;
  width: 90%;
`;

const Input = styled.input`
  display: none;
`;

const HeadingSection = styled(Flex)`
  max-width: 1100px;
  margin: 0 auto;
  width: 95%;

  ${({ $gap, $padding, $loading, $main }) => css`
    ${$padding &&
    css`
      padding: 15px 0;
    `}
    ${$gap &&
    css`
      gap: 40px;
      max-width: 542px;
      width: 100%;
    `}
    ${$loading &&
    css`
      display: none;
      align-items: center;
      height: 100%;
      justify-content: center;
    `}
    ${$main &&
    css`
      width: 95%;
    `}
  `}
`;

const SubContainer = styled(Flex)`
  background: rgb(242, 243, 245);
`;

const MainContainer = styled(Flex)`
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  background: #fff;
`;
