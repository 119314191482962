import React from 'react';

const Clothes = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <g clipPath="url(#clip0_21952_28092)">
        <path
          d="M3.97567 6.3125L8.01805 6.3125L11.3047 10.8647C11.3047 10.8647 9.23851 12.5906 5.99675 12.4949C2.75518 12.5906 0.689001 10.8647 0.689001 10.8647L3.97567 6.3125Z"
          fill="#7C7C7C"
        />
        <path
          d="M8.69843 3.70808L8.01668 5.79301L3.9743 5.79301L3.29255 3.70808L2.22963 4.52666L1.09252 3.15041C1.95202 1.5005 3.58617 0.499999 3.58617 0.499999C4.14483 1.24058 5.01628 1.71687 5.99541 1.71687C6.97474 1.71687 7.84617 1.24058 8.40483 0.5C8.40483 0.5 10.039 1.5005 10.8984 3.15041L9.76153 4.52666L8.69843 3.70808Z"
          fill="#7C7C7C"
        />
      </g>
      <defs>
        <clipPath id="clip0_21952_28092">
          <rect width="12" height="12" fill="white" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 12 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clothes;
