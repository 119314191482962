import { create } from 'zustand';

export const useSearchV2Store = create((set) => ({
  jobs: [],
  activeJob: null,
  pagination: {},
  searchValue: '',
  cityValue: '',
  setJobs: (jobs) => set({ jobs }),
  setActiveJob: (activeJob) => set({ activeJob }),
  setPagination: (pagination) => set({ pagination }),
  setValues: (data) => set({ searchValue: data.searchValue, cityValue: data.cityValue }),
}));
