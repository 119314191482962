import { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import AutoSuggest from 'react-autosuggest';
import nookies from 'nookies';
import { debounce, isObject } from 'lodash';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { defaultCandidateSuggesiton, searchAutoComplete } from '/imports/job-tracking/api/api';
import CloseIcon from '/imports/resume-options/ui/assest/CloseIcon';
import Flex from '/imports/core/ui/atoms/Flex';
import SearchIcon from '/imports/job-tracking/ui/assets/SearchIcon';
import SearchToolTip from '/imports/job-tracking/ui/components/SearchToolTip';
import useIntl from '/imports/core/api/useIntl';
import { useSearchStore } from 'zustand/SearchStore';
import useFeedStore from 'zustand/feedStore';

const SearchHeader = ({ changeText, searchLoading, searchJobData }) => {
  const { t, locale } = useIntl();
  const { token } = nookies.get();
  const [citySuggestion, setCitySuggestion] = useState([]);
  const [searchSuggestion, setSearchSuggestion] = useState([]);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [cityFocus, setCityFocus] = useState(false);
  const [showToolTip, setShow] = useState(false);
  const inputEl = useRef(null);
  const cityEl = useRef(null);
  const countryRef = useRef(null);
  const {
    query: { show, template, text, loc },
    query,
  } = useRouter();
  const [search, setSearch] = useState('');
  const [city, setCity] = useState('');
  const [fromHighlight, setFromHighlight] = useState(false);
  const [fromHighlightCity, setFromHighlightCity] = useState(false);
  const { searchTextData, filter, cityTextData, savedFeedNameData, isLoading, setCandidateSearchs, candidateSearchs } =
    useSearchStore();
  const notMantiksDB = useMemo(() => {
    return filter?.apiProvider !== 'search-v5';
  }, [filter]);
  const { activeFeeds } = useFeedStore();

  useEffect(() => {
    if (search === '') {
      setTimeout(() => {
        if (savedFeedNameData) fetchSearchData(savedFeedNameData);
      }, 1500);
    }
  }, [search]);

  const SearchInfo = useCallback(() => {
    if (searchTextData) setSearch(searchTextData);
    if (cityTextData) setCity(cityTextData);
  }, [searchTextData, cityTextData]);

  useEffect(() => {
    SearchInfo();
  }, [SearchInfo]);

  const getCandidateSearch = async () => {
    const res = await defaultCandidateSuggesiton(token);
    const filterOut = Array.isArray(res) && res?.filter((k) => k.title && k.location);
    setCandidateSearchs(Array.isArray(res) ? filterOut : []);
  };

  useEffect(() => {
    if (!query?.search || !query?.location) getCandidateSearch();
  }, []);
  useEffect(() => {
    if (show === 'true') setShow(true);
    else setShow(false);
  }, [show]);

  const updateSearchAndCity = useCallback(() => {
    if (activeFeeds) {
      const search = activeFeeds?.searchText || '';
      const where = activeFeeds?.where || '';
      setSearch(search);
      setCity(where);
    }
  }, [activeFeeds, setSearch, setCity]);

  useEffect(() => {
    updateSearchAndCity();
  }, [updateSearchAndCity]);

  useEffect(() => {
    if (template === 'search') {
      const search = text ? text : searchTextData ? searchTextData : '';
      const location = loc ? loc : cityTextData ? cityTextData : '';
      setSearch(search);
      setCity(location);
    }
  }, [template]);

  const handleOutside = (e) => {
    if (open) {
      if (countryRef.current && !countryRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
  };
  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleOutside);
      return () => {
        document.removeEventListener('click', handleOutside);
      };
    }
  }, [open]);

  const handleSearch = (type) => (e) => {
    if (e.key === 'Enter' && search && city) {
      searchJobData && searchJobData(search, city, 1, true);
      changeText && changeText(search, city, true);
      if (type === 'feedName') {
        inputEl.current?.blur();
        setFocus(false);
      } else if (type === 'city') {
        cityEl.current?.blur();
        setCityFocus(false);
      }
    }
  };

  const changeSearch = (isSearch) => (e) => {
    if (isSearch) {
      setFromHighlight(false);
      setSearch(e.target.value);
      if (inputEl.current.value === '') {
        setFocus(true);
        if (savedFeedNameData) fetchSearchData(savedFeedNameData);
      } else {
        setFromHighlightCity(false);
        setCity(e.target.value);
      }
    }
  };

  const searchDirectly = () => {
    searchJobData && searchJobData(search, city, 1, true);
    changeText && changeText(search, city, true);
    inputEl.current?.blur();
    cityEl.current?.blur();
    setFocus(false);
  };

  const fetchData = async (citySearch) => {
    if (citySearch) {
      const resp = await searchAutoComplete(citySearch, 'CITY', locale, token);
      setCitySuggestion(resp);
    }
  };

  const fetchSearchData = async (searchData) => {
    if (searchData !== undefined) {
      const resp = await searchAutoComplete(searchData, 'TITLE', locale, token);
      setSearchSuggestion(resp);
      return;
    }
    if (savedFeedNameData) fetchSearchData(savedFeedNameData);
  };

  const debounceData = useRef(debounce((citySearch) => fetchData(citySearch), 200)).current;

  const debounceSearchData = useRef(debounce((searchData) => fetchSearchData(searchData), 200)).current;

  const handleCitySuggestion = (event, { method, suggestion }) => {
    const value = suggestion?.location ? suggestion?.location : suggestion;
    setFromHighlightCity(false);
    if (value?.replace(/ /g, '') === '') return;
    if (method === 'enter') return;
    setCityFocus(false);
    event.preventDefault();
    setCity(value);
    setTimeout(() => {
      cityEl.current?.blur();
      if (city) setShow(false);
    }, 100);
  };

  const handleSearchSuggestion = (event, { suggestion, method }) => {
    const value = suggestion?.title ? suggestion?.title : suggestion;
    setFromHighlight(false);
    if (value?.replace(/ /g, '') === '') return;
    if (method === 'enter') return;
    setFocus(false);
    event.preventDefault();
    setSearch(value);
    setShow(true);
    setTimeout(() => {
      inputEl.current?.blur();
      setShow(false);
    }, 100);
  };
  const getSuggestionValue = (suggestion) => {
    setFromHighlight(false);
    setSearch(suggestion);
  };

  const handleFocus = (type) => {
    if (type === 'feedName') {
      setFocus(true);
      if (savedFeedNameData && searchSuggestion.length === 0 && !search) fetchSearchData(savedFeedNameData);
      else if (searchSuggestion.length === 0 && !search) setSearchSuggestion(candidateSearchs);
    } else if (type === 'city') {
      setCityFocus(true);
      citySuggestion.length === 0 && !city && setCitySuggestion(candidateSearchs);
    }
  };

  const clearText = (isCity) => (e) => {
    e.stopPropagation();
    if (isCity) {
      setCity('');
      setCitySuggestion(candidateSearchs);
    } else {
      setSearch('');
    }
  };

  const searchSuggestionHighlight =
    (isFeedName) =>
    ({ suggestion }) => {
      if (isObject(suggestion)) {
        if (isFeedName) {
          setFromHighlight(true);
          setSearch(suggestion.title);
          return;
        } else {
          setFromHighlightCity(true);
          setCity(suggestion.location);
          return;
        }
      }

      if (suggestion) {
        if (isFeedName) {
          setFromHighlight(true);
          setSearch(suggestion);
        } else {
          setFromHighlightCity(true);
          setCity(suggestion);
        }
      }
    };

  return (
    <SearchFlex fullWidth alignItems="center">
      <HeaderWrapper fullWidth alignItems="center">
        <SearchInputFlex fullWidth>
          <SearchInputWrapper className="field-wrapper">
            <FieldLabel>{t('jobtrack_search_keyword_input_label')}</FieldLabel>
            <AutoSuggest
              suggestions={focus ? searchSuggestion : []}
              alwaysRenderSuggestions={focus}
              onSuggestionsFetchRequested={({ value }) => {
                setSearch(value);
                debounceSearchData(value);
                if (!value) {
                  setSearchSuggestion(candidateSearchs);
                }
              }}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={(searchSuggestion) => {
                return searchSuggestion?.title ? searchSuggestion?.title : '';
              }}
              onSuggestionsClearRequested={() => setSearchSuggestion([])}
              onSuggestionHighlighted={searchSuggestionHighlight(true)}
              onSuggestionSelected={handleSearchSuggestion}
              inputProps={{
                placeholder: t('jobtrack_search_keyword_input_placeholder'),
                value: search,
                ref: inputEl,
                onFocus: () => handleFocus('feedName'),
                onKeyDown: handleSearch('feedName'),
                onBlur: () => {
                  if (fromHighlight) {
                    setFromHighlight(false);
                    setSearch(activeFeeds?.searchText || '');
                  }
                  setFocus(false);
                  setCitySuggestion([]);
                },
                onChange: (e) => {
                  changeSearch(e, true);
                },
              }}
            />
          </SearchInputWrapper>
          <CrossFlex onMouseDown={clearText()} show={focus && search !== ''}>
            <CloseIcon />
          </CrossFlex>
        </SearchInputFlex>
        <SearchInputFlex fullWidth>
          <SearchInputWrapper className="field-wrapper">
            <FieldLabel>{t('jobtrack_search_location_input_label')}</FieldLabel>
            <AutoSuggest
              suggestions={cityFocus ? citySuggestion : []}
              alwaysRenderSuggestions={cityFocus}
              onSuggestionsFetchRequested={({ value }) => {
                setCity(value);
                debounceData(value);
                if (!value) {
                  setCitySuggestion(candidateSearchs);
                }
              }}
              getSuggestionValue={(suggestion) => {
                setFromHighlightCity(false);
                setCity(suggestion);
              }}
              renderSuggestion={(citySuggestion) => {
                return citySuggestion?.location ? citySuggestion.location : '';
              }}
              onSuggestionsClearRequested={() => setCitySuggestion([])}
              onSuggestionHighlighted={searchSuggestionHighlight}
              onSuggestionSelected={handleCitySuggestion}
              inputProps={{
                placeholder: t('jobtrack_search_location_input_placeholder'),
                value: city,
                ref: cityEl,
                onBlur: () => {
                  if (fromHighlightCity) {
                    setFromHighlightCity(false);
                    setCity(activeFeeds?.where || '');
                  }
                  setCityFocus(false);
                },
                onFocus: () => handleFocus('city'),
                onKeyDown: handleSearch('city'),
                onChange: (e) => {
                  changeSearch(e);
                },
              }}
            />
          </SearchInputWrapper>
          <CrossFlex onMouseDown={clearText(true)} show={cityFocus && city !== ''}>
            <CloseIcon />
          </CrossFlex>
        </SearchInputFlex>
        <SearchToolTip show={showToolTip} />
      </HeaderWrapper>
      {/* <HeaderSupport total={total} tags={tags} tagSelect={tagSelect} FilterTags={FilterTags} /> */}
      <FlexSearch alignItems="center" show={showToolTip} disable={(!city && notMantiksDB) || !search}>
        <SearchButton onClick={searchDirectly} disabled={searchLoading || isLoading}>
          <SvgWrapper disabled={isLoading || searchLoading}>
            <SearchIcon />
          </SvgWrapper>
          {t('jobtrack_search_keyword_input_label')}
        </SearchButton>
      </FlexSearch>
    </SearchFlex>
  );
};

export default SearchHeader;

const FlexSearch = styled(Flex)`
  gap: 8px;
  width: 100%;
  ${({ show }) =>
    show &&
    css`
      margin-top: 70px;
    `}
  @media (min-width: 768px) {
    width: fit-content;
    margin-top: 0;
  }
  ${({ disable }) =>
    disable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const SearchInputFlex = styled(Flex)`
  border-radius: 4px;
  position: relative;
  background-color: var(--light-values-white);
`;

const SvgWrapper = styled.div`
  height: 16px;
  width: 16px;
  svg > path {
    fill: var(--light-values-white);
  }
  ${({ disabled }) =>
    disabled &&
    css`
      svg > path {
        fill: var(--grayscale-n300);
      }
    `}
`;

const SearchFlex = styled(Flex)`
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const SearchButton = styled(Flex)`
  padding: 18px 24px;
  gap: 12px;
  background-color: #1688fe;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '18', 'var(--light-values-white)', '24px')};
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  @media (min-width: 768px) {
    width: fit-content;
  }
  &:hover {
    background-color: #0072e8;
  }
  &:active {
    background-color: #0062c7;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: var(--grayscale-n50);
      color: var(--grayscale-n300);
    `}
`;

const FieldLabel = styled.p`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-dark-regular)', '1.54')};
  margin: 0;
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  height: 60px;
  padding: 9px 12px;
  border-radius: 4px;
  background-color: var(--light-values-white);
  border: 1px solid var(--grayscale-n300);
  &:focus-within {
    border-color: #1688fe;
  }
  .react-autosuggest__input {
    width: 100%;
    ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-black)', '1.47')};
    border: none;
    &::placeholder {
      color: var(--grayscale-n300);
    }
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: block;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 58px;
    left: 0px;
    right: 0px;
    width: 100%;
    border: 1px solid #1688fe;
    border-top-color: var(--grayscale-n300);
    background-color: #fff;
    ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-dark-regular)', '20px')};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 99;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`;

const CrossFlex = styled(Flex)`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  right: 15px;
  text-align: center;
  margin: auto;
  width: 13px;
  top: 22px;
  cursor: pointer;
  svg > path {
    fill: var(--dark-values-dark-regular);
  }
`;

const HeaderWrapper = styled(Flex)`
  z-index: 99;
  transition: all 0.3s;
  border-radius: 4px;
  background-color: var(--light-values-white);
  position: relative;
  gap: 8px;
  width: 100%;
  form {
    display: flex;
    width: 100%;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
