import { Loader } from 'imports/generator/ui/atoms/Loader';
import { LoaderWrapper, Subtitle, Title, CTA } from '/imports/generator/ui/components/translateResume/Styles';
import intlHook from 'imports/core/api/useIntl';
import Flex from 'imports/core/ui/atoms/Flex';
import styled from 'styled-components';

const ProcessTranslationStep = ({ processRefCount, closeModal }) => {
  const { t } = intlHook();
  return (
    <LoaderWrapper direction="column" justifyContent="center" alignItems="center" height="100%">
      <Loader />
      <Subtitle style={{ fontSize: 18 }}>
        <TextInfo processRefCount={processRefCount} t={t} />
      </Subtitle>
    </LoaderWrapper>
  );
};

const TextInfo = ({ processRefCount, t }) => {
  if (processRefCount < 7) return t('translate_cv.translate.being_translated');
  if (processRefCount < 35) return t('translate_cv.cv_content_bigger');
  return t('translate_cv.translate_might_failed');
};

export default ProcessTranslationStep;
