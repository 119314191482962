import React from 'react';

const DeltaAirIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
      <g clipPath="url(#clip0_21952_27992)">
        <path
          d="M13.8975 10.8713L7.45312 7.76821V0.941406L13.8975 10.8713ZM14.4098 12.0548L7.45312 9.1177V12.0548H14.4098Z"
          fill="#98002E"
        />
        <path d="M1.01562 10.8713L7.45996 0.941406V7.76821L1.01562 10.8713Z" fill="#E31837" />
        <path d="M0.5 12.0543H7.4567V9.11719L0.5 12.0543Z" fill="#E31837" />
      </g>
      <defs>
        <clipPath id="clip0_21952_27992">
          <rect width="14" height="11.1134" fill="white" transform="translate(0.5 0.941406)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeltaAirIcon;
