import { useSearchStore } from './SearchStore';
import { saveMantiksFeed } from 'imports/job-tracking/api/api';
import { create } from 'zustand';

const useFeedStore = create((set, get) => ({
  feeds: null,
  activeFeeds: null,
  updateFeeds: null,
  renameFeed: {},
  addFeeds: {},
  changeIcon: null,

  setFeeds: (newFeeds) =>
    set(() => ({
      feeds: newFeeds,
    })),

  setActiveFeeds: (active) => set({ activeFeeds: active }),

  updateToFeeds: (resp, feedId) =>
    set((state) => {
      const feedIndex = state.feeds.findIndex((feed) => feed.feedId === feedId);
      const updatedFeeds = [...state.feeds];
      updatedFeeds[feedIndex] = resp;

      return {
        feeds: updatedFeeds,
        activeFeeds: resp,
        updateFeeds: resp,
      };
    }),

  renameFeedName: (newFeedName, feedId) =>
    set((state) => {
      const feedIndex = state.feeds.findIndex((feed) => feed.feedId === feedId);
      const updatedFeeds = [...state.feeds];
      updatedFeeds[feedIndex] = newFeedName;

      return {
        feeds: updatedFeeds,
        activeFeeds: newFeedName,
        renameFeed: newFeedName,
      };
    }),
  saveMantiksDataIntoFeed: (newFeed, jobs = []) => {
    const { filter, jobsData } = useSearchStore.getState();
    let currentJobsList = jobsData;
    if (!newFeed) {
      newFeed = get().activeFeeds;
    }
    if (jobs?.length > 0) {
      // if jobs is passed we use it instead of current state
      currentJobsList = jobs;
    }
    if (filter.apiProvider === 'search-v5') {
      saveMantiksFeed({
        feedId: newFeed.feedId,
        feedName: newFeed.feedName,
        searchText: newFeed.searchText || newFeed.feedName,
        where: newFeed.where,
        jobs: currentJobsList?.map((job) => {
          return {
            jobId: job?.job_id,
            company: job?.company,
            description: job?.description,
            title: job?.job_title,
            salary: job?.salaryRange,
            location: job?.location,
            datePosted: job?.datePosted,
            employmentType: job?.employmentType,
            url: job?.url,
          };
        }),
      });
    }
  },

  addToFeeds: (add) =>
    set((state) => {
      const newFeeds = [add, ...state.feeds];
      get().saveMantiksDataIntoFeed(add);
      return {
        feeds: newFeeds,
        addFeeds: add,
      };
    }),

  setIcon: (icon, id) =>
    set((state) => {
      const newIcon = state.feeds.findIndex((feed) => feed.feedId === id);
      const updateIcon = [...state.feeds];
      updateIcon[newIcon] = icon;

      return {
        feeds: updateIcon,
        changeIcon: icon,
      };
    }),
}));

export default useFeedStore;
