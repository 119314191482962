import { useResponsive } from 'imports/core/api/responsiveContext';
import DropdownAutoSaveForFinalize from 'imports/core/ui/atoms/DropdownAutoSaveForFinalize';
import {
  moveLeftAtsBlocksToRight,
  moveRightAtsBlocksToLeft,
  setDefaultValueSABudapest,
} from 'imports/generator/api/helpers';
import { getActiveRepositingVar, getPreviewBlocksHeight } from 'lib/helpers';
import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import { useSelectStore } from 'zustand/SelectStore';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import {
  REORDER_BLOCK,
  UPDATE_BLOCK_POSITION,
  UPDATE_COVER_LETTER_DETAIL,
  UPDATE_RESUME_DETAIL,
} from '/imports/generator/api/apollo/client/mutations';
import { COVER_LETTER_TEMPLATES, TEMPLATES } from '/imports/generator/api/constants';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { DEFAULT_TEMPLATE_FONTS } from '/imports/pdf/core/api/constants';
import { useAccount } from 'imports/core/api/accounts/accountContext';
import nookies from 'nookies';

const TemplateSelectorDropdown = ({
  options,
  source,
  hideLabel,
  isCoverLetter,
  dark,
  preview,
  stripe,
  needUpdate,
  updateImmue,
}) => {
  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { currentUser } = useAccount();
  const { breakpoint } = useResponsive();
  const [updateBlockPosition] = useMutation(UPDATE_BLOCK_POSITION);
  const { agent_client_id } = nookies.get({});

  const [updateResumeDetail] = useMutation(UPDATE_RESUME_DETAIL);
  const [updateCoverLetterDetail] = useMutation(UPDATE_COVER_LETTER_DETAIL);
  const [reorder] = useMutation(REORDER_BLOCK);

  const { expanded, setSelectedResume } = useSelectStore();

  const {
    blocks,
    id: docId,
    settings: { template, headingFont, contentFont, templateVersion },
  } = source;

  const templateSwitch = (e) => {
    const {
      target: { value: newTemplate, version },
    } = e;

    trackEvent('switch_template', {
      template: `${newTemplate}${version > 1 ? `-v${version}` : ''}`,
    });

    if (!isCoverLetter) {
      setSelectedResume(newTemplate);
      if (template === 'atsresume' && newTemplate !== 'atsresume') {
        moveLeftAtsBlocksToRight(source, updateImmue, updateBlockPosition);
      }
      if (newTemplate === 'atsresume') {
        moveRightAtsBlocksToLeft(source, updateImmue, updateBlockPosition);
      }
      if (newTemplate === 'budapest' && version === 3) {
        setDefaultValueSABudapest(source, updateResumeDetail);
      }

      updateResumeDetail({
        variables: {
          docId,
          path: 'settings.templateVersion',
          value: `${(version && parseInt(version)) || 1}`,
        },
      });
      updateImmue && blockDetailImmutableUpdate(updateImmue)(docId, 'settings.templateVersion')(version);
    }

    if (newTemplate === template) return;

    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateObject = templates.find((c) => c.id === newTemplate);

    if (
      DEFAULT_TEMPLATE_FONTS[template]?.headingFont === headingFont &&
      DEFAULT_TEMPLATE_FONTS[template]?.contentFont === contentFont &&
      !isCoverLetter
    ) {
      if (headingFont !== DEFAULT_TEMPLATE_FONTS[newTemplate]?.headingFont) {
        updateResumeDetail({
          variables: {
            docId,
            path: 'settings.headingFont',
            value: DEFAULT_TEMPLATE_FONTS[newTemplate]?.headingFont,
          },
        });
        blockDetailImmutableUpdate(updateImmue)(docId, 'settings.headingFont')(
          DEFAULT_TEMPLATE_FONTS[newTemplate]?.headingFont,
        );
      }

      if (contentFont !== DEFAULT_TEMPLATE_FONTS[newTemplate]?.contentFont) {
        updateResumeDetail({
          variables: {
            docId,
            path: 'settings.contentFont',
            value: DEFAULT_TEMPLATE_FONTS[newTemplate]?.contentFont,
          },
        });
        blockDetailImmutableUpdate(updateImmue)(docId, 'settings.contentFont')(
          DEFAULT_TEMPLATE_FONTS[newTemplate]?.contentFont,
        );
      }
    }

    if (templateObject && templateObject.defaultColor) {
      const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
      const options = {
        variables: {
          docId,
          path: 'settings.color',
          value: templateObject.defaultColor,
        },
      };
      // doubt on this line nedd to cross check.
      if (isCoverLetter) {
        options.context = {
          client: 'coverLetter',
        };
      }
      mutation(options);
    }
  };

  const customBlocks =
    blocks && blocks.length > 0 ? blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b) : [];

  const customSkillsBlocks =
    blocks && blocks.length > 0
      ? blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
      : [];

  const activeRepositionVariant = getActiveRepositingVar();
  const isMobile = ['xs', 'sm'].includes(breakpoint);
  let obj = {
    docId,
    path: `settings.template`,
    needUpdate: needUpdate,
  };

  if (activeRepositionVariant === 'with_complete_repositioning' && !isMobile) {
    obj = {
      ...obj,
      heights: getPreviewBlocksHeight(customBlocks, customSkillsBlocks),
      variant: 'with_complete_repositioning',
    };
  }

  let templateValue = template;

  if (templateVersion > 1) {
    templateValue = `${templateValue}-v${templateVersion}`;
  }

  const value = templateValue > 1 ? `${templateValue}-v${templateVersion}` : templateValue;

  const isAgentOrAdmin =
    (currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin')) ||
    (agent_client_id !== undefined && agent_client_id !== null);

  const SABudapestHide =
    source?.settings?.template === 'budapest' && source?.settings?.templateVersion === '3' && !isAgentOrAdmin;

  return (
    <Wrap language={locale}>
      {!hideLabel && (
        <FieldLabel
          summary
          label={t('generator.settings.template')}
          SABudapestHide={SABudapestHide}
          htmlFor="generator-settings__template"
        />
      )}
      <DropdownAutoSaveForFinalize
        dark={dark}
        mutation={isCoverLetter ? UPDATE_COVER_LETTER_DETAIL : UPDATE_RESUME_DETAIL}
        variables={obj}
        expandTitle={t('generator.settings.template')}
        name="template"
        isExpanded={expanded === 'template'}
        docType="resume"
        value={value}
        options={options}
        stripe={stripe}
        preview={preview}
        isCoverLetter={isCoverLetter}
        optimisticResponse={blockDetailImmutableUpdate(updateImmue)(docId, 'settings.template')}
        onChangeCallback={templateSwitch}
        updateImmue={updateImmue}
        source={source}
        id="generator-settings__template"
      />
    </Wrap>
  );
};

TemplateSelectorDropdown.propTypes = {
  options: PropTypes.array,
  source: PropTypes.object,
  hideLabel: PropTypes.bool,
  isCoverLetter: PropTypes.bool,
};

const Wrap = styled.div`
  position: relative;
`;

export default TemplateSelectorDropdown;
