import React from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import styled, { css } from 'styled-components';

import Container from '/imports/homepageV2/ui/atmos/Container';
import Flex from '/imports/core/ui/atoms/Flex';
import { getBrandName } from '/lib/helpers';
import Link from '/components/Link';
import LocaleSelect from '/imports/core/ui/components/LocaleSelect';
import LoginIcon from '/imports/homepageV2/ui/atmos/LoginIcon';
import Logo from '/imports/newHomePage/ui/atmos/Logo.js';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Header = ({ userIdentified, onClickLandingCTA, isNewMobileDesign = false }) => {
  const { t, locale } = useIntl();
  const isNewDesign = locale === 'en';
  const { host } = useResponsive();
  const loginLink = (
    <HeaderLink $domain={host}>
      <Link href="/login" passHref>
        {t('landing.login')}
      </Link>
    </HeaderLink>
  );

  return (
    <HeaderWrapper $isNewMobileDesign={isNewMobileDesign}>
      <Container $fullWidth>
        <StyledFlex $justifyContent="space-between" $fullWidth>
          <HeaderFlex $alignItems="center">
            <Logo />
            <LogoText $isNewMobileDesign={isNewMobileDesign}>{getBrandName(true, host)}</LogoText>
          </HeaderFlex>
          <MenuWrapper>
            <LocaleWrapper>
              <LocaleSelect $isNewMobileDesign={isNewMobileDesign} />
            </LocaleWrapper>
            {!userIdentified && loginLink}
            <LoginBtn
              onClick={onClickLandingCTA}
              $isNewDesign={isNewDesign}
              aria-label={t('resume_nation_landing_asset_1')}
              role="link"
            >
              {t('resume_nation_landing_asset_1')}
            </LoginBtn>
            {!userIdentified && (
              <LoginWrapper $alignItems="center" $justifyContent="center" $isNewMobileDesign={isNewMobileDesign}>
                <Link href="/login" passHref>
                  <div>
                    <LoginIcon />
                  </div>
                </Link>
              </LoginWrapper>
            )}
          </MenuWrapper>
        </StyledFlex>
      </Container>
    </HeaderWrapper>
  );
};

const LoginWrapper = styled(Flex)`
  width: 40px;
  height: 40px;
  display: none;
  margin-top: 7px;
  ${({ theme }) => theme.max('md')`
    display: flex;
  `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      margin-top: 5px;
    `}
`;

const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.max('md')`
    padding: 20px 0;
    align-items: center;
  `}
`;

const HeaderFlex = styled(Flex)`
  align-self: center;
`;

const LoginBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
  border-radius: 20px;
  box-shadow: 0 3px 16px 0 rgba(29, 44, 67, 0.04);
  background-color: var(--light-values-white);
  border: none;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 1.47;
  color: var(--dark-values-dark-regular-v1);
  cursor: pointer;
  &:hover {
    box-shadow: 0 8px 24px 0 var(--border-transparent);
    background-color: var(--light-values-white);
    color: var(--text);
  }
  ${({ theme }) => theme.max('md')`
    display: none;
  `}
  ${({ isNewDesign }) =>
    isNewDesign &&
    css`
      border-radius: 4px;
      box-shadow: box-shadow: 0px 6px 24px 0px rgba(20, 20, 31, 0.12);
    `}
`;

const LogoText = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 22px;
  line-height: 1.09;
  letter-spacing: -0.44px;
  color: var(--text);
  margin-left: 12px;
  text-transform: capitalize;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: 0;
      margin-right: 12px;
    `}
  ${({ theme }) => theme.max('md')`
       display: none;
    `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      color: #000000;
      display: flex !important;
      font-size: 16px;
      margin-left: 8px;
    `}
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 40px;
  z-index: 1;
  ${({ theme }) => theme.max('md')`
    height: 64px;
    margin: 0;
  `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      border-bottom: 1px solid #e3e3e4;
    `}
`;

const HeaderLink = styled.p`
  font-size: 15px !important;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  margin: 6px 12px 5px 12px;
  flex-shrink: 0;
  white-space: nowrap;

  a {
    font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
    font-size: 15px;
    font-weight: 600;
    line-height: 1.47;
    color: var(--dark-values-dark-regular-v1);
    text-decoration: none;
    padding: 9px 20px;
  }

  ${({ theme }) =>
    theme.max('md')`
     // margin: 6px 0;
  `};
  ${({ theme }) => theme.max('sm')`
    display: none;
  `}
  ${({ theme }) => theme.max('md')`
    display: none;
  `}
  ${({ theme, domain }) =>
    domain !== 'mysmartcv' &&
    domain !== 'resumedone' &&
    theme.max('sm')`
    margin: 10px 0;
    font-size: 20px;
  `};
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 80px;
      right: unset;
    `}
`;

const LocaleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
`;

export default Header;
