import { create } from 'zustand';

export const useSelectStore = create((set) => ({
  expanded: null,
  selectedResume: null,
  localAiPopup: false,
  setlocalAiPopup: (data) => set({ localAiPopup: data }),
  setExpanded: (expanded) => set({ expanded }),
  setSelectedResume: (selectedResume) => set({ selectedResume }),
}));
