import React, { Fragment } from 'react';

import dynamic from 'next/dynamic';

const Header = dynamic(() => import('/imports/dashboard/ui/components/Header'), { ssr: false });
const Footer = dynamic(() => import('/imports/core/ui/components/Footer'), { ssr: false });

const CancelSubscriptionLayout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      {children}
      <Footer />
    </Fragment>
  );
};

export default CancelSubscriptionLayout;
