import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import SvgIcon from '/imports/core/ui/atoms/SvgIcon';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 99;
  transform: translateY(-50%);

  ${(p) =>
    p.hide &&
    css`
      opacity: 0;
    `}
  ${({ $transparentBackground }) =>
    $transparentBackground &&
    css`
      background: transparent !important;
    `}
  ${({ inputValid, isJobTrack, isCoverLetter }) =>
    inputValid &&
    css`
      top: ${isJobTrack && !isCoverLetter ? '7px' : '0px'};
      right: 0;
      width: 37px;
      height: ${isJobTrack && !isCoverLetter ? 'calc(100% - 14px)' : '100%'};
      border-radius: 0 4px 4px 0;
      transform: none;
      background-color: #19cca3;
    `}
    ${({ inputValid, isJobTrack, isCoverLetter }) =>
    inputValid &&
    css`
      background-color: ${isJobTrack && !isCoverLetter ? 'var(--light-values-white)' : '#19cca3'};
      right: ${isJobTrack && !isCoverLetter ? '4px' : '0px'};
      border-radius: 4px;
    `}
    ${({ isErrorthere }) =>
    isErrorthere &&
    css`
      background-color: transparent;
    `}

    ${(p) => css`
    ${p.customStyle}
  `}
    ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      direction: rtl;
      left: 0;
    `}

      ${({ theme, isJobTrack }) =>
    theme.designV2 &&
    theme.isRTL &&
    isJobTrack &&
    css`
      right: auto;
      border-radius: 8px 0 0 8px;
      left: 5px;
      direction: rtl;
    `}

    ${({ extraZIndex }) =>
    extraZIndex &&
    css`
      z-index: 9;
    `}
`;

const Icon = styled((p) => <SvgIcon.Check {...p} viewBox="0 0 18 18" />)`
  width: 14px;
  height: 14px;
  margin: 0;
  color: var(--additional-colors-green-box);
  ${({ isJobTrack, isCoverLetter }) =>
    (!isJobTrack || isCoverLetter) &&
    css`
      color: var(--light-values-white);
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      left: 0 !important;
    `}
  
    ${({ warn }) =>
    warn &&
    css`
      color: rgb(255, 165, 0);
    `}
`;

const ErrorIcon = styled((p) => <SvgIcon.Alert {...p} viewBox="0 0 15 15" />)`
  width: 14px;
  height: 14px;
  margin-right: ${({ theme }) => (theme.designV2 ? '10px' : '16px')};
  color: #ff2d2d;
  ${({ theme }) =>
    theme.max('xs')`
    color: #e84c85;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: ${({ theme }) => (theme.designV2 ? '10px' : '16px')};
      right: auto;
      left: 0 !important;
    `}
`;

const ValidatedInoutIcon = ({
  error,
  empty,
  hide,
  view,
  inputValid,
  warn = false,
  experiment,
  customIcon: CustomIcon,
  isJobTrack,
  isErrorthere,
  isCoverLetter,
  extraZIndex,
  transparentBackground,
  ...rest
}) => {
  const getIcon = () => {
    if (error || isErrorthere) return <ErrorIcon view={view} />;
    else if (empty) return null;
    else if (CustomIcon) return <CustomIcon />;
    return <Icon warn={warn} view={view} isJobTrack={isJobTrack} isCoverLetter={isCoverLetter} />;
  };

  return (
    <Container
      hide={hide}
      warn={warn}
      inputValid={inputValid}
      isErrorthere={isErrorthere}
      error={error}
      experiment={experiment}
      isJobTrack={isJobTrack}
      isCoverLetter={isCoverLetter}
      extraZIndex={extraZIndex}
      $transparentBackground={transparentBackground}
      {...rest}
    >
      {getIcon()}
    </Container>
  );
};

export default ValidatedInoutIcon;

ValidatedInoutIcon.propTypes = {
  error: PropTypes.string,
  empty: PropTypes.any,
  hide: PropTypes.bool,
  view: PropTypes.string,
  inputValid: PropTypes.bool,
  experiment: PropTypes.any,
  customIcon: PropTypes.any,
  isJobTrack: PropTypes.bool,
  isCoverLetter: PropTypes.bool,
  isErrorthere: PropTypes.bool,
};
