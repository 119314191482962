import { useEffect, useCallback } from 'react';
import nookies from 'nookies';
import useIntl from '/imports/core/api/useIntl';
import { createChatCompletion, createCompletion } from '/imports/generator/api/openaiApi';
import { buildAISuggestionPrompt, getLanguageNameByCode } from '/lib/helpers';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { replaceString } from 'imports/core/ui/helpers';
import { withBlock } from 'imports/generator/context/blockresposition.context';
import { useResponsive } from '/imports/core/api/responsiveContext';

const useFetchAISuggestion = (source, item, type, step) => {
  const { token } = nookies.get({});
  const { locale, t } = useIntl();
  const { isGeneratorMobileView } = useResponsive();
  const {
    dispatch,
    state: { isSummaryLoading, isTriggerAPI },
  } = withBlock();
  const isSKillPage = step === 'skills';
  const expBlock = source?.blocks.find((b) => b.type === 'EMPLOYMENT');
  const isExpAvailable = expBlock?.items?.length > 0 && expBlock?.items?.length !== undefined;
  const storedResumeIds = JSON.parse(localStorage.getItem('resumeIDs') || '[]');
  const showSummary = !storedResumeIds.includes(source.id);
  const extractContent = (inputString) => {
    const regex = /"([^"]+)"/g;
    const matches = inputString.match(regex);
    const result = [];
    if (matches) {
      for (const match of matches) {
        const content = match.slice(1, -1);
        result.push(content);
      }
    } else {
      result.push(inputString);
    }
    return result;
  };

  const getOpenAIModel = (prompt) => {
    return {
      model: 'gpt-4-1106-preview',
      messages: [{ role: 'user', content: prompt }],
      temperature: 0.3,
    };
  };

  const openAIApiCallSkills = async (prompt) => {
    const modelParameter = getOpenAIModel(prompt);
    const chatCompletionModels = ['gpt-4', 'gpt-3.5-turbo', 'gpt-3.5-turbo-1106', 'gpt-4-1106-preview'];
    const isChatCompletion = chatCompletionModels.includes(modelParameter.model);
    let completionText = '';

    if (isChatCompletion) {
      return createChatCompletion(modelParameter, token).then((completion) => {
        completionText = completion.choices[0].message.content;
        return completionText;
      });
    } else {
      return createCompletion(modelParameter, token).then((completion) => {
        completionText = completion.choices[0].message.content;
        return completionText;
      });
    }
  };

  const openAIApiCall = async (prompt) => {
    const modelParameter = getOpenAIModel(prompt);
    const chatCompletionModels = ['gpt-4', 'gpt-3.5-turbo', 'gpt-3.5-turbo-1106', 'gpt-4-1106-preview'];
    const isChatCompletion = chatCompletionModels.includes(modelParameter.model);
    let completionText = '';
    if (isChatCompletion) {
      const completion = await createChatCompletion(modelParameter, token);
      completionText = completion.choices[0].message.content;
    } else {
      const completion = await createCompletion(modelParameter, token);
      completionText = completion.choices[0].text;
    }
    return completionText;
  };

  const fetchAISuggestion = useCallback(async () => {
    let completionText = '';
    try {
      dispatch({ type: 'UPDATE_STATE', name: 'isSummaryLoading', payload: true });
      dispatch({ type: 'UPDATE_STATE', name: 'isTriggerAPI', payload: true });
      const promptLanguageCode = source?.settings?.language || locale;
      const prompt = buildAISuggestionPrompt(source, item, type, t, promptLanguageCode, false);
      const languagePrompt = replaceString(t('prompt_language_setting', promptLanguageCode), {
        RESUME_LANGUAGE: getLanguageNameByCode(promptLanguageCode),
      });
      const finalPrompt = `${prompt}${languagePrompt}`;
      if (isSKillPage) {
        completionText = await openAIApiCallSkills(finalPrompt);
      } else {
        completionText = await openAIApiCall(finalPrompt);
      }
      const jsonArrayFormatted = completionText.slice(completionText.indexOf('['), completionText.lastIndexOf(']') + 1);
      const ans = JSON.parse(jsonArrayFormatted);
      dispatch({ type: 'UPDATE_STATE', name: 'isSummaryData', payload: ans });
      dispatch({ type: 'UPDATE_STATE', name: 'isSummaryLoading', payload: false });
      return ans;
    } catch (e) {
      const arrayRegex = /\["[^"]*"\]/;
      if (!arrayRegex.test(completionText)) {
        return extractContent(completionText);
      }
      if (e instanceof SyntaxError) {
        const arr = [...completionText.matchAll(/\["([^"]+)"\]/g)].map((match) => match[1]);
        return arr || [];
      }
      dispatch({ type: 'UPDATE_STATE', name: 'isSummaryLoading', payload: false });
      console.log('OPENAI SUGGESTION ERROR:', e);
      return [];
    }
  }, [source, item, type, locale, t]);

  useEffect(() => {
    if (!isSummaryLoading && !isTriggerAPI && isExpAvailable && showSummary) {
      fetchAISuggestion();
    }
  }, []);

  return null;
};

export default useFetchAISuggestion;
