import React from 'react';

const AdobeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
      <g clipPath="url(#clip0_21952_27975)">
        <path d="M4.78292 0.5H0.5V10.7427L4.78292 0.5Z" fill="#FA0C00" />
        <path d="M7.79688 0.5H12.0742V10.7427L7.79688 0.5Z" fill="#FA0C00" />
        <path d="M6.28627 4.27344L9.01228 10.7411H7.22377L6.40904 8.68192H4.41406L6.28627 4.27344Z" fill="#FA0C00" />
      </g>
      <defs>
        <clipPath id="clip0_21952_27975">
          <rect width="11.4286" height="10" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdobeIcon;
