import { useState, useEffect } from 'react';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';
import { useMutation } from 'react-apollo';
import { sortObjectListByArraySequence } from 'imports/generator/api/helpers';
import styled, { css } from 'styled-components';

import { ADD_BLOCK } from '/imports/generator/api/apollo/client/mutations';
import { BLOCK_NAMES, REQUIRED_BLOCKS, SINGLE_ITEM_BLOCKS } from '/imports/generator/api/constants';
import { BLOCKS_MAP } from '/imports/generator/api/form';
import Box from '/imports/core/ui/atoms/Box';
import ButtonAtom from '/imports/core/ui/atoms/Button';
import { apolloClient as client } from '/lib/initApollo';
import Flex from '/imports/core/ui/atoms/Flex';
import { GET_RESUME } from '/imports/generator/api/apollo/client/queries';
import { getActiveRepositingVar, getPreviewBlocksHeight } from '/lib/helpers';
import { getBlockTranslationSlug } from '/imports/generator/api/helpers';
import { OLD_SYSTEM } from '/imports/pdf/core/api/constants';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { withBlock } from '/imports/generator/context/blockresposition.context';
import intlHook from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { useResponsive } from 'imports/core/api/responsiveContext';

const AddBlockButton = (props) => {
  const { icon: Icon, resume, type, updateImmue } = props;
  const [isLoading, setLoading] = useState(false);
  const { breakpoint } = useResponsive();
  const { trackEvent } = useTracking();
  const { state } = withBlock();
  const { t } = intlHook();
  const { blocks, settings } = resume;
  const [mutate] = useMutation(ADD_BLOCK);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const updateImmutableStateBuilder = async () => {
    const { getResume } = client.readQuery({
      query: GET_RESUME,
      variables: {
        resumeId: resume.id,
      },
    });
    await updateImmue(getResume);
  };

  const addBlock = () => {
    const shortid = require('shortid');
    const animationKey = shortid.generate();
    let obj = {
      resumeId: resume.id,
      blockType: type,
      animationKey,
    };
    setLoading(true);
    const customBlocks =
      resume && resume.blocks && resume.blocks.length > 0
        ? resume.blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b)
        : [];
    const customSkillsBlocks =
      resume && resume.blocks && resume.blocks.length > 0
        ? resume.blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
        : [];
    const activeRepositionVariant = getActiveRepositingVar();
    const isMobile = ['xs', 'sm'].includes(breakpoint);
    if (activeRepositionVariant === 'with_complete_repositioning' && !isMobile) {
      obj = {
        ...obj,
        heights: getPreviewBlocksHeight(customBlocks, customSkillsBlocks),
        variant: 'with_complete_repositioning',
      };
    }
    if (state.toggle.deActive || resume?.saDetails?.mainColumnSize === 'none') {
      delete obj.heights;
      delete obj.variant;
    }
    mutate({
      variables: obj,
    }).then(async (response) => {
      const { id, blocks } = response.data.addBlock || {};
      await updateImmutableStateBuilder();
      const block = last(blocks.filter((b) => b.type === obj.blockType)) || {};
      const singleItemBlock = SINGLE_ITEM_BLOCKS.includes(block.type);
      if (!singleItemBlock) {
        client.writeData({
          data: {
            expandedItem: `${id}.${block.id}.${last(block.items)?.animationKey}`,
          },
        });
      }

      trackEvent(`add_${block.items && block.items.length > 1 ? 'extra_' : ''}${type.toLowerCase()}`);

      // We use setTimeout with 0 milliseconds to defer the execution of this code
      // until after the current JavaScript execution context has completed. This
      // allows any ongoing DOM updates triggered by asynchronous tasks, such as
      // this.updateImmutableStateBuilder(), to finish before accessing the DOM elements.
      // This behavior might slightly differ between JavaScript versions or
      // environments, but the primary purpose remains consistent.
      setTimeout(() => {
        setLoading(false);
        const el = last(document.querySelectorAll(`#${block.type}`));
        if (el) {
          const lastCont = last(el.querySelectorAll('[data-item-cont]'));
          if (lastCont) {
            const input = lastCont.querySelector('input') || lastCont.querySelector('textarea');
            input.focus();
          }
          el.offsetHeight;
          return el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
        setLoading(false);
      }, 5);
    });
  };

  const isCustom = ['CUSTOM_SKILLS_CATEGORY', 'CUSTOM'];
  const isAdded = !isCustom.includes(type) ? !!blocks.find((b) => b.type === type) : false;
  const blockName = getBlockTranslationSlug(BLOCK_NAMES[type]);
  return (
    <AddButton onClick={addBlock} disabled={isAdded} loader={isLoading} tabIndex="0" aria-label={t(blockName)}>
      <AddButtonInner>
        <IconWrappper disabled={isAdded} loader={isLoading}>
          <Icon />
        </IconWrappper>
        <StyledTitle language={settings?.language}>{t(blockName)}</StyledTitle>
        <AddIconWrapper disabled={isAdded} loader={isLoading}>
          <SvgIcon.CirclePlus width="16px" height="16px" />
        </AddIconWrapper>
      </AddButtonInner>
    </AddButton>
  );
};

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
const sortOrder = [
  'LANGUAGES',
  'PERSONAL_DETAILS',
  'HOBBIES',
  'COURSES',
  'CUSTOM',
  'REFERENCES',
  'EXTRA_CURRICULAR',
  'INTERNSHIPS',
  'PUBLICATIONS',
  'DRIVING_LICENSE',
];
const AddBlocks = (props) => {
  const { resume, updateImmue } = props;
  const sortedBLOCKS_MAP = sortObjectListByArraySequence(BLOCKS_MAP, sortOrder);
  const BLOCK = sortedBLOCKS_MAP || BLOCKS_MAP;
  return (
    <Flex grid>
      {Object.entries(BLOCK)
        .filter((block) => !REQUIRED_BLOCKS.includes(block[0]))
        .filter((block) => !OLD_SYSTEM.includes(block[0]))
        .map(([type, data]) => {
          return (
            <StyledBox md={6} sm={6} xs={12} padded key={`add-${type}`}>
              <AddBlockButton
                type={type}
                icon={data.icon}
                resume={resume}
                updateImmue={updateImmue}
                iconV2={data.iconV2}
              />
            </StyledBox>
          );
        })}
    </Flex>
  );
};

AddBlocks.propTypes = {
  resume: PropTypes.object,
  updateImmue: PropTypes.func,
};

const StyledTitle = styled.span`
  max-width: 70%;
  text-align: left;
  margin: 0 !important;
  font-size: 14px;
  display: inline-block;
  ${({ theme }) =>
    theme.isRTL &&
    css`
      text-align: right;
    `}
`;
const StyledBox = styled(Box)`
  ${({ theme }) =>
    theme.max('xs')`
    button {
      span {
        width: auto;
      }
    }
  `}

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const AddButton = styled((p) => <ButtonAtom unstyled {...p} />)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 28px 6px 16px;
  margin-top: 8px;
  margin-bottom: 20px;
  border-radius: 3px;
  border: solid 1px #e3e3e4;
  background-color: var(--light-values-white);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  color: #282b32;
  text-align: left;
  position: relative;
  box-shadow: none;

  &:hover {
    background-color: var(--light-values-white);
    border-color: #e6e6ff;
    box-shadow:
      0 3px 8px -5px rgba(15, 15, 15, 0.05),
      0 7px 8px -5px rgba(15, 15, 15, 0.1);
  }
  &:focus {
    border-color: #e6e6ff;
    color: #016fe0;
    background-color: var(--light-values-white);

    svg {
      color: ${(p) => p.theme.colors.primary};
    }
  }

  @media (min-width: 801px) {
    &:hover {
      color: #016fe0;
    }
  }

  @media screen and (max-width: 800px) {
    &:hover,
    &:focus {
      border: solid 1px #e3e3e4;
      background-color: var(--light-values-white);
      box-shadow: none;
    }
  }

  &:disabled {
    opacity: 0.5;
    background-color: var(--light-values-white) !important;
    color: #33334f !important;
    pointer-events: none;
    div:first-child svg {
      color: #bfc5e0;
    }
  }

  ${({ theme }) =>
    theme.max('xs')`
    margin: 5px 0;
  `}

  @media (min-width: 800px) and (max-width: 1200px) {
    padding: 10px 10px 6px;
  }
  ${({ loader }) =>
    loader &&
    css`
      pointer-events: none;
    `}
`;

const AddButtonInner = styled.span`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;

  span {
    margin-right: 12px;
  }
  ${(p) => p.theme.max('sm')`
    width: 36px;
  `}

  &:disabled {
    background: transparent;
  }
`;

const IconWrappper = styled.div`
  color: inherit;
  margin-right: 10px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 10px;
    `}
  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const SvgWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: solid 1px var(--border-transparent);
  background-color: var(--light-values-light-extra-v1);
`;

const AddIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  color: inherit;
  top: 35%;
  left: auto;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      left: 10px;
    `}
  ${({ loader }) =>
    loader &&
    css`
      pointer-events: none;
    `}
`;

export default AddBlocks;
